import { useInView } from 'react-intersection-observer';
import React from 'react';

interface LazyLoadProps {
  children: React.ReactNode;
  className?: string;
}

const LazyLoad = ({ children, className }: LazyLoadProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '200px',
  });

  return (
    <div ref={ref} className={className}>
      {inView ? children : <div style={{ minHeight: '40rem' }} />}
    </div>
  );
};

export default LazyLoad;
