import { useDispatch, useSelector } from 'react-redux';
import '../../../styles/pages/quickOnboarding/quickOnboardingMain.scss';
import { RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../../lib/constants/route';
import { motion, AnimatePresence } from 'framer-motion';
import QuickOnboardingCompany from './QuickOnboardingCompany';
import RenderLoginPageLogo from '../../login/RenderLoginPageLogo';

const QuickOnboardingMain = () => {
  const dispatch = useDispatch();
  //* Commented below for the MCP Launch
  // const currentStep = useSelector(
  //   (state: RootState) => state.user?.ai_onboarding?.currentStep || 1
  // );
  //* Added below for the MCP Launch
  const currentStep = 1;

  const isAiOnboardingComplete = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.is_ai_onboarding_completed || false
  );
  const is_ai_onboarding_inprogress = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.is_ai_onboarding_inprogress || false
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const [apiKey, setAPIKey] = useState<string>('');

  const navigate = useNavigate();
  useEffect(() => {
    //* Commented below for the MCP Launch
    // if (isAiOnboardingComplete) {
    //   navigate(HOME_ROUTE);
    // } else {
    //   dispatch(setAiOnboardingInprogress(true));
    // }
    //* Added below for the MCP Launch
    if (!is_ai_onboarding_inprogress) {
      navigate(HOME_ROUTE);
    }
  }, [isAiOnboardingComplete, is_ai_onboarding_inprogress]);

  return (
    <div className='quickOnboardingMain'>
      <div className='quickOnboardingMain__content'>
        <div className='quickOnboardingMain__content--logo'>
          <RenderLoginPageLogo
            staticURL={staticURL}
            merchantTheme={merchantTheme}
            isDarkMode={isDarkMode}
          />
        </div>
        {/* //* Commented below for the MCP Launch */}
        {/* <div className='quickOnboardingMain__content--top'>
          <QuickOnboardingProgress />
        </div> */}
        <br />
        <div className='quickOnboardingMain__content--bottom'>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={currentStep}
              initial={{ opacity: 0.1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.1 }}
              transition={{ duration: 0.3 }}
            >
              {currentStep === 1 && <QuickOnboardingCompany />}
              {/* //* Commented below for the MCP Launch */}
              {/* {currentStep === 2 && (
                <QuickOnboardingIntegrations
                  apiKey={apiKey}
                  setAPIKey={setAPIKey}
                />
              )}
              {currentStep === 3 && <QuickOnboardingReady />} */}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default QuickOnboardingMain;
