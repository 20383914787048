import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import '../../../styles/components/charts/pieChartComponent.scss';
import ChartWithConfig from '../ChartWithConfig';
import IndividualCTRBarChart from './IndividualCTRBarChart';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';
import { defaultGraphTimeFilter } from '../../../lib/constants/abstracts';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainCTRChart = ({
  timeFilter = defaultGraphTimeFilter,
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=ctr_graph&group_by=individual&time_range=${timeFilter}&event_type=${eventFilter}`
  );
  const [response, setResponse] = useState<any>([]);
  const [title, setTitle] = useState<string>(t('ClickThroughRate'));
  const [comment, setComment] = useState<string>(t('OverallClickThroughRate'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        setResponse(res);
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }
    setComment(
      `${t('ClickThroughRateOf')} ${eventFilter?.replaceAll('_', ' ')}`
    );
    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=ctr_graph&group_by=individual&time_range=${timeFilter}${dateRangeURLParam}${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  useEffect(() => {
    if (eventFilter === 'add_to_cart') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'pay_button') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'go_to_checkout') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else {
      setTitle(t('ClickThroughRate'));
    }
  }, [eventFilter]);

  return (
    <ChartWithConfig title={title} comment={comment} isConfigHidden={true}>
      <IndividualCTRBarChart apiResponse={response} />
    </ChartWithConfig>
  );
};

export default MainCTRChart;
