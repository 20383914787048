import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/tara/search/taraSearch.scss';
import { Chat } from '../../../interfaces/taraInterfaces';
import TaraSearchItem from './TaraSearchItem';
import PaginationMain from '../../../components/pagination/PaginationMain';
import TaraSearchInput from './TaraSearchInput';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import tara_chat_history_api_call from '../../../lib/api/tara/tara_chat_history_api_call';
import { useDispatch } from 'react-redux';
import { setAllChatsHistory } from '../../../store/reducers/chatSlice';

const TaraSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  /*------------------------- PAGINATION STATE ------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [paginatedData, setPaginatedData] = useState<{
    chats: Chat[];
    meta: any;
    links: any;
  } | null>(null);
  /*------------------------- END PAGINATION STATE ------------------------- */

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');

  // Ref to track initial mount so we don't trigger search effect immediately.
  const isInitialMount = useRef(true);

  // Memoized API fetch function.
  const fetchPaginatedChats = useCallback(
    async (page: number, per_page: number) => {
      try {
        setLoading('pending');
        const res = await tara_chat_history_api_call(page, per_page);
        if (res && res.data && Array.isArray(res.data)) {
          const chats: Chat[] = res.data.map((item: any) => ({
            chatId: item.conversation_id,
            chatStartedDate: item.chatStartedDate,
            chatLastUpdatedDate: item.chatLastUpdatedDate,
            chatMessages: item.conversations?.map((conv: any) => ({
              id: conv.chatId,
              message: conv.message,
              messageType: 'text',
              role: conv.role,
            })),
          }));
          setPaginatedData({ chats, meta: res.meta, links: res.links });
          setTotalRecords(res.meta.total);
          setPageCount(res.meta.last_page);
          setLoading('success');
          // Update redux chat history with the newly fetched chats.
          dispatch(setAllChatsHistory(chats));
        } else {
          setLoading('null');
        }
      } catch (error) {
        console.error('Error fetching paginated chat history:', error);
        setLoading('error');
      }
    },
    [dispatch]
  );

  // Fetch data when currentPageNumber or itemsPerPage change.
  useEffect(() => {
    fetchPaginatedChats(currentPageNumber, itemsPerPage);
  }, [currentPageNumber, itemsPerPage, fetchPaginatedChats]);

  // Inline debounce for searchQuery changes.
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return; // Skip on initial mount.
    }
    const timer = setTimeout(() => {
      setCurrentPageNumber(1);
      fetchPaginatedChats(1, itemsPerPage);
    }, 300);
    return () => clearTimeout(timer);
  }, [searchQuery, itemsPerPage, fetchPaginatedChats]);

  const handlePageClick = (event: any) => {
    const newPage = event.selected + 1;
    setCurrentPageNumber(newPage);
  };

  const handleRecordsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPageNumber(1);
  };

  // If a search query is provided, filter the current page's data locally.
  const displayedChats =
    searchQuery && paginatedData
      ? paginatedData.chats.filter((chat) =>
          chat.chatMessages[0].message
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : paginatedData?.chats || [];

  return (
    <div className='taraSearch'>
      <TaraSearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div className='taraSearch__items'>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'null' && (
          <NoData title={t('No chat found with this search query.')} />
        )}
        {loading === 'success' &&
          (displayedChats.length === 0 ? (
            <NoData title={t('No chat found with this search query.')} />
          ) : (
            displayedChats.map((chat) => (
              <TaraSearchItem
                key={chat.chatId}
                id={chat.chatId}
                title={chat.chatMessages[0]?.message}
                lastUpdated={chat.chatLastUpdatedDate}
              />
            ))
          ))}
        {paginatedData &&
          totalRecords > itemsPerPage &&
          loading === 'success' && (
            <PaginationMain
              totalRecords={totalRecords}
              itemsPerPage={itemsPerPage}
              onRecordsPerPageChange={handleRecordsPerPageChange}
              breakLabel='...'
              nextLabel={t('Next >')}
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              initialPage={currentPageNumber - 1}
              pageCount={pageCount}
              previousLabel={t('< Previous')}
              renderOnZeroPageCount={undefined}
              marginPagesDisplayed={2}
              containerClassName='pagination'
              pageLinkClassName='pagination__page-num'
              previousLinkClassName='pagination__page-num'
              nextLinkClassName='pagination__page-num'
              disabledLinkClassName='pagination__page-num-disabled'
              activeLinkClassName='pagination__active'
            />
          )}
      </div>
    </div>
  );
};

export default TaraSearch;
