import adjustColorBrightness from './adjustColorBrightness';
import { generateBoxShadow } from './generateBoxShadow';

/* 
if (appThemeType === '2') {
      root?.style.setProperty('--containers-background-color', '#F3F4F8');
    } else {
      root?.style.setProperty('--containers-background-color', '#ffffff');
    }
*/

export default function appThemeEngine(
  isAppThemeDark: boolean,
  merchantTheme: any,
  appThemeType: string
) {
  const root = document.documentElement;
  if (isAppThemeDark) {
    if (appThemeType === '2') {
      root?.style.setProperty('--containers-background-color', '#121212');
    } else {
      root?.style.setProperty('--containers-background-color', '#1f1f1f');
    }
    root?.style.setProperty('--main-background-color', '#121212');
    root?.style.setProperty('--main-border-color', '#424244');
    root?.style.setProperty('--header-color', '#1f1f1f');
    root?.style.setProperty('--main-text-color', '#e2e2e2');
    root?.style.setProperty('--sidebar-color', '#1f1f1f');
    root?.style.setProperty('--sidebar-hover-color', '#292929');
    root?.style.setProperty('--sidebar-hover-color-2', '#2929293d');
    root?.style.setProperty('--icon-color', '#e2e2e2');
    root?.style.setProperty('--sidebar-background-color', '#1f1f1f');
    root?.style.setProperty('--black-white-color', '#ffffff');

    root?.style.setProperty('--containers-border-color', '#424244');
    root?.style.setProperty('--white-border-color', '#424244');
    root?.style.setProperty('--sidebar-sublink-hover-color', '#9999a9');
    root?.style.setProperty('--subPageLinkHolder-link-hover-color', '#424242');
    root?.style.setProperty(
      '--subPageLinkHolder-link-selected-color',
      '#424242'
    );
    root?.style.setProperty('--input-border-color', '#424244');
    root?.style.setProperty('--input-border-hover-color', '#52525b');
    root?.style.setProperty('--input-background-color', '#121212');
    root?.style.setProperty('--store-btn-color', '#424242');
    root?.style.setProperty('--gray-btn-light-background-color', '#4e4e50');
    root?.style.setProperty(
      '--gray-btn-light-hover-background-color',
      '#424242'
    );
    root?.style.setProperty('--gray-btn-light-text-color', '#e2e2e2');
    root?.style.setProperty('--subPageList-hover-color', '#4e4e50');
    root?.style.setProperty('--topbar-background-color', '#424242');
    root?.style.setProperty('--sub-text-color', '#e2e2e2');
    root?.style.setProperty('--login-background-color', '#121212');
  } else {
    root?.style.setProperty('--header-color', '#f2f2f2');
    root?.style.setProperty('--sidebar-color', '#fafafa');
    root?.style.setProperty('--sidebar-hover-color', '#f3f3f3');
    root?.style.setProperty('--sidebar-hover-color-2', '#f3f3f375');
    root?.style.setProperty('--icon-color', '#9999a9');

    if (appThemeType === '2') {
      root?.style.setProperty('--main-background-color', '#F3F4F8');
      root?.style.setProperty('--containers-background-color', '#F3F4F8');
    } else {
      root?.style.setProperty('--main-background-color', '#ffffff');
      root?.style.setProperty('--containers-background-color', '#ffffff');
    }

    root?.style.setProperty('--sidebar-background-color', '#ffffff');
    root?.style.setProperty('--containers-border-color', '#ada5a5');
    root?.style.setProperty('--white-border-color', '#ebebeb');
    root?.style.setProperty('--sidebar-sublink-hover-color', '#2e333d');
    root?.style.setProperty('--subPageLinkHolder-link-hover-color', '#efefef');
    root?.style.setProperty('--subPageLinkHolder-link-selected-color', '#fff');
    root?.style.setProperty('--input-border-color', '#cecece');
    root?.style.setProperty('--input-border-hover-color', '#9999a9');
    root?.style.setProperty('--input-background-color', '#ffffff');
    root?.style.setProperty('--main-text-color', '#2e333d');
    root?.style.setProperty('--store-btn-color', '#fff');
    root?.style.setProperty('--gray-btn-light-background-color', '#e2e2e2');
    root?.style.setProperty(
      '--gray-btn-light-hover-background-color',
      '#d7d7d7'
    );
    root?.style.setProperty('--black-white-color', '#000000');
    root?.style.setProperty('--gray-btn-light-text-color', '#9999a9');
    root?.style.setProperty('--subPageList-hover-color', '#f0f0f0');
    root?.style.setProperty('--topbar-background-color', '#fff');
    root?.style.setProperty('--sub-text-color', '#4c4c53');
    root?.style.setProperty('--login-background-color', '#f4f7fc');
    root?.style.setProperty('--main-border-color', '#d1d1d1');
  }
  const themeColorConst = '#111111';
  const secondaryThemeColorConst = '#FFDD33';
  const textColorConst = isAppThemeDark ? '#e2e2e2' : '#2e333d';
  const btnTextColorConst = isAppThemeDark ? '#ffffff' : '#000000';
  const defaultFont = `'Geist Sans', sans-serif`;

  //white label
  root?.style.setProperty(
    '--main-brand-color',
    merchantTheme?.main_brand_color
      ? merchantTheme.main_brand_color
      : themeColorConst
  );

  root?.style.setProperty(
    '--main-brand-hover-color',
    merchantTheme?.main_brand_color
      ? merchantTheme.main_brand_color
      : adjustColorBrightness(themeColorConst, 50)
  );
  root?.style.setProperty(
    '--secondary-brand-color',
    merchantTheme?.secondary_brand_color
      ? merchantTheme.secondary_brand_color
      : secondaryThemeColorConst
  );

  root?.style.setProperty(
    '--secondary-brand-hover-color',
    merchantTheme?.secondary_brand_color
      ? merchantTheme.secondary_brand_color
      : adjustColorBrightness(secondaryThemeColorConst, 10)
  );

  root?.style.setProperty(
    '--main-btn-color',
    merchantTheme?.main_brand_color
      ? merchantTheme.main_brand_color
      : adjustColorBrightness(themeColorConst, -3)
  );

  root?.style.setProperty(
    '--main-btn-hover-color',
    merchantTheme?.main_brand_color
      ? merchantTheme.main_brand_color
      : adjustColorBrightness(themeColorConst, 15)
  );

  root?.style.setProperty(
    '--main-btn-text-color',
    merchantTheme?.main_btn_text_color && !isAppThemeDark
      ? merchantTheme.main_btn_text_color
      : btnTextColorConst
  );

  root?.style.setProperty(
    '--main-text-color',
    merchantTheme?.main_text_color && !isAppThemeDark
      ? merchantTheme.main_text_color
      : textColorConst
  );

  root?.style.setProperty(
    '--main-font-primary',
    merchantTheme?.font_family &&
      merchantTheme?.font_family !== '' &&
      merchantTheme?.font_family !== 'null'
      ? merchantTheme.font_family
      : defaultFont
  );

  root?.style.setProperty(
    '--main-font-secondary',
    merchantTheme?.font_family_secondary &&
      merchantTheme?.font_family_secondary !== '' &&
      merchantTheme?.font_family_secondary !== 'null'
      ? merchantTheme.font_family_secondary
      : defaultFont
  );

  root?.style.setProperty(
    '--main-box-shadow',
    merchantTheme?.main_brand_color
      ? generateBoxShadow(merchantTheme?.main_brand_color)
      : generateBoxShadow(themeColorConst)
  );
}
