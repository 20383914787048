import {
  addSelectedCMS,
  setAiTrackerAPIKey,
  setIsTrackable,
} from '../../../store/reducers/userSlice';
import store from '../../../store/store';
import get_checkout_tracking_api_call from '../integration/get_checkout_tracking_api_call';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

//get basic dashboard statistics
export default async function get_available_tracking_data_api_call() {
  try {
    const res1: ApiDataType[] = await get_checkout_tracking_api_call();

    if (!res1 || res1.length === 0) {
      store.dispatch(setIsTrackable(false));
    } else {
      if (
        res1[res1.length - 1]?.configuration &&
        JSON.stringify(res1[res1.length - 1].configuration) ===
          JSON.stringify({ key: 'value' })
      ) {
        store.dispatch(setIsTrackable(false));
      } else {
        store.dispatch(setIsTrackable(true));
      }

      const currentIntegrationData = res1[res1.length - 1];
      // setKey(currentIntegrationData?.api_key || '');
      store.dispatch(setAiTrackerAPIKey(currentIntegrationData?.api_key || ''));
      store.dispatch(
        addSelectedCMS(
          currentIntegrationData?.additional_configs?.CMS_type || ''
        )
      );
    }
  } catch (error) {
    console.error('Error fetching tracking data:', error);
  }
}
