export const availableTrackableEvents = {
  init: 'initial_page_load',
  payButtonElement: 'pay_btn_click_event',
  addToCartButtonElement: 'add_to_cart_btn_click_event',
  removeFromCartButtonElement: 'remove_from_cart_btn_click_event',
  viewCartButtonElement: 'view_cart_btn_click_event',
  goToCheckoutButtonElement: 'go_to_checkout_btn_click_event',
  addToFavoritesButtonElement: 'add_to_favorites_btn_click_event',
  removeFromFavoritesButtonElement: 'remove_from_favorites_btn_click_event',
  addToCompareButtonElement: 'add_to_compare_btn_click_event',
  customerReviewsButtonElement: 'customer_reviews_btn_click_event',
  productViewButtonElement: 'product_view_btn_click_event',
};
