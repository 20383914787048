import store from '../../../store/store';
import { asyncHandler } from '../async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { PSP_API } from '../../constants/central-api';
import { submitLogToBugsnag } from '../log';

export default async function delete_psp_api_call(selectedPSP: string) {
  try {
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    const shop_slug = store.getState().user.company.shop_slug;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelSellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    const requestInfo: RequestInfo = {
      url: `${PSP_API(shop_slug)}/${selectedPSP}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'DELETE_PSP_CONNECTION';
    const customMsg = 'Connection Deleted Successful.';

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (exception) {
    console.log('Error during authentication request');
    submitLogToBugsnag('error', `${exception}`);
    return false;
  }
}
