import { clearAuthSlice } from '../../store/reducers/authSlice';
import { clearChatSlice } from '../../store/reducers/chatSlice';
import { setIsPaymentPopupOpen } from '../../store/reducers/uiSlice';
import { clearUserSlice } from '../../store/reducers/userSlice';
import store from '../../store/store';

declare const window: any;

function localStorageHardReset() {
  store.dispatch(clearUserSlice());
  store.dispatch(clearAuthSlice());
  store.dispatch(clearChatSlice());
  store.dispatch(setIsPaymentPopupOpen(false));
  window.localStorage.clear();
}

export default localStorageHardReset;
