import { successSVG, errorSVG } from '../../lib/allCustomSVGs';
import '../../styles/pages/thankYou/stripeSubscriptionFailed.scss';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import get_subscription_payment_status_api_call from '../../lib/api/ai-tracker/subscriptions/get_subscription_payment_status_api_call';
import { useNavigate } from 'react-router-dom';

const RecommendationsSubscriptionStatus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<'pending' | 'success' | 'error'>(
    'pending'
  );

  const getRecommendationStatus = async (id: string) => {
    try {
      const res = await get_subscription_payment_status_api_call(id);

      if (res && res?.status === 'success') {
        setLoading('success');
      } else {
        setLoading('error');
      }
    } catch (error) {
      console.error('Error fetching recommendations status:', error);
    }
  };

  useEffect(() => {
    if (window.location?.search) {
      const params = new URLSearchParams(window.location.search);
      const subscription_history_id = params.get('subscription_history_id');
      if (subscription_history_id) {
        getRecommendationStatus(subscription_history_id);
      }
    }
  }, []);

  const renderMessageBanner = () => {
    if (loading === 'success') {
      return (
        <MessageBanner
          title={t('SubscriptionSuccessMainText')}
          subTitle={t('SubscriptionSuccessSubText')}
          icon={successSVG}
          btnTitle='Ok'
          onSelect={() => {
            navigate('/tracking/trends');
          }}
          success={true}
        />
      );
    } else if (loading === 'error') {
      return (
        <MessageBanner
          title={t('SubscriptionFailMainText')}
          subTitle={t('SubscriptionFailSubText')}
          icon={errorSVG}
          btnTitle={t('GoBack')}
          onSelect={() => {
            navigate('/tracking/trends');
          }}
          success={false}
        />
      );
    } else {
      return (
        <MessageBanner
          title={t('Loading...')}
          icon={errorSVG}
          btnTitle={t('GoBack')}
          onSelect={() => {
            navigate('/tracking/trends');
          }}
          success={false}
          isLoading={true}
        />
      );
    }
  };

  return renderMessageBanner();
};

export default RecommendationsSubscriptionStatus;
