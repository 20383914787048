import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { SHOPIFY_INJECT_SCRIPT_API } from '../../constants/central-api';

export default async function shopify_inject_script_api_call(data: {
  token: string;
  shop_domain: string;
  shop_id: string;
  script_url: string;
}) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shopSlug = store.getState().user?.company?.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!shopSlug) {
    return false;
  }

  const requestInfo: RequestInfo = {
    url: SHOPIFY_INJECT_SCRIPT_API,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
    body: JSON.stringify(data),
  };

  const actionType = 'INJECT_SHOPIFY_SCRIPT';
  const customMsg = 'Shopify script injected Successfully.';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  return response.success;
}
