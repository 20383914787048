import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import getCurrentRoute from '../../lib/helper-functions/getCurrentRoute';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import get_checkout_tracking_api_call from '../../lib/api/integration/get_checkout_tracking_api_call';
import post_integration_api_call from '../../lib/api/integration/post_integration_api_call';
import { allRoutes } from '../../lib/constants/route';
import { settingSVG } from '../../lib/allCustomSVGs';
import { submitLogToBugsnag } from '../../lib/api/log';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { HELP_LINE_API } from '../../lib/constants/central-api';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import { setMessage } from '../../store/reducers/appSlice';
import CssClasses from './checkoutTrackingSections/CssClasses/CssClasses';
import Autofill from './checkoutTrackingSections/Autofill/Autofill';
import Integration from './checkoutTrackingSections/Integration/Integration';
import Trends from './checkoutTrackingSections/Trends/Trends';
import EventTracking from './checkoutTrackingSections/CustomEvent/EventTracking';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import MCP from './checkoutTrackingSections/MCP/MCP';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

function CheckoutTrackingPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');
  const [key, setKey] = useState<string>('');
  const [isTrackable, setIsTrackable] = useState<boolean>(false);
  const [apiData, setAPIData] = useState<ApiDataType | null>(null);
  const [isAutofillEnabled, setIsAutofillEnabled] = useState<boolean>(false);
  const [isTrackerON, setIsTrackerON] = useState<boolean>(true);
  const [optimizeValue, setOptimizeValue] = useState<number>(0);
  const shopURL = useSelector(
    (state: RootState) => state.user?.company?.webshop_url
  );
  const shopSlug = useSelector(
    (state: RootState) => state.user?.company?.shop_slug
  );
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const isWhiteLabel = useSelector(
    (state: RootState) => state.UI?.isWhiteLabel
  );

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }
  const { t } = useTranslation();

  const location = useLocation();
  const currentRoute = getCurrentRoute(location);

  useEffect(() => {
    if (!isNotEmptyString(key)) {
      getAvailableTrackingData();
    }
  }, [key]);

  useEffect(() => {
    if (isNotEmptyString(key) && currentRoute === 'integration') {
      getAvailableTrackingData();
    }
  }, [currentRoute]);

  const getAvailableTrackingData = async () => {
    setLoading('pending');
    try {
      const res: ApiDataType[] = await get_checkout_tracking_api_call();

      if (!res || res.length === 0) {
        const sendingData = {
          name: 'checkout-tracking-class-names',
          type: 'tracker',
          configuration: { key: 'value' },
        };
        const postRes = await post_integration_api_call(
          sendingData,
          undefined,
          undefined,
          undefined,
          undefined
        );
        if (postRes && postRes.api_key) {
          setKey(postRes.api_key);
        }
        setIsTrackable(false);
      } else {
        if (
          res[res.length - 1]?.configuration &&
          JSON.stringify(res[res.length - 1].configuration) ===
            JSON.stringify({ key: 'value' })
        ) {
          setIsTrackable(false);
        } else {
          setIsTrackable(true);
        }
        setAPIData(res[res.length - 1]);
        if (res?.length > 0) {
          const additionalConfigs = res[res?.length - 1]?.additional_configs;

          if (typeof additionalConfigs?.is_autofill_enabled === 'string') {
            setIsAutofillEnabled(
              additionalConfigs?.is_autofill_enabled === 'true' ? true : false
            );
          } else {
            setIsAutofillEnabled(false);
          }

          if (typeof additionalConfigs?.is_tracker_active === 'string') {
            setIsTrackerON(
              additionalConfigs?.is_tracker_active === 'false' ? false : true
            );
          } else {
            setIsTrackerON(true);
          }

          if (
            typeof additionalConfigs?.optimization_adjustment_ratio === 'number'
          ) {
            setOptimizeValue(additionalConfigs?.optimization_adjustment_ratio);
          } else {
            setOptimizeValue(0);
          }
        }
        setKey(res[res.length - 1]?.api_key || '');
      }
      setLoading('success');
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  /* ================================================== */
  const onSubmitHandler = async () => {
    if (shopURL) {
      const message = t(
        'ShopURLNotAvailablePleaseUpdateYourShopURLThroughSettings'
      );
      const messageType = 'warning';
      dispatch(setMessage({ message, messageType }));
      return;
    }

    try {
      const sendingData = JSON.stringify({
        type: 'tracking',
        data: {
          cms_url: `https://${shopURL}`,
          shop_slug: shopSlug,
        },
      });

      const requestInfo: RequestInfo = {
        url: HELP_LINE_API,
        method: 'POST',
        body: sendingData,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'HELP_LINE_API_POST';
      const customMsg = t('EmailSentSuccessfully');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      if (res.actionType === actionType && response.success) {
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };
  /* ================================================== */

  const title =
    currentRoute === 'css'
      ? t('CSSTracking')
      : currentRoute === 'integration'
      ? t('Integration')
      : currentRoute === 'mcp'
      ? t('MCP')
      : currentRoute === 'autofill'
      ? t('Autofill')
      : currentRoute === 'events'
      ? t('EventTracking')
      : currentRoute === 'trends'
      ? t('Recommendations')
      : t('CheckoutTracking');

  return (
    <PageWrapperMain>
      <div className='subPage'>
        <SubPageContentHolder>
          <SubPageInnerContentHolder
            title={title ? title : t('CheckoutTracking')}
            mainBtnIcon={
              currentRoute === 'css' || currentRoute === 'events'
                ? settingSVG
                : undefined
            }
            mainBtnTitle={
              currentRoute === 'css' || currentRoute === 'events'
                ? t('SetupForMe')
                : undefined
            }
            mainBtnAction={
              currentRoute === 'css' || currentRoute === 'events'
                ? () => {
                    onSubmitHandler();
                  }
                : undefined
            }
            // subTitle={subTitle}
            // backBtnLink={HOME_ROUTE}
          >
            {loading === 'pending' && <LoadingSpinner />}
            {loading === 'error' && (
              <NoData
                title={t('ErrorFetchingData')}
                subTitle={t('SomethingWentWrong-msg')}
              />
            )}

            <Routes>
              {allRoutes['/tracking/css'] && (
                <Route
                  path='css/*'
                  element={
                    loading === 'success' ? (
                      <CssClasses
                        data={apiData}
                        setAPIData={setAPIData}
                        apiKey={key}
                        setScriptKey={setKey}
                        isAutofillEnabled={isAutofillEnabled}
                        isTrackerON={isTrackerON}
                        optimizeValue={optimizeValue}
                      />
                    ) : null
                  }
                />
              )}
              {allRoutes['/tracking/autofill'] && (
                <Route
                  path='autofill/*'
                  element={
                    loading === 'success' ? (
                      <Autofill
                        setIsAutofillEnabled={setIsAutofillEnabled}
                        isAutofillEnabled={isAutofillEnabled}
                        isTrackerON={isTrackerON}
                        optimizeValue={optimizeValue}
                        data={apiData}
                        setAPIData={setAPIData}
                        setScriptKey={setKey}
                      />
                    ) : null
                  }
                />
              )}
              {allRoutes['/tracking/integration'] && (
                <Route
                  path='integration/*'
                  element={
                    key && loading === 'success' ? (
                      <Integration
                        apiKey={key}
                        isTrackerON={isTrackerON}
                        setIsTrackerON={setIsTrackerON}
                        optimizeValue={optimizeValue}
                        setOptimizeValue={setOptimizeValue}
                        isAutofillEnabled={isAutofillEnabled}
                        data={apiData}
                        setAPIData={setAPIData}
                        setScriptKey={setKey}
                      />
                    ) : null
                  }
                />
              )}
              {allRoutes['/tracking/mcp'] && (
                <Route
                  path='mcp/*'
                  element={loading === 'success' ? <MCP /> : null}
                />
              )}
              {allRoutes['/tracking/trends'] && (
                <Route
                  path='trends/*'
                  element={
                    loading === 'success' ? (
                      <Trends apiKey={key} isTrackable={isTrackable} />
                    ) : null
                  }
                />
              )}
              {allRoutes['/tracking/events'] && (
                <Route
                  path='events/*'
                  element={
                    loading === 'success' ? (
                      <EventTracking
                        apiKey={key}
                        data={apiData}
                        setAPIData={setAPIData}
                        setScriptKey={setKey}
                        isAutofillEnabled={isAutofillEnabled}
                        isTrackerON={isTrackerON}
                        optimizeValue={optimizeValue}
                      />
                    ) : null
                  }
                />
              )}
            </Routes>
          </SubPageInnerContentHolder>
        </SubPageContentHolder>
      </div>
    </PageWrapperMain>
  );
}

export default CheckoutTrackingPage;
