import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { plusSignSVG } from '../../../../lib/allCustomSVGs';
import AddPSP from './AddPSP';
import EditPSP from './EditPSP';
import ListPSP from './ListPSP';
import DeletePSP from './DeletePSP';
import store, { RootState, useAppDispatch } from '../../../../store/store';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import {
  setAlreadyAddedPSP,
  setAvailablePSP,
  setPaymentMethods,
} from '../../../../store/reducers/userSlice';
import NoData from '../../../../ui/noData/NoData';
import NoPSP from './NoPSP';
import { useTranslation } from 'react-i18next';
import getPaymentMethods from '../../../../lib/helper-functions/getPaymentMethods';
import { useSelector } from 'react-redux';
// import getOnlyVisiblePSPs from '../../../../lib/helper-functions/getOnlyVisiblePSPs';
import get_all_psp_api_call from '../../../../lib/api/psp/get-all-available-psp';
import get_all_psp_by_shop_api_call from '../../../../lib/api/psp/get-all-psp-by-shop';

const PSPConnection = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [pspArray, setPSPArray] = useState([]);
  const activeLink = location.pathname;
  const [loading, setLoading] = useState('pending');
  const dispatch = useAppDispatch();
  /* const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  ); */
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  );
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  /*   const usablePSPs = getOnlyVisiblePSPs(onboarding_object);
  const redirectNumber = 1; */

  const [alreadyAddedPSPLocal, setAvailablePSPLocal] = useState<any>();
  const [allPSPsInShopLocal, setAllPSPsInShopLocal] = useState<any>();

  /*  console.log(alreadyAddedPSPLocal);
  console.log(allPSPsInShopLocal); */

  /* ==================================== */

  const isAddButtonDisabled = () => {
    const isDisabled =
      alreadyAddedPSPLocal?.every((value: string) =>
        allPSPsInShopLocal?.includes(value)
      ) &&
      allPSPsInShopLocal?.every((value: string) =>
        alreadyAddedPSPLocal?.includes(value)
      );

    return isDisabled;
  };

  /* ==================================== */

  let subTitle = '';
  let backBtnLink = '/settings';

  if (activeLink.includes('add')) {
    subTitle = t('Create-PSP-Connection');
    if (alreadyAddedPSP.length === 0) {
      backBtnLink = '/settings';
    } else {
      backBtnLink = '/settings/payments/connection';
    }
  } else if (activeLink.includes('delete')) {
    subTitle = t('Delete-PSP-Connection');
    backBtnLink = '/settings/payments/connection';
  } else if (activeLink.includes('edit')) {
    subTitle = t('Edit-PSP-Connection');
    backBtnLink = '/settings/payments/connection';
  } else {
    // subTitle = t('ManagePSP');
    subTitle = '';
    backBtnLink = '/settings';
  }

  /* ----------------------------API CALL START---------------------------- */
  const getAvailablePSP = async () => {
    setLoading('pending');
    const response: any = await get_all_psp_api_call();

    if (!response) {
      setLoading('error');
      console.log('No Available CMS');
    }
    if (response) {
      if (response?.length === 0) {
        setLoading('null');
        console.log('No Available CMS');
      } else {
        store.dispatch(setAvailablePSP(response));
        store.dispatch(setPaymentMethods(getPaymentMethods(response)));

        //this used only for adding PSP button validation
        setAllPSPsInShopLocal(Object.keys(getPaymentMethods(response)));
        getPSP();
      }
    }
  };

  const getPSP = async () => {
    const response: any = await get_all_psp_by_shop_api_call();

    if (!response) {
      setLoading('error');
      navigate('/settings/payments/connection');
    }

    if (response?.payment_secret?.length === 0) {
      navigate('/settings/payments/connection');
      setLoading('null');
      dispatch(setAlreadyAddedPSP([]));
    } else {
      setLoading('success');
      const alreadyAddedPSP = [];
      alreadyAddedPSP.push(response);
      dispatch(setAlreadyAddedPSP(alreadyAddedPSP));

      //this used only for adding PSP button validation
      if (response?.payment_secret) {
        setAvailablePSPLocal(Object.keys(response?.payment_secret));
        navigate('/settings/payments/connection');
      }
    }
  };

  useEffect(() => {
    if (activeLink === '/settings/payments/connection') {
      getAvailablePSP();
    }
  }, [activeLink]);
  console.log(activeLink);
  useEffect(() => {
    navigate('/settings/payments/connection');
  }, []);

  /* ----------------------------API CALL END---------------------------- */

  const renderConnectionPage = () => {
    if (loading === 'pending') {
      return <LoadingSpinner />;
    } else if (loading === 'null') {
      // return <NoPSP />;
      navigate('/settings/payments/connection/add');
    } else if (loading === 'error') {
      return (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      );
    } else {
      return <ListPSP />;
    }
  };

  const mainBtnTitle = () => {
    if (
      activeLink === '/settings/payments/connection' &&
      loading === 'success'
    ) {
      return 'Add PSP';
    } else {
      return undefined;
    }
  };

  return (
    <SubPageInnerContentHolder
      title={t('Payments')}
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : backBtnLink}
      mainBtnIcon={plusSignSVG}
      mainBtnTitle={isAddButtonDisabled() ? '' : mainBtnTitle()}
      mainBtnLink='/settings/payments/connection/add'
    >
      {activeLink === '/settings/payments/connection' && renderConnectionPage()}
      {/* {activeLink === '/integrations/cms/connection' && (
        <>
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title='Error Fetching Data'
              subTitle='Something went wrong :('
            />
          )}
          {pspData?.length > 0 && <EditPSP pspData={pspData} />}
        </>
      )} */}

      <Routes>
        <Route path='/add' element={<AddPSP />} />
        <Route path='/delete/:pspId' element={<DeletePSP />} />
        <Route path='/edit/:pspId' element={<EditPSP />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default PSPConnection;
