import { AnimatePresence, motion } from 'framer-motion';
import '../../../styles/pages/quickOnboarding/quickOnboarding.scss';
import { pageSlideOutForward } from '../../../framer-animations/page-slide-in-out';
import QuickOnboardingMain from './QuickOnboardingMain';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { arrowHeadRightSVG } from '../../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';
import SidebarProfileTheme2 from '../../../components/sidebar/SidebarProfileTheme2';
import { useEffect, useRef, useState } from 'react';

const QuickOnboarding = () => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Effect to handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <motion.div {...pageSlideOutForward} className='quickOnboarding'>
      {/* <div className='quickOnboarding__left'>
        <div className='quickOnboarding__left--content'>
          <div className='quickOnboarding__left--image'>
            {merchantTheme?.merchant_logo_light &&
            merchantTheme?.merchant_logo_light !== '' ? (
              <img
                src={`${staticURL}/medium/${
                  isDarkMode
                    ? merchantTheme?.merchant_logo_light
                    : merchantTheme?.merchant_logo_dark
                }`}
                alt={'logo'}
              />
            ) : (
              <img
                src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                alt={'logo'}
              />
            )}
          </div>
        </div>
      </div> */}
      <div
        className='quickOnboarding__account'
        data-is-quickOnboarding-profile-open={isDropdownOpen.toString()}
      >
        <div
          onClick={toggleDropdown}
          ref={dropdownRef}
          className='quickOnboarding__account--content'
        >
          <div className='quickOnboarding__account--content-left'>
            {t('Account')}
          </div>
          <div className='quickOnboarding__account--content-right'>
            <CustomSVGs svg={arrowHeadRightSVG} />
          </div>
          <AnimatePresence>
            {isDropdownOpen && (
              <SidebarProfileTheme2
                isProfileLinkHidden={true}
                isSettingsLinkHidden={true}
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className='quickOnboarding__right'>
        <div className='quickOnboarding__content'>
          <QuickOnboardingMain />
        </div>
      </div>
    </motion.div>
  );
};

export default QuickOnboarding;
