import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import DashboardTab from './DashboardTab';
import NoData from '../../ui/noData/NoData';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import { useSelector } from 'react-redux';
import AiCheckoutTrackingStats from './stats/AiCheckoutTrackingStats';
import addDaysToDate from '../../lib/helper-functions/addDaysToDate';
import {
  DAYS_TO_ADD,
  defaultGraphTimeFilter,
} from '../../lib/constants/abstracts';
import { RootState } from '../../store/store';
import WebAuthnRegisterPrompt from '../webAuthn/WebAuthnRegisterPrompt';

declare const window: any;

type Props = {
  statistics: any;
};

const Dashboard = ({ statistics }: Props) => {
  const { t } = useTranslation();
  // const [statistics, setStatistics] = useState<any>({});
  const [isTimesUp, setIsTimesUp] = useState<boolean>(false);

  // const [isTrackable, setIsTrackable] = useState<boolean>(false);
  const [showTabs, setShowTabs] = useState<boolean>(false);
  const [timeFilter, setTimeFilter] = useState<string>(defaultGraphTimeFilter);
  const [customPeriodFilterDateRange, setCustomPeriodFilterDateRange] =
    useState<{
      start_date: string | undefined;
      end_date: string | undefined;
    }>({ start_date: undefined, end_date: undefined });
  const [eventFilter, setEventFilter] = useState<string>('purchase');
  const isAdminSession = useSelector(
    (state: RootState) => state.auth.authorization?.isAdminSession ?? false
  );
  const CMS_Type = useSelector(
    (state: RootState) => state.user.company?.cmsSelected ?? ''
  );
  const isTrackable = useSelector(
    (state: RootState) => state.user.stats?.is_trackable
  );
  const key = useSelector(
    (state: RootState) => state.user.stats?.ai_tracker_api_key
  );
  const isLoggedInViaWebAuthn =
    useSelector(
      (state: RootState) =>
        state.auth?.authorization?.webAuthn?.isLoggedInViaWebAuthn
    ) ?? false;
  const isWebAuthnRegistrationSkipped =
    useSelector(
      (state: RootState) =>
        state.auth?.authorization?.webAuthn?.isWebAuthnRegistrationSkipped
    ) ?? false;
  const isWebAuthnAvailableInAccount =
    useSelector(
      (state: RootState) =>
        state.auth?.authorization?.webAuthn?.isWebAuthnAvailableInAccount
    ) ?? false;
  const [
    shouldShowWebAuthnRegistrationPrompt,
    setShouldShowWebAuthnRegistrationPrompt,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  useEffect(() => {
    setLoading('pending');
    if (statistics) {
      setLoading('success');
    } else {
      setLoading('null');
    }
  }, [statistics]);

  // Check if the tracking time is up
  useEffect(() => {
    if (statistics?.additional_stats?.tracker_configured_timestamp) {
      const dateTimeISOString =
        statistics?.additional_stats?.tracker_configured_timestamp;
      const futureDate = addDaysToDate(
        dateTimeISOString ? dateTimeISOString : '',
        DAYS_TO_ADD
      );

      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const targetTime = futureDate.getTime();
        const difference = targetTime - now;

        if (difference <= 0) {
          setIsTimesUp(true);
        }
      };

      calculateTimeLeft();
    }
  }, [statistics]);

  /* --------------------------------------------- */

  useEffect(() => {
    if (isTimesUp && key !== '') {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
  }, [isTimesUp, key, statistics]);
  /* --------------------------------------------- */

  /* --------------- WebAuthn Registration Prompt show/hide logic - START --------------- */
  useEffect(() => {
    const shouldShowPrompt = () => {
      //* If WebAuthn is not supported, hide registration prompt
      if (!window.Webpass.isSupported()) {
        return false;
      }
      //* If user is already logged in via WebAuthn, hide registration prompt
      if (isLoggedInViaWebAuthn) {
        return false;
      }
      //* If user has skipped WebAuthn registration, hide registration prompt
      if (isWebAuthnRegistrationSkipped) {
        return false;
      }
      //* If user logged in as an Admin, hide registration prompt
      if (isAdminSession) {
        return false;
      }
      return true;
    };

    //* Commented below for the MCP Launch
    // setShouldShowWebAuthnRegistrationPrompt(shouldShowPrompt());
    //* Added below for the MCP Launch
    setShouldShowWebAuthnRegistrationPrompt(false);
  }, [
    isWebAuthnAvailableInAccount,
    isLoggedInViaWebAuthn,
    isWebAuthnRegistrationSkipped,
  ]);
  /* --------------- WebAuthn Registration Prompt show/hide logic - END --------------- */

  return (
    <>
      {!shouldShowWebAuthnRegistrationPrompt ? (
        <>
          <PageWrapperMain>
            <SubPageContentHolder>
              <SubPageInnerContentHolder
                title={t('DashboardOverview')}
                dashboardEventFilterHandler={
                  CMS_Type === 'shopify' ? undefined : setEventFilter
                }
                eventFilter={CMS_Type === 'shopify' ? undefined : eventFilter}
                dashboardPeriodFilterHandler={setTimeFilter}
                timeFilter={timeFilter}
                setCustomPeriodFilterDateRange={(value) => {
                  setCustomPeriodFilterDateRange(value);
                  if (
                    value?.start_date !== undefined &&
                    value?.end_date !== undefined
                  ) {
                    setTimeFilter('custom');
                  }
                }}
                customPeriodFilterDateRange={customPeriodFilterDateRange}
                // subTitle={t('DashboardSubTitle')}
              >
                {loading === 'pending' && <LoadingSpinner />}
                {loading === 'error' && (
                  <NoData
                    title={t('ErrorFetchingData')}
                    subTitle={t('SomethingWentWrong-msg')}
                  />
                )}
                {loading === 'success' && (
                  <>
                    {showTabs && !isTimesUp && (
                      <DashboardTab stats={statistics} isTimesUp={isTimesUp} />
                    )}
                    <AiCheckoutTrackingStats
                      isTrackable={isTrackable}
                      isTimesUp={isTimesUp}
                      stats={statistics}
                      timeFilter={timeFilter}
                      customPeriodFilterDateRange={customPeriodFilterDateRange}
                      eventFilter={eventFilter}
                    />
                  </>
                )}
              </SubPageInnerContentHolder>
            </SubPageContentHolder>
          </PageWrapperMain>
        </>
      ) : (
        <WebAuthnRegisterPrompt />
      )}
    </>
  );
};

export default Dashboard;
