const words = ['add_to_cart', 'pay_button', 'go_to_checkout'];

export default function capitalizeFirstLetterOfEachWord(
  sentence: string
): string {
  if (sentence.length === 0) {
    return sentence;
  }

  // Convert the entire sentence to lowercase
  sentence = sentence.toLowerCase();

  // Loop through each word in the list and transform it if found
  words.forEach((word) => {
    const wordSplit = word
      .split('_')
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');
    const regex = new RegExp(word, 'g');
    sentence = sentence.replace(regex, wordSplit);
  });

  // Capitalize the first letter of the first word in the sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}
