import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import { useSelector } from 'react-redux';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';
import { defaultGraphTimeFilter } from '../../../../lib/constants/abstracts';

interface DataPoint {
  week: string;
  total_carts: number;
  total_checkouts: number;
  conversion_rate: number;
}

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const WeeklyConversionRateLineChart = ({
  timeFilter = defaultGraphTimeFilter,
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [title, setTitle] = useState<string>(t('WeeklyConversionRate'));

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&group_by=weekly&time_range=${timeFilter}&event_type=${eventFilter}`
  );

  const [response, setResponse] = useState<any>([]);

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      // Call to get conversion rate stats by location
      const res: DataPoint[] | undefined = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (Object.keys(res)?.length === 0) {
          setLoading('null');
          return;
        } else {
          const sortedRes = res?.sort(
            (a, b) => new Date(a.week).getTime() - new Date(b.week).getTime()
          );
          setResponse(sortedRes);
          setLoading('success');
        }
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
      setLoading('null');
    }
  };

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [stats, params]);

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=conversion_rate&group_by=weekly&time_range=${timeFilter}${dateRangeURLParam}${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    const title1 =
      eventFilter === 'purchase'
        ? t('WeeklyConversionRate')
        : `${t('Weekly')} ${eventFilter} ${t('ClickThroughRate')}`;
    setTitle(capitalizeFirstLetterOfEachWord(title1));
  }, [eventFilter]);

  /* ========================== */

  const valueFormatter = (value: number | null) => `${value}%`;
  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{title}</h2>
      <div className='pieChartComponent__chart'>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle={t('NeedMoreInteractionsToGenerateStats')}
          />
        )}
        {loading === 'success' && (
          <div className='pieChartComponent__chart--container'>
            <div className='pieChartComponent__chart--container-chart'>
              <LineChart
                dataset={response}
                // xAxis={[{ scaleType: 'band', dataKey: 'week', label: 'Date' }]}
                xAxis={[{ scaleType: 'band', dataKey: 'week' }]}
                yAxis={[
                  {
                    // label: 'Conversion Rate (%)',
                    min: 0,
                    max: 100,
                  },
                ]}
                series={[{ dataKey: 'conversion_rate', valueFormatter }]}
                height={315}
                colors={['#8884d8']}
                margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
                grid={{ vertical: true, horizontal: true }}
                sx={(theme) => ({
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#8b909a',
                      strokeWidth: 3,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: '#8b909a',
                    },
                  },
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    fill: '#8b909a',
                    // transform: 'translate(-10px, -10px)',
                  },
                  [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                    fill: '#8b909a', // Change color of x-axis label
                  },
                  [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                    fill: '#8b909a', // Change color of x-axis tick labels
                  },
                })}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyConversionRateLineChart;
