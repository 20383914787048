import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { editSVG, trashSVG } from '../../../../lib/allCustomSVGs';
import Popup from '../../../../ui/popup/Popup';
import { useTranslation } from 'react-i18next';
import RadioButtonSVG from '../../../../assets/svg/RadioButtonSVG';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import get_custom_events_by_integration_key_api_call from '../../../../lib/api/customEvents/get_custom_events_by_integration_key_api_call';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import store_custom_events_by_integration_key_api_call from '../../../../lib/api/customEvents/store_custom_events_by_integration_key_api_call';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import delete_custom_events_by_integration_key_api_call from '../../../../lib/api/customEvents/delete_custom_events_by_integration_key_api_call';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';

type Event = {
  id: string;
  name: string;
  type: string;
  value: string;
  matchTypeOrParam: string;
  active: boolean;
};

interface Props {
  apiKey: string;
  eventsFetchTriggerCount: number;
  eventTypeArr: { OptionValue: string; OptionName: string }[];
  matchTypeArr: { OptionValue: string; OptionName: string }[];
  events: Event[];
  setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
  filterExistingEventNames: (
    value: string,
    isUpdateMode?: boolean,
    currentEventId?: string
  ) => boolean;
  filterAdUpEventNames: (value: string) => boolean;
}

const CustomEventTable: React.FC<Props> = ({
  apiKey,
  eventsFetchTriggerCount,
  eventTypeArr,
  matchTypeArr,
  events,
  setEvents,
  filterExistingEventNames,
  filterAdUpEventNames,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<'pending' | 'success' | 'error'>(
    'pending'
  );

  const [editingEvent, setEditingEvent] = useState<Event>();

  const [eventName, setEventName] = useState('');
  const [eventType, setEventType] = useState(eventTypeArr[0].OptionValue);
  const [url, setURL] = useState('');
  const [matchType, setMatchType] = useState(matchTypeArr[0].OptionValue);
  const [parameterName, setParameterName] = useState('');
  const [parameterValue, setParameterValue] = useState('');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isEventNameInputInvalid, setIsEventNameInputInvalid] =
    useState<boolean>(false);

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(false);

  const toggleEditPopupModal = () => {
    if (!isEditPopupOpen === false) {
      setEditingEvent(undefined);
    }
    setIsEditPopupOpen(!isEditPopupOpen);
  };

  const fetchEvents = async () => {
    try {
      const response = await get_custom_events_by_integration_key_api_call(
        apiKey
      );
      if (Array.isArray(response) && response.length > 0) {
        const fetchedEvents: Event[] = response?.map((event: any) => ({
          id: event.id,
          name: event.name,
          type: event.type,
          value:
            event.type === 'url'
              ? event.configurations.url
              : event.type === 'parameter'
              ? event.configurations.parameter_name
              : 'N/A',
          matchTypeOrParam:
            event.type === 'url'
              ? event.configurations.match_type
              : event.type === 'parameter'
              ? event.configurations.parameter_value
              : 'N/A',
          active: event.is_active,
        })) as any;

        // Sort the events by type
        const sortedEvents = fetchedEvents.sort((a, b) =>
          b.type.localeCompare(a.type)
        );
        setEvents(sortedEvents);
      } else {
        setEvents([]);
      }
      setLoading('success');
      setIsEditPopupOpen(false);
      setEditingEvent(undefined);
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error fetching custom events:', error);
      setEvents([]);
      setLoading('error');
      setIsEditPopupOpen(false);
      setEditingEvent(undefined);
      setIsSubmitting(false);
    }
  };

  const validateEventNameInput = (value: string) => {
    setEventName(value);

    const snake_case_pattern = /^[a-z]+(_[a-z]+)*$/;
    if (isNotEmptyString(value)) {
      if (snake_case_pattern.test(value)) {
        setIsEventNameInputInvalid(false);
      } else {
        setIsEventNameInputInvalid(true);
      }
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    const eventNameAlreadyExists = filterExistingEventNames(
      eventName,
      true,
      editingEvent?.id
    );

    if (eventNameAlreadyExists) {
      store.dispatch(
        setMessage({
          message: `${t('DuplicateCustomEventName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    const eventNameIsAdUpEvent = filterAdUpEventNames(eventName);

    if (eventNameIsAdUpEvent) {
      store.dispatch(
        setMessage({
          message: `${t('AdUpEventName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    setIsSubmitting(true);

    const res = await store_custom_events_by_integration_key_api_call(apiKey, [
      {
        id: editingEvent?.id,
        name: eventName,
        type: eventType,
        configurations:
          eventType === 'url'
            ? {
                url: url,
                match_type: matchType,
              }
            : eventType === 'parameter'
            ? {
                parameter_name: parameterName,
                parameter_value: parameterValue,
              }
            : undefined,
        is_active: editingEvent?.active,
      },
    ]);

    if (res && res?.success) {
      fetchEvents();
    } else {
      setIsSubmitting(false);
    }
  };

  const handleActiveToggle = async (event: Event) => {
    setEditingEvent(event);
    const res = await store_custom_events_by_integration_key_api_call(
      apiKey,
      [
        {
          id: event.id,
          name: event.name,
          type: event.type,
          configurations:
            event.type === 'url'
              ? {
                  url: event.value,
                  match_type: event.matchTypeOrParam,
                }
              : event.type === 'parameter'
              ? {
                  parameter_name: event.value,
                  parameter_value: event.matchTypeOrParam,
                }
              : undefined,
          is_active: !event.active,
        },
      ],
      false //shouldShowMsg
    );

    if (res && res?.success) {
      setEvents((prevEvents) =>
        prevEvents.map((e) =>
          e.id === event.id ? { ...e, active: !e.active } : e
        )
      );
    }

    setEditingEvent(undefined);
  };

  const handleEdit = (event: Event) => {
    setEditingEvent(event);
    toggleEditPopupModal();
  };

  const handleDelete = async (event: Event) => {
    setIsDeleteDisabled(true);
    const deleteSuccess =
      await delete_custom_events_by_integration_key_api_call(apiKey, event.id);
    if (deleteSuccess) {
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      store.dispatch(
        setMessage({
          message: `${t('CustomEventDeletedMsg')}`,
          messageType: 'success',
        })
      );
    }
    setIsDeleteDisabled(false);
  };

  useEffect(() => {
    fetchEvents();
  }, [apiKey, eventsFetchTriggerCount]);

  useEffect(() => {
    // Reset the form fields when the editing event changes
    setEventName('');
    setEventType(eventTypeArr[0].OptionValue);
    setURL('');
    setMatchType(matchTypeArr[0].OptionValue);
    setParameterName('');
    setParameterValue('');

    // Set the form fields to the editing event's values
    if (editingEvent) {
      setEventName(editingEvent.name);
      setEventType(editingEvent.type);
      if (editingEvent.type === 'url') {
        setURL(editingEvent.value);
        setMatchType(editingEvent.matchTypeOrParam);
      } else if (editingEvent.type === 'parameter') {
        setParameterName(editingEvent.value);
        setParameterValue(editingEvent.matchTypeOrParam);
      }
    }
  }, [editingEvent]);

  useEffect(() => {
    if (
      !isNotEmptyString(eventName) ||
      (eventType === 'url' && !isNotEmptyString(url)) ||
      (eventType === 'parameter' &&
        (!isNotEmptyString(parameterName) || !isNotEmptyString(parameterValue)))
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [eventName, eventType, url, parameterName, parameterValue]);

  const renderRadioButtons = () => {
    return (
      <div className='customEvent__radioInputs'>
        <div className='customEvent__radioInputs--label'>{t('MatchType')}</div>
        <div className='customEvent__radioInputs--radioItems'>
          <div
            className='customEvent__radioInputs--radioItems-radio'
            onClick={() => setMatchType('contains')}
          >
            <div className='customEvent__radioInputs--radioItems-radio-input'>
              <RadioButtonSVG
                isChecked={matchType === 'contains'}
                fillColor={'var(--main-text-color)'}
              />
            </div>
            <label htmlFor='CustomEvent__radio-1'>{t('Contains')}</label>
          </div>
          <div
            className='customEvent__radioInputs--radioItems-radio'
            onClick={() => setMatchType('exact')}
          >
            <div className='customEvent__radioInputs--radioItems-radio-input'>
              <RadioButtonSVG
                isChecked={matchType === 'exact'}
                fillColor={'var(--main-text-color)'}
              />
            </div>
            <label htmlFor='CustomEvent__radio-2'>{t('ExactMatch')}</label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <h1 className='customEvent-title'>{t('ExistingEvents')}</h1>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Name')}</TableCell>
                <TableCell>{t('Type')}</TableCell>
                <TableCell>{t('Value')}</TableCell>
                <TableCell>{t('MatchType/Parameter')}</TableCell>
                <TableCell>{t('Active')}</TableCell>
                <TableCell>{t('Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event, index) => (
                <TableRow key={index}>
                  <TableCell>{event.name}</TableCell>
                  <TableCell>
                    {
                      eventTypeArr.find(
                        (eventType) => eventType.OptionValue === event.type
                      )?.OptionName
                    }
                  </TableCell>
                  <TableCell>{event.value}</TableCell>
                  <TableCell>
                    {event.type === 'url'
                      ? matchTypeArr.find(
                          (matchType) =>
                            matchType.OptionValue === event.matchTypeOrParam
                        )?.OptionName
                      : event.matchTypeOrParam}
                  </TableCell>
                  <TableCell>
                    <ToggleSwitch
                      disabled={event.id === editingEvent?.id}
                      isOn={event.active}
                      onClick={() => handleActiveToggle(event)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className='customEvent__actions'>
                      <div
                        className='customEvent__editButton'
                        onClick={() => handleEdit(event)}
                      >
                        <CustomSVGs svg={editSVG} />
                      </div>
                      <div
                        className='customEvent__deleteButton'
                        onClick={() => {
                          if (!isDeleteDisabled) {
                            handleDelete(event);
                          }
                        }}
                      >
                        <CustomSVGs svg={trashSVG} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Popup
        styles={{ backgroundColor: '#fff', width: '80%' }}
        setTrigger={toggleEditPopupModal}
        trigger={isEditPopupOpen}
      >
        <div className='customEvent__editPopup'>
          <form
            id='customEvent-form-update'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <h1 className='customEvent-title'>{t('EditEvent')}</h1>
            <div className='normalForm__top'>
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <AINormalInput
                    changeListeners={[
                      (event) =>
                        validateEventNameInput(
                          (event.target as HTMLInputElement).value
                        ),
                    ]}
                    id='CustomEvent__event-name-input'
                    value={eventName}
                    placeholder={t('EventName')}
                    required={true}
                    innerPlaceholder={t('EnterEventName')}
                    pattern='^[a-z]+(_[a-z]+)*$'
                    title={t('CustomEventName-validation')}
                  />
                </div>

                <div className='normalForm__container1--right'>
                  <AISelectInput
                    defaultValue={eventType}
                    setSelect={setEventType}
                    selectOptionArr={eventTypeArr}
                    placeholder={t('EventType')}
                    id={'CustomEvent__event-type-select'}
                  />
                </div>
              </div>

              {/* event type URL inputs */}
              {eventType === 'url' ? (
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <AINormalInput
                      changeListeners={[(e) => setURL(e.target.value)]}
                      id='CustomEvent__event-url-input'
                      value={url}
                      placeholder={t('URL')}
                      required={true}
                      innerPlaceholder={t('EnterURL')}
                    />
                  </div>

                  <div className='normalForm__container1--right'>
                    {renderRadioButtons()}
                  </div>
                </div>
              ) : null}

              {/* event type parameter inputs */}
              {eventType === 'parameter' ? (
                <div className='normalForm__container1'>
                  <div className='normalForm__container1--left'>
                    <AINormalInput
                      changeListeners={[
                        (e) => setParameterName(e.target.value),
                      ]}
                      id='CustomEvent__event-parameter-name-input'
                      value={parameterName}
                      placeholder={t('ParameterName')}
                      required={true}
                      innerPlaceholder={t('EnterParameterName')}
                    />
                  </div>

                  <div className='normalForm__container1--right'>
                    <AINormalInput
                      changeListeners={[
                        (e) => setParameterValue(e.target.value),
                      ]}
                      id='CustomEvent__event-parameter-value-input'
                      value={parameterValue}
                      placeholder={t('ParameterValue')}
                      required={true}
                      innerPlaceholder={t('EnterParameterValue')}
                    />
                  </div>
                </div>
              ) : null}

              <div className='normalForm__bottom flex-start'>
                <MainSquareButton
                  value={
                    isSubmitting ? `${t('Updating')}...` : t('UpdateEvent')
                  }
                  disabled={
                    isDisabled || isSubmitting || isEventNameInputInvalid
                  }
                  type='submit'
                  form='customEvent-form-update'
                />
              </div>
            </div>
          </form>
        </div>
      </Popup>
    </>
  );
};

export default CustomEventTable;
