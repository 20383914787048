import { useEffect, useRef } from 'react';
import '../../../styles/pages/tara/chat/taraChat.scss';
import TaraChatItemAI from './TaraChatItemAI';
import TaraChatItemUser from './TaraChatItemUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const TaraChat = () => {
  // Get currentChatID and compute currentChat from allChatsHistory.
  const currentChatID = useSelector(
    (state: RootState) => state.chat.currentChatID
  );
  const allChatsHistory = useSelector(
    (state: RootState) => state.chat.allChatsHistory
  );
  const currentChat =
    allChatsHistory.find((chat) => chat.chatId === currentChatID) || null;
  const isBotTyping = useSelector((state: RootState) => state.chat.isBotTyping);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [currentChat?.chatMessages, isBotTyping]);

  return (
    <div className='taraChat' ref={chatContainerRef}>
      <div className='taraChat__content'>
        {currentChat?.chatMessages?.map((chatMessage, i) => {
          if (chatMessage.role === 'user') {
            return (
              <TaraChatItemUser
                key={`${chatMessage.id}-${i}`}
                message={chatMessage.message}
                messageType={chatMessage.messageType}
              />
            );
          } else {
            return (
              <TaraChatItemAI
                key={`${chatMessage.id}-${i}`}
                message={chatMessage.message}
                messageType={chatMessage.messageType}
                isTyping={false}
              />
            );
          }
        })}
        {isBotTyping && (
          <TaraChatItemAI
            key='typing-indicator'
            message=''
            messageType='text'
            isTyping={true}
          />
        )}
      </div>
    </div>
  );
};

export default TaraChat;
