import '../../styles/buttons/toggleSwitch.scss';

interface Props {
  title?: string;
  isOn?: boolean;
  onClick?: any;
  click?: any;
  classes?: string;
  id?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
  message?: string;
}

const ToggleSwitch = ({
  title,
  onClick,
  isOn,
  classes,
  id,
  defaultChecked,
  disabled,
  click,
  message,
}: Props) => {
  return (
    <div
      title={disabled ? message : ''}
      className={`toggle-container ${classes ? classes : ''}`}
    >
      <label className='toggle' htmlFor={id}>
        <input
          onChange={(e) => {
            onClick(e.target.checked);
          }}
          onClick={click}
          checked={isOn}
          defaultChecked={defaultChecked}
          type='checkbox'
          className='toggle__input'
          style={{ fontFamily: 'var(--main-font-secondary)' }}
          id={id}
          disabled={disabled}
        />
        <span data-is-toggle={isOn ? 'true' : 'false'} className='toggle-track'>
          <span className='toggle-indicator'>
            <span className='checkMark'>
              <svg
                viewBox='0 0 24 24'
                id='ghq-svg-check'
                role='presentation'
                aria-hidden='true'
              >
                <path d='M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z'></path>
              </svg>
            </span>
          </span>
        </span>
      </label>
      <p data-is-toggle={disabled ? 'true' : 'false'}>{title}</p>
    </div>
  );
};

export default ToggleSwitch;
