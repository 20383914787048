import { Chat } from '../../interfaces/taraInterfaces';

export default function getAllChatsByDateArray(allChatsHistory: Chat[]) {
  const chats = [...allChatsHistory]; // Creates a new copy

  // Sort the chats by chatLastUpdatedDate in descending order (newest first)
  const sortedChats = chats.sort((a, b) => {
    const dateA = new Date(a.chatLastUpdatedDate);
    const dateB = new Date(b.chatLastUpdatedDate);

    return dateB.getTime() - dateA.getTime();
  });

  if (sortedChats && sortedChats?.length > 0) {
    return sortedChats;
  } else {
    return [];
  }
}
