import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat, ChatState } from '../../interfaces/taraInterfaces';

const initialState: ChatState = {
  allChatsHistory: [],
  currentChatID: null,
  isBotTyping: false,
  historyLoading: 'null',
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setAllChatsHistory: (state, action: PayloadAction<Chat[]>) => {
      state.allChatsHistory = action.payload;
    },
    setCurrentChatID: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.currentChatID = action.payload;
    },
    setIsBotTyping: (state, action: PayloadAction<boolean>) => {
      state.isBotTyping = action.payload;
    },
    setHistoryLoading: (
      state,
      action: PayloadAction<'pending' | 'success' | 'error' | 'null'>
    ) => {
      state.historyLoading = action.payload;
    },
    clearChatSlice: (state) => {
      state.allChatsHistory = [];
      state.currentChatID = null;
      state.isBotTyping = false;
      state.historyLoading = 'null';
    },
  },
});

export const {
  setAllChatsHistory,
  setCurrentChatID,
  setIsBotTyping,
  setHistoryLoading,
  clearChatSlice,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
