import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { SHOPIFY_AUTH_API } from '../../constants/central-api';

export default async function shopify_auth_api_call(data: {
  code: string;
  shop_domain: string;
  shop_id: string;
}) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shopSlug = store.getState().user?.company?.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!shopSlug) {
    return false;
  }

  const requestInfo: RequestInfo = {
    url: SHOPIFY_AUTH_API,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
    body: JSON.stringify(data),
  };

  const actionType = 'AUTHENTICATE_SHOPIFY';
  const customMsg = 'Shopify Authenticated Successfully.';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return false;
    } else {
      return response?.data;
    }
  }
}
