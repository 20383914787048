import React from 'react';
import '../../../styles/pages/tara/taraUI/taraHeading.scss';
import { useTranslation } from 'react-i18next';
import TaraBasicButton from './TaraBasicButton';
import { bellSVG } from '../../../lib/allCustomSVGs';

const TaraHeading = () => {
  const { t } = useTranslation();

  const initialHeading = () => {
    return (
      <div className='taraHeading__initial'>
        <h1>{t('What can I help you with?')}</h1>

        {/* <TaraBasicButton
          onClick={() => {}}
          value={t('Setup notification rules')}
          type='button'
          buttonUse='yellow'
          icon={bellSVG}
        /> */}
      </div>
    );
  };

  return <>{initialHeading()}</>;
};

export default TaraHeading;
