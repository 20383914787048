import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Components } from 'react-markdown/lib/ast-to-react';

type MarkdownMessageProps = {
  content: string;
};

const MarkdownMessage = ({ content }: MarkdownMessageProps) => {
  const components: Components = {
    strong: ({ node, ...props }) => <strong {...props} />,
    em: ({ node, ...props }) => <em {...props} />,
    del: ({ node, ...props }) => <s {...props} />,
    a: ({ node, ...props }) => (
      <a target='_blank' rel='noopener noreferrer' {...props} />
    ),
    code: ({ node, inline, ...props }) => <code {...props} />,
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={components}
      className='react-markdown'
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownMessage;
