import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { POST_TARA_CHAT_API } from '../../constants/central-api';

export default async function tara_chat_api_call(
  message: string,
  signal?: AbortSignal
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shop_slug = store.getState().user.company.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  // Instead of currentChat, we use currentChatID and look it up in history.
  const currentChatID = store.getState().chat.currentChatID;
  const allChatsHistory = store.getState().chat.allChatsHistory;
  const currentChat = allChatsHistory.find(
    (chat: any) => chat.chatId === currentChatID
  );

  let body: any = { message };

  // For subsequent calls, send conversation_id.
  if (
    currentChat &&
    currentChat.chatMessages &&
    currentChat.chatMessages.length > 1 &&
    currentChat.chatId
  ) {
    body.conversation_id = currentChat.chatId;
  }

  const requestInfo: RequestInfo = {
    url: POST_TARA_CHAT_API(shop_slug),
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
    signal, // attach the AbortController's signal if provided
  };

  const actionType = 'POST_TARA_CHAT_API';
  const customMsg = 'Tara Chat API Successful.';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );
  // NEW: Check if the request was aborted.
  if (res && res.aborted) {
    return res;
  }
  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return null;
    } else {
      return response?.data;
    }
  }
}
