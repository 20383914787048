import React from 'react';
import '../../../styles/pages/tara/taraUI/taraBasicButton.scss';

type props = {
  onClick: (event: React.MouseEvent) => void;
  icon?: any;
  value?: string;
  classes?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonUse?: 'normal' | 'yellow' | undefined;
  title?: string;
};

const TaraBasicButton: React.FC<props> = ({
  onClick,
  icon,
  value,
  type,
  disabled,
  classes = '',
  buttonUse,
  title,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={'taraBasicButton' + (' ' + classes)}
      title={title}
      data-tara-basic-button-use={buttonUse ? buttonUse : 'normal'}
    >
      {icon}
      <p>{value}</p>
    </button>
  );
};

export default TaraBasicButton;
