import { useEffect, useState } from 'react';
import '../../../styles/components/charts/chart.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../ChartWithConfig';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import ChartLegend from '../ChartLegend';
import makeFirstLetterCapital from '../../../lib/helper-functions/makeFirstLetterCapital';
import { ResponsiveFunnel } from '@nivo/funnel';
import { defaultGraphTimeFilter } from '../../../lib/constants/abstracts';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
};

const FunnelGraph = ({
  timeFilter = defaultGraphTimeFilter,
  customPeriodFilterDateRange,
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=funnel_graph&time_range=${timeFilter}`
  );

  const [response, setResponse] = useState<any[]>([]);

  //Comments and titles
  const [title, setTitle] = useState<string>(t('PurchaseFunnelView'));

  //   const [comment, setComment] = useState<string>(
  //     t('TheConversionRateOfCartsToCheckoutsBasedOnScreenSize')
  //   );

  const colors = ['#B3E2CD', '#FDCDAC', '#CBD5E8', '#F4CAE4'];

  // const AdditionalStatsData = [
  //   {
  //     label: t('CompanyOverallCarts'),
  //     value: additionalStats?.companyOverallCarts,
  //     fillColor: '#84b6d8',
  //     svg: addedCartSVG,
  //   },
  // ];

  const transformDataForFunnel = (data: any) => {
    if (Object.keys(data)?.length === 0) {
      return [];
    }

    const chartData = Object.keys(data)
      ?.map((item) => ({
        id: item,
        label: makeFirstLetterCapital(item?.replaceAll('_', ' ')),
        value: data[item]?.percentage,
      }))
      ?.sort((a, b) => b.value - a.value); // 'b.value - a.value' for descending order
    // Sort the mapped array by the 'value' in descending order

    return chartData?.map((item, index) => ({
      ...item,
      color: colors[index],
    }));
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      //   Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );
      if (res) {
        if (res?.overall && res?.categories) {
          const newChartData = transformDataForFunnel(res?.overall);

          setResponse(newChartData);
          setLoading('success');
        } else {
          setResponse([]);
          setLoading('null');
        }
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    setParams(
      `?graph_type=funnel_graph&time_range=${timeFilter}${dateRangeURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse([]);
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  function renderChart() {
    return (
      <>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle={t('NeedMoreInteractionsToGenerateStats')}
          />
        )}

        {loading === 'success' && (
          <>
            <div className='chart__bottom--content-chart'>
              <div
                style={{ height: '28rem' }}
                className='chart__bottom--content-chart-pie'
                data-chart='funnel'
              >
                <ResponsiveFunnel
                  data={response}
                  //   margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  direction='horizontal'
                  valueFormat={(value) => `${value}%`}
                  colors={{ scheme: 'pastel2' }}
                  borderWidth={20}
                  borderOpacity={0.5}
                  labelColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  theme={{
                    labels: {
                      text: {
                        fontSize: 15,
                        fontWeight: 600,
                      },
                    },
                  }}
                  beforeSeparatorLength={10}
                  beforeSeparatorOffset={20}
                  afterSeparatorLength={10}
                  afterSeparatorOffset={20}
                  currentPartSizeExtension={10}
                  currentBorderWidth={40}
                  motionConfig='gentle'
                />
              </div>
              <div className='chart__bottom--content-chart-legend'>
                <div className='chart__bottom--content-chart-legend-content'>
                  {response?.map((item, index) => (
                    <ChartLegend
                      key={index}
                      color={item?.color}
                      label={item?.label}
                      value={item?.value}
                      symbol='%'
                    />
                  ))}
                </div>
              </div>
            </div>
            {/* <ChartAdditionalStats stats={AdditionalStatsData} /> */}
          </>
        )}
      </>
    );
  }

  return (
    <ChartWithConfig title={title} isConfigHidden={true}>
      {renderChart()}
    </ChartWithConfig>
  );
};

export default FunnelGraph;
