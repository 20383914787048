import '../../../styles/pages/quickOnboarding/quickOnboardingCompany.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import AIPhoneInput from '../../../ui/inputs/AIPhoneInput';
import AIURLInput from '../../../ui/inputs/AIURLInput';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import {
  setAiOnboardingInprogress,
  setAiOnboardingSelectedIntegrate,
  setAiOnboardingStepCompleted,
  setUserCompanyDetails,
  updateProfile,
} from '../../../store/reducers/userSlice';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import {
  CREATE_SHOP_API,
  DETECT_CMS_API,
  REGISTRATION_API,
  SHOP_API,
} from '../../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { submitLogToBugsnag } from '../../../lib/api/log';
import getCountryByDialCode from '../../../lib/helper-functions/getCountryByDialCode';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';
import update_all_shop_stats_api_call from '../../../lib/api/dashboard/update_all_shop_stats_api_call';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../../lib/constants/route';

const QuickOnboardingCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // global state
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );

  const seller_id = useSelector(
    (state: RootState) => state.user.profile.seller_id
  );

  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);

  const userProfile = useSelector((state: RootState) => state.user.profile);
  const companyDetails = useSelector((state: RootState) => state.user.company);

  const getURL = () => {
    if (companyDetails?.webshop_url) {
      return companyDetails?.webshop_url.replace('https://', '');
    } else {
      return '';
    }
  };

  function ensureHttps(url: string): string {
    // Trim leading/trailing spaces
    url = url.trim();

    // Check if the URL starts with 'http://' or 'https://'
    const regex = /^(https?:\/\/)/i;

    // If the URL starts with 'http://', replace it with 'https://'
    if (url.startsWith('http://')) {
      return 'https://' + url.slice(7);
    }

    // If the URL starts with 'https://', return it as is
    if (url.startsWith('https://')) {
      return url;
    }

    // If the URL has no scheme but starts with 'www.', add 'https://'
    if (url.startsWith('www.')) {
      return 'https://' + url;
    }

    // Handle cases like 'https:www.example.com' where there's a typo
    if (url.includes('https:') && !url.includes('https://')) {
      // Replace the first colon after 'https' with a slash
      url = url.replace('https:', 'https://');
    }

    // Otherwise, assume the user provided a domain and prepend 'https://'
    return 'https://' + url;
  }

  // local state
  const [loading, setLoading] = useState<'pending' | 'initial'>('initial');
  const [url, setURL] = useState(getURL());

  const IPCountry = useSelector(
    (state: RootState) => state.app.countries.countryOfIP
  );
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  );
  const appLanguage = useSelector((state: RootState) => state.app.language);
  const [phoneNumber, setPhoneNumber] = useState({
    number: userProfile.phone,
    country: userProfile.country_code
      ? getCountryByDialCode(userProfile.country_code)
      : countries[IPCountry],
  });
  const merchantSlug = useSelector(
    (state: RootState) => state.user.merchant.merchant_slug
  );
  const url_prefix = 'https://';

  async function submitProfile() {
    const sendingData = {
      contact: phoneNumber.number,
      country_code: phoneNumber.country?.dialCode?.substring(1),
      language: isNotEmptyString(userProfile?.language)
        ? userProfile?.language
        : isNotEmptyString(appLanguage)
        ? appLanguage
        : 'en',
    };
    try {
      const requestInfo: RequestInfo = {
        url: `${REGISTRATION_API(merchantSlug)}/${seller_id}`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'ONBOARDING_PROFILE_UPDATE';
      const customMsg = 'Profile Update Successful.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        false,
        true
      );

      const response = res.data;
      if (res.actionType === actionType && response.success === true) {
        const { user } = response?.data;

        if (user?.role[0]?.name === 'seller') {
          if (user?.seller_id) {
            dispatch(
              updateProfile({
                country_code: phoneNumber.country?.dialCode?.substring(1),
                phone: phoneNumber.number,
                language: isNotEmptyString(userProfile?.language)
                  ? userProfile?.language
                  : isNotEmptyString(appLanguage)
                  ? appLanguage
                  : 'en',
              })
            );
            return true;
          }
        }
      } else {
        setLoading('initial');
        return false;
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function detectCMS() {
    try {
      const sendingURL = ensureHttps(url);
      const requestInfo: RequestInfo = {
        url: DETECT_CMS_API,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({ url: sendingURL }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'DETECT_CMS';
      const customMsg = 'CMS Detection Successful.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        false,
        true
      );

      const response = res.data;
      if (res.actionType === actionType && response?.data && response.success) {
        if (response?.data?.website_name) {
          if (response?.data?.cms === 'shopify') {
            dispatch(setAiOnboardingSelectedIntegrate('shopify'));
          }
          return response?.data?.website_name;
        } else {
          setLoading('initial');
        }
      } else {
        setLoading('initial');
        return false;
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function submitCompany(detectedCMSName: any) {
    try {
      const requestInfo: RequestInfo = {
        url: CREATE_SHOP_API(seller_id),
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          name: detectedCMSName,
          currency: 'EUR',
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'ONBOARDING_ADD_COMPANY';
      const customMsg = 'Company Added Successfully.';

      if (companyDetails?.shop_id) {
        return companyDetails;
      }

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        false,
        true
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const companyResponse = {
          name: detectedCMSName,
          currency: 'EUR',
          shop_id: response?.data?.id?.toString(),
          shop_slug: response?.data?.slug,
          location: response?.data?.location ? response?.data?.location : '',
          is_active: response?.data?.is_active ? true : false,
          initial: response?.data?.initial ? response?.data?.initial : '',
          webshop_url: '',
          transaction_per_month: '',
        };

        if (companyResponse?.shop_slug) {
          dispatch(setUserCompanyDetails(companyResponse));
          return companyResponse;
        } else {
          setLoading('initial');
        }
      } else {
        setLoading('initial');
        return false;
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  const submitWebshopData = async (
    companyResponse: any,
    detectedCMSName: any
  ) => {
    const sendingURL = ensureHttps(url);
    const sendingData: any = {
      webshop_url: sendingURL,
    };

    if (detectedCMSName && typeof detectedCMSName === 'string') {
      sendingData.shop_name = detectedCMSName;
    }

    const requestInfo: RequestInfo = {
      url: SHOP_API + `/${companyResponse?.shop_slug}/setting/shop-general`,
      method: 'PUT',
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `${accessToken.type} ${
          isWhiteLabel
            ? accessToken.currentWhiteLabelSellerToken
            : accessToken.sellerToken
        }`,
      },
    };

    const actionType = 'ONBOARDING_WEB_SHOP_URL';
    const customMsg = t('Update Successfully.');

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false,
      false,
      true
    );

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      dispatch(
        setUserCompanyDetails({
          webshop_url: url_prefix + url,
        })
      );
      return true;
    } else {
      return false;
    }
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!phoneNumber.number || !url) {
      return;
    }

    setLoading('pending');

    try {
      const profileUpdated = await submitProfile();
      if (!profileUpdated) throw new Error('Profile update failed');

      const detectedCMSName = await detectCMS();
      if (!detectedCMSName || typeof detectedCMSName !== 'string') {
        throw new Error('CMS detection failed');
      }

      const companyCreated = await submitCompany(detectedCMSName);
      if (!companyCreated) throw new Error('Company creation failed');

      if (companyCreated?.shop_slug && companyCreated?.shop_slug !== '') {
        const webshopDataSubmitted = await submitWebshopData(
          companyCreated,
          detectedCMSName
        );
        if (!webshopDataSubmitted) {
          throw new Error('Webshop data submission failed');
        }

        const sendOnboardingStatus = await update_all_shop_stats_api_call(
          2,
          false
        );
        if (!sendOnboardingStatus) {
          throw new Error('Onboarding status submission failed');
        }

        setLoading('initial');
        dispatch(
          setAiOnboardingStepCompleted({ stepNumber: 1, isCompleted: true })
        );

        //* Commented below for the MCP Launch
        // dispatch(setAiOnboardingNextStep(2));
        //* Added below for the MCP Launch
        dispatch(setAiOnboardingInprogress(false));
        navigate(HOME_ROUTE);
      }
    } catch (error) {
      // const message = t('SomethingWentWrong-msg');
      // const messageType = 'error';
      // dispatch(setMessage({ message, messageType }));
      console.error('Error during submission:', error);
      setLoading('initial'); // Reset loading state on error
    }
  };

  const isDisabled = !phoneNumber.number || !url;

  return (
    <form
      onSubmit={(e) => {
        submitForm(e);
      }}
      className='quickOnboardingCompany'
    >
      <div className='quickOnboardingCompany__title'>
        <span>{t('CompanyDetails')}</span>
      </div>
      <div className='quickOnboardingCompany__input'>
        <AIPhoneInput
          id='quickOnboardingCompany__user-phone-input'
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          label={t('PhoneNumber')}
          placeholder={t('EnterPhoneNumber')}
          title={t('Phone-validation')}
          required={true}
          pattern='^[0-9]{4,}$'
        />
      </div>
      <div className='quickOnboardingCompany__input'>
        <AIURLInput
          prefix={url_prefix}
          changeListeners={[(e) => setURL(e.target.value)]}
          id='quickOnboardingCompany__shop-url-input'
          value={url}
          label={t('WebshopURL')}
          placeholder={t('www.example.com')}
          title={t('URL-validation')}
          required={true}
        />
      </div>

      <div
        style={{ marginTop: '2rem', marginBottom: '1rem' }}
        className='quickOnboardingCompany__button'
      >
        <MainSquareButton
          type={'submit'}
          value={t('Next')}
          normal={true}
          loading={loading === 'pending' ? true : false}
          disabled={isDisabled}
        />
      </div>
    </form>
  );
};

export default QuickOnboardingCompany;
