import { motion } from 'framer-motion';
import { useRef } from 'react';

type Particle = {
  id: number;
  x: number;
  y: number;
  size: number;
  delay: number;
  moveX: number;
  moveY: number;
  rotate: number;
  duration: number;
};

const ParticleAnimation = () => {
  // Generate persistent random configurations
  const particles = useRef<Particle[]>(
    Array.from({ length: 50 }, (_, i) => {
      const angle = Math.random() * Math.PI * 2;
      const radius = Math.random() * 50;
      return {
        id: i,
        x: Math.random() * 200 - 100,
        y: Math.random() * 200 - 100,
        size: Math.random() * 4 + 2,
        delay: Math.random() * 2,
        moveX: Math.cos(angle) * radius,
        moveY: Math.sin(angle) * radius,
        rotate: Math.random() * 360,
        duration: 2 + Math.random() * 2,
      };
    })
  ).current;

  return (
    <svg viewBox='-100 -100 200 200'>
      {particles.map((particle) => (
        <motion.circle
          key={particle.id}
          cx={particle.x}
          cy={particle.y}
          r={particle.size}
          fill='#3B82F6'
          initial={{
            opacity: 0,
            scale: 0,
            translateX: 0,
            translateY: 0,
          }}
          animate={{
            opacity: [0, 1, 0],
            scale: [0, 1, 0.5],
            translateX: [0, particle.moveX, 0],
            translateY: [0, particle.moveY, 0],
            rotate: [0, particle.rotate],
          }}
          transition={{
            duration: particle.duration,
            repeat: Infinity,
            delay: particle.delay,
            ease: 'anticipate',
            times: [0, 0.5, 1],
            repeatType: 'mirror',
          }}
        />
      ))}
    </svg>
  );
};

export default ParticleAnimation;
