import React from 'react';

type Props = {
  isChecked: boolean;
  fillColor: string;
};

const RadioButtonSVG = ({ isChecked, fillColor }: Props) => {
  const renderRadioButtonSVG = (isChecked: boolean, fillColor: string) => {
    if (isChecked) {
      return (
        <svg
          viewBox='0 0 20 20'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-340.000000, -4322.000000)'>
              <g transform='translate(100.000000, 4266.000000)'>
                <g transform='translate(238.000000, 54.000000)'>
                  <g>
                    <polygon points='0 0 24 0 24 24 0 24'></polygon>
                    <path
                      d='M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M12,20 C16.42,20 20,16.42 20,12 C20,7.58 16.42,4 12,4 C7.58,4 4,7.58 4,12 C4,16.42 7.58,20 12,20 Z M12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625 9.23857625,7 12,7 C14.7614237,7 17,9.23857625 17,12 C17,14.7614237 14.7614237,17 12,17 Z'
                      fill={fillColor || '#000'}
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    } else {
      return (
        <svg
          viewBox='0 0 20 20'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-306.000000, -4322.000000)'>
              <g transform='translate(100.000000, 4266.000000)'>
                <g transform='translate(204.000000, 54.000000)'>
                  <g>
                    <polygon points='0 0 24 0 24 24 0 24'></polygon>
                    <path
                      d='M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z'
                      fill={fillColor || '#000'}
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    }
  };
  return renderRadioButtonSVG(isChecked, fillColor);
};

export default RadioButtonSVG;
