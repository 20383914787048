import { MessageTypes } from '../../../interfaces/taraInterfaces';
import '../../../styles/pages/tara/chat/taraChatItemUser.scss';

type Props = {
  message: any;
  messageType: MessageTypes;
};

const TaraChatItemUser = ({ message, messageType }: Props) => {
  const renderMessage = () => {
    if (messageType === 'chart') {
      return <div className='taraChatItemUser__chart'>{message}</div>;
    } else {
      return <div className='taraChatItemUser__message'>{message}</div>;
    }
  };

  return (
    <div className='taraChatItemUser'>
      {renderMessage()}
      {/* <div className='taraChatItemUser__icon'> 👤</div> */}
    </div>
  );
};

export default TaraChatItemUser;
