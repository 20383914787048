import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

type Props = {
  title: string;
  chartData: any[];
};

const TaraDummyLineChart = ({ title, chartData }: Props) => {
  const valueFormatter = (value: number | null) => `${value}%`;
  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{title}</h2>
      <div className='pieChartComponent__chart'>
        <div className='pieChartComponent__chart--container'>
          <div className='pieChartComponent__chart--container-chart'>
            <LineChart
              dataset={chartData}
              // xAxis={[{ scaleType: 'band', dataKey: 'week', label: 'Date' }]}
              xAxis={[{ scaleType: 'band', dataKey: 'x_axis' }]}
              yAxis={[
                {
                  // label: 'Conversion Rate (%)',
                  min: 0,
                  max: 100,
                },
              ]}
              series={[{ dataKey: 'y_axis', valueFormatter }]}
              height={315}
              colors={['#8884d8']}
              margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
              grid={{ vertical: true, horizontal: true }}
              sx={(theme) => ({
                [`.${axisClasses.root}`]: {
                  [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                    stroke: '#8b909a',
                    strokeWidth: 3,
                  },
                  [`.${axisClasses.tickLabel}`]: {
                    fill: '#8b909a',
                  },
                },
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  fill: '#8b909a',
                  // transform: 'translate(-10px, -10px)',
                },
                [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                  fill: '#8b909a', // Change color of x-axis label
                },
                [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                  fill: '#8b909a', // Change color of x-axis tick labels
                },
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaraDummyLineChart;
