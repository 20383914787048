import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { AI_CHECKOUT_CUSTOM_EVENTS_API } from '../../constants/central-api';

export default async function delete_custom_events_by_integration_key_api_call(
  integrationKey: string,
  customEventId: string
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${AI_CHECKOUT_CUSTOM_EVENTS_API(integrationKey)}/${customEventId}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'DELETE_CUSTOM_EVENTS_BY_INTEGRATION_KEY';
  const customMsg = 'Custom event deleted successfully';

  const res = await asyncHandler(requestInfo, actionType, customMsg, true);

  if (res?.data === null) {
    return true;
  }
}
