function makeFirstLetterCapital(sentence: string): string {
  if (typeof sentence !== 'string') {
    return sentence;
  }
  if (sentence.length === 0) {
    return sentence;
  }
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export default makeFirstLetterCapital;
