import { CurrentChatUpdate } from '../../../interfaces/taraInterfaces';
import { searchSVG } from '../../../lib/allCustomSVGs';
import generateRandomString from '../../../lib/helper-functions/generateRandomString';
import '../../../styles/pages/tara/taraUI/taraSearchSuggestions.scss';
import TaraBasicButton from './TaraBasicButton';

const searchSuggestions = [
  {
    id: 1,
    title: 'Purchases last 7 days',
    searchQuery: 'Purchases last 7 days',
  },
  {
    id: 2,
    title: 'Conversion rate last 7 days',
    searchQuery: 'Conversion rate last 7 days',
  },
  // {
  //   id: 3,
  //   title: 'What is the best selling product',
  //   searchQuery: 'What is the best selling product',
  // },
  // {
  //   id: 4,
  //   title: 'Where can I improve my checkout page?',
  //   searchQuery: 'Where can I improve my checkout page?',
  // },
];

type Props = {
  updateChat: ({ newChat, role, messageType }: CurrentChatUpdate) => void;
};

const TaraSearchSuggestions = ({ updateChat }: Props) => {
  return (
    <div className='taraSearchSuggestions'>
      {searchSuggestions?.map((suggestion) => {
        return (
          <div
            className='taraSearchSuggestions__suggestion'
            key={suggestion?.id}
          >
            <TaraBasicButton
              onClick={() => {
                updateChat({
                  newChatId: generateRandomString(10),
                  newChat: suggestion?.searchQuery,
                  role: 'user',
                  messageType: 'text',
                });
              }}
              value={suggestion?.title}
              type='button'
              buttonUse='normal'
              icon={searchSVG}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TaraSearchSuggestions;
