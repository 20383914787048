import { useEffect, useState } from 'react';
import '../../../../styles/components/charts/chart.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../../ChartWithConfig';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';
import OverallScreenSizeBasedCRBarChart from './OverallScreenSizeBasedCRBarChart';
import IndividualScreenSizeBasedCRBarChart from './IndividualScreenSizeBasedCRBarChart';
import { defaultGraphTimeFilter } from '../../../../lib/constants/abstracts';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainScreenSizeBasedCRChart = ({
  timeFilter = defaultGraphTimeFilter,
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&time_range=${timeFilter}&event_type=${eventFilter}&group_by=screen_size`
  );

  const [selectedScreenSize, setSelectedScreenSize] = useState<string>('');
  const [response, setResponse] = useState<any>('initial');
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  //Comments and titles
  const [title, setTitle] = useState<string>(
    t('ScreenSizeBasedConversionRate')
  );
  const [comment, setComment] = useState<string>(
    t('TheConversionRateOfCartsToCheckoutsBasedOnScreenSize')
  );

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );
      if (res) {
        if (selectedScreenSize !== '') {
          setResponse({
            type: 'individual',
            data: res,
          });
        } else {
          setResponse({ type: 'overall', data: res });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let groupByURLParam = '';
    if (isNotEmptyString(selectedScreenSize)) {
      groupByURLParam = `&group_by_value=${selectedScreenSize}`;
    } else {
      groupByURLParam = '';
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=conversion_rate&group_by=screen_size&time_range=${timeFilter}${dateRangeURLParam}${groupByURLParam}${eventURLParam}`
    );
  }, [
    selectedScreenSize,
    timeFilter,
    customPeriodFilterDateRange,
    eventFilter,
  ]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (selectedScreenSize !== '') {
      setSelectedScreenSize('');
    }
  };

  useEffect(() => {
    if (selectedScreenSize !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedScreenSize]);

  useEffect(() => {
    if (eventFilter === 'add_to_cart') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ScreenSizeBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'pay_button') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ScreenSizeBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'go_to_checkout') {
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ScreenSizeBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else {
      setTitle(t('ScreenSizeBasedConversionRate'));
    }
  }, [eventFilter]);

  function renderChart() {
    if (selectedScreenSize === '') {
      return (
        <OverallScreenSizeBasedCRBarChart
          apiResponse={response}
          setSelectedScreenSize={setSelectedScreenSize}
        />
      );
    } else {
      return <IndividualScreenSizeBasedCRBarChart apiResponse={response} />;
    }
  }

  return (
    <ChartWithConfig
      title={title}
      comment={comment}
      onBackButtonClick={onBackButtonClick}
      isBackButtonVisible={isBackButtonVisible}
      isConfigHidden={true}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainScreenSizeBasedCRChart;
