import getCurrencySymbol from './getCurrencySymbol';

const tara_pricing_plans_structure = [
  {
    id: 1,
    name: 'Starter',
    monthly_slug: '9e89a01c-0f17-4d3f-84ea-06ca34ade3ee',
    annual_slug: '9e8a52df-266f-4543-ae11-6dfc9a450eb7',
    monthly_price: '€20',
    annual_price: '€200',
    monthly_price_number: 20,
    annual_price_number: 200,
    description: 'Best value for smaller webshops',
    currency: 'EUR',
    features: [
      'Up to 100 tokens p/m',
      'Connect 3 MCP',
      'Connect additional MCP if you connect TARA',
    ],
    is_most_popular: false,
  },
  {
    id: 2,
    name: 'Business',
    monthly_slug: '9e89a01c-1047-4e9b-b8a2-5e2eab064039',
    annual_slug: '9e8a52df-2a2c-4dd9-a847-572d2969dd21',
    monthly_price: '€99',
    annual_price: '€990',
    monthly_price_number: 99,
    annual_price_number: 990,
    description: 'Our most popular package',
    currency: 'EUR',
    features: [
      'Everything from Starter',
      'Additional 1000 tokens p/m',
      'Connect 3 additional MCPs',
      'TARA AI Insights',
      'TARA Tracking',
      'CSS Tracking',
    ],
    is_most_popular: true,
  },
  {
    id: 3,
    name: 'AI Expert',
    monthly_slug: '9e89a01c-117c-4015-aacf-d408d3b74a62',
    annual_slug: '9e8a52df-2b6f-4d43-ae75-ee7bd084a531',
    monthly_price: '€249',
    annual_price: '€1299',
    monthly_price_number: 249,
    annual_price_number: 1299,
    description: 'For your enterprise needs',
    currency: 'EUR',
    features: [
      'Everything from Business',
      'Additional 15000 tokens p/m',
      'TARA Predictive analytics',
      'TARA A/B Testing',
      'Preferred Support',
      'Agent instructions (coming soon)',
      'Build your own (coming soon)',
    ],
    is_most_popular: false,
  },
];

// Conversion function that uses the known static structure directly
const convertAPIPackagesToStructure = (apiResponse: any[]): any[] => {
  return tara_pricing_plans_structure.map((plan) => {
    // Find the matching API entries for monthly and annual plans (case-insensitive match on package_name)
    const monthlyPlan = apiResponse.find(
      (item) =>
        item.package_name.toLowerCase() === plan.name.toLowerCase() &&
        item.time_period_type === 'months'
    );
    const annualPlan = apiResponse.find(
      (item) =>
        item.package_name.toLowerCase() === plan.name.toLowerCase() &&
        item.time_period_type === 'years'
    );

    return {
      ...plan,
      // Update slugs with the API's slug field, preserving the static structure's numeric id
      monthly_price_number: monthlyPlan.package_price.price,
      annual_price_number: annualPlan.package_price.price,
      monthly_slug: monthlyPlan ? monthlyPlan.slug : plan.monthly_slug,
      annual_slug: annualPlan ? annualPlan.slug : plan.annual_slug,
      // Update currency with the API's currency field, preserving the static structure's currency
      currency:
        monthlyPlan.package_price.currency || annualPlan.package_price.currency,

      // Update prices if available from the API, using getCurrencySymbol() for formatting
      monthly_price: monthlyPlan
        ? `${getCurrencySymbol(monthlyPlan.package_price.currency)}${
            monthlyPlan.package_price.price
          }`
        : plan.monthly_price,
      annual_price: annualPlan
        ? `${getCurrencySymbol(annualPlan.package_price.currency)}${
            annualPlan.package_price.price
          }`
        : plan.annual_price,
    };
  });
};

export default convertAPIPackagesToStructure;
