import { motion } from 'framer-motion';
import '../../styles/components/sidebar/sidebarProfileTheme2.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  creditCardSVG,
  logoutSVG,
  moonSVG,
  questionSVG,
  settingsPlusSVG,
  sunSVG,
  userSVG,
} from '../../lib/allCustomSVGs';
import { tokenRevokeAtLogOutUser } from '../../lib/api/logout-user';
import { APP_ENV } from '../../lib/constants/central-api';
import { useNavigate } from 'react-router-dom';
import {
  setAppThemeMode,
  setIsPaymentPopupOpen,
} from '../../store/reducers/uiSlice';
import localStorageHardReset from '../../lib/helper-functions/localStorageHardReset';

type Props = {
  isProfileLinkHidden?: boolean;
  isSettingsLinkHidden?: boolean;
};

const SidebarProfileTheme2 = ({
  isProfileLinkHidden,
  isSettingsLinkHidden,
}: Props) => {
  const { t } = useTranslation();
  const appTheme = useSelector((state: RootState) => state.UI.theme.dark);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    tokenRevokeAtLogOutUser();
    if (APP_ENV === 'LIVE') {
      // @ts-ignore:next-line
      window.Intercom && window.Intercom('shutdown'); //shutdown intercom
    }

    localStorageHardReset();

    navigate(
      window?.location?.pathname === '/register' ? '/register' : '/login'
    );
  };

  return (
    <motion.ul
      className='sidebarProfileTheme2'
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.3 }}
    >
      {!isProfileLinkHidden ? (
        <li
          onClick={() => {
            dispatch(setIsPaymentPopupOpen(true));
          }}
        >
          <CustomSVGs svg={creditCardSVG} />
          <span>{t('Pricing plans')}</span>
        </li>
      ) : null}

      {!isProfileLinkHidden ? (
        <li
          onClick={() => {
            navigate('/settings/profile');
          }}
        >
          <CustomSVGs svg={userSVG} />
          <span>{t('EditProfile')}</span>
        </li>
      ) : null}

      {!isSettingsLinkHidden ? (
        <li
          onClick={() => {
            navigate('/settings');
          }}
        >
          <CustomSVGs svg={settingsPlusSVG} />
          <span>{t('Settings')}</span>
        </li>
      ) : null}

      <li
        onClick={() => {
          dispatch(setAppThemeMode());
        }}
      >
        {appTheme ? <CustomSVGs svg={sunSVG} /> : <CustomSVGs svg={moonSVG} />}
        {!appTheme && <span>{t('SwitchToDarkMode')}</span>}
        {appTheme && <span>{t('SwitchToLightMode')}</span>}
      </li>
      <li
        onClick={() => {
          window.open('https://intercom.help/ad-up/en', '_blank');
        }}
      >
        <CustomSVGs svg={questionSVG} />
        <span>{t('Support')}</span>
      </li>
      <li onClick={logoutHandler}>
        <CustomSVGs svg={logoutSVG} />
        <span>{t('Logout')}</span>
      </li>
    </motion.ul>
  );
};

export default SidebarProfileTheme2;
