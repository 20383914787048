import store from '../../store/store';
import { AI_CHECKOUT_TRACKING_LINK } from './central-api';

export const regularScriptCode = (apiKey: string) => {
  return `<!-- AI Checkout Tracking script -->
  <script defer>
    function loadAITrackingScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = function () {
       initializeAnalytics();
     };
     document.head.appendChild(script);
   }
   document.addEventListener("DOMContentLoaded",function(){var r=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};if(r&&typeof r==="object"){Object.keys(r).forEach(function(k){var parts=k.split("&t=");var s=parts[0];var t=parts[1];var v=t==="A";if(v){try{document.querySelectorAll(s).forEach(function(x){if(x){var o=r[k];if(o&&typeof o==="object"){Object.keys(o).forEach(function(p){x.style[p]=o[p]})}}})}catch(x){console.error(x)}}})}loadAITrackingScript()});
 </script>`;
};

export const shopifyPixelCode = (apiKey: string) => {
  return `(function () {
  function loadAITrackingScript() {
    return new Promise((resolve, reject) => {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  function trackShopifyEvent(event) {
    setTimeout(() => {
      window.postMessage(
        {
          type: "shopify_web_pixel_event",
          payload: event,
        },
        "*"
      );
    }, 5000);
  }
  function initializeTracking() {
    var r=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};if(r&&typeof r==="object"){Object.keys(r).forEach(function(k){var parts=k.split("&t=");var s=parts[0];var t=parts[1];var v="A"===t;if(v){try{document.querySelectorAll(s).forEach(function(x){if(x){var o=r[k];if(o&&typeof o==="object"){Object.keys(o).forEach(function(p){x.style[p]=o[p]})}}})}catch(x){console.error(x)}}})}
    loadAITrackingScript()
      .then(() => {
        if (window.initializeAnalytics) {
          window.initializeAnalytics();
        }
      })
      .catch((error) => {
        console.error("Error loading script:", error);
      })
      .finally(() => {
        var eventsToTrack = [
          "cart_viewed",
          "checkout_address_info_submitted",
          "checkout_completed",
          "checkout_contact_info_submitted",
          "checkout_shipping_info_submitted",
          "checkout_started",
          "collection_viewed",
          "payment_info_submitted",
          "product_added_to_cart",
          "product_removed_from_cart",
          "product_viewed",
          "search_submitted",
        ];
        eventsToTrack.forEach((eventName) => {
          analytics.subscribe(eventName, (event) => {
            trackShopifyEvent(event);
          });
        });
      });
  }
  analytics.subscribe("page_viewed", () => {
    initializeTracking();
  });
})();
analytics.subscribe("${shopifyPixelName(apiKey)}", (e) => {});`;
};

export const shopifyPixelName = (apiKey: string) => {
  const shopId = store.getState().user?.company?.shop_id;
  const lastFourCharsOfIntegrationKey = apiKey.slice(-4);

  return `AdUp_AI_${shopId}_${lastFourCharsOfIntegrationKey}`;
};
