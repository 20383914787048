import '../../styles/components/messageBanner/messageBanner.scss';
import { useTranslation } from 'react-i18next';
import { detectWebAuthnType } from './detect-webAuthn-type';
import touchIdLogo from '../../assets/svg/webAuthn/Touch ID.gif';
import faceIdLogo from '../../assets/svg/webAuthn/Face ID.svg';
import androidFingerPrintLogo from '../../assets/svg/webAuthn/Android Fingerprint.svg';
import androidFaceUnlockLogo from '../../assets/svg/webAuthn/Android Face.svg';
import biometricsLogo from '../../assets/svg/webAuthn/Biometrics.png';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../store/store';
import { AnimatePresence, motion } from 'framer-motion';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { v4 as uuidv4 } from 'uuid';
import {
  setIsLoggedInViaWebAuthn,
  setIsWebAuthnRegistrationSkipped,
} from '../../store/reducers/authSlice';
import { useState } from 'react';
import {
  APP_ENV,
  USER_SETTINGS_API,
  WEBAUTHN_REGISTER_API,
} from '../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../lib/api/log';
import { setMessage } from '../../store/reducers/appSlice';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { applicationJSONBodyBuilder } from '../../lib/utils/body-builder';
import { asyncHandler } from '../../lib/api/async-handler';

declare const window: any;

const WebAuthnRegisterPrompt = () => {
  const { t } = useTranslation();

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const token = useSelector(
    (state: RootState) =>
      state.auth?.authorization?.accessToken?.sellerToken ?? ''
  );

  const accessToken = useSelector(
    (state: RootState) => state.auth?.authorization?.accessToken
  );

  const isWhiteLabel = useSelector(
    (state: RootState) => state.UI?.isWhiteLabel
  );

  const hideWebAuthnPrompt =
    useSelector(
      (state: RootState) =>
        state.auth?.authorization?.webAuthn?.hideWebAuthnPrompt
    ) ?? 0;

  const webAuthnType = detectWebAuthnType();

  const [verifyingWebAuthn, setVerifyingWebAuthn] = useState<boolean>(false);

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  async function updateHideWebAuthnPromptState() {
    const sendingData = {
      settings: {
        hideWebAuthnPrompt: 1,
      },
    };

    try {
      const requestInfo: RequestInfo = {
        url: USER_SETTINGS_API,
        method: 'PUT',
        body: applicationJSONBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'UPDATE_USER_SETTINGS';
      const customMsg = t('Success');

      await asyncHandler(requestInfo, actionType, customMsg, false, false);
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  const skipRegistration = () => {
    store.dispatch(setIsWebAuthnRegistrationSkipped(true));
    if (hideWebAuthnPrompt === 0) {
      updateHideWebAuthnPromptState();
    }
  };

  async function triggerWebAuthn() {
    setVerifyingWebAuthn(true);

    const registerUUID = uuidv4();

    const attestOptionsConfig = {
      path: `${WEBAUTHN_REGISTER_API}/options`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        phone_login: false,
        key: registerUUID,
      },
    };

    const attestConfig = {
      path: WEBAUTHN_REGISTER_API,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        key: registerUUID,
      },
    };

    try {
      console.log('WebAuthn : Registering...');

      const webpass = window.Webpass.create({
        rpId: APP_ENV === 'LIVE' ? 'adup.io' : 'kodeia.com',
      });

      const { success }: any = await webpass.attest(
        attestOptionsConfig,
        attestConfig
      );

      if (success) {
        console.log('WebAuthn : Registering successful');

        store.dispatch(setIsLoggedInViaWebAuthn(true));

        if (hideWebAuthnPrompt === 0) {
          updateHideWebAuthnPromptState();
        }

        store.dispatch(
          setMessage({
            message: t('WebAuthnSuccess', {
              webAuthnType,
            }),
            messageType: 'success',
          })
        );
      } else {
        console.log('WebAuthn : Registering failed.');

        store.dispatch(
          setMessage({
            message: t('WebAuthnError', {
              webAuthnType,
            }),
            messageType: 'error',
          })
        );
        skipRegistration();
      }
    } catch (error) {
      console.log('WebAuthn : Registering failed.', error);
      submitLogToBugsnag(
        'error',
        `Error in webauthn register endpoint: ${error}`
      );
      store.dispatch(
        setMessage({
          message: t('WebAuthnError', {
            webAuthnType,
          }),
          messageType: 'error',
        })
      );
      skipRegistration();
    }

    setVerifyingWebAuthn(false);
  }

  return (
    <>
      <div className='messageBanner'>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='messageBanner__message'
          >
            <>
              <img
                loading='lazy'
                src={
                  webAuthnType === 'Face Id'
                    ? faceIdLogo
                    : webAuthnType === 'Touch Id'
                    ? touchIdLogo
                    : webAuthnType === 'Fingerprint'
                    ? androidFingerPrintLogo
                    : webAuthnType === 'Face Unlock'
                    ? androidFaceUnlockLogo
                    : webAuthnType === 'Biometrics'
                    ? biometricsLogo
                    : ''
                }
                width={
                  webAuthnType === 'Touch Id'
                    ? 400
                    : webAuthnType === 'Biometrics'
                    ? 200
                    : 100
                }
                style={{ margin: 20 }}
              ></img>
              <div className='messageBanner__message--title'>
                <h1>{`${webAuthnType} ${t('Authentication')}`}</h1>
              </div>
              <div className='messageBanner__message--body'>
                <p>
                  {t('WebAuthnText-1', {
                    webAuthnType,
                  })}
                </p>
              </div>
              <div className='messageBanner__message--body'>
                <p>
                  {
                    <>
                      {' '}
                      {t('WebAuthnText-2')} <br />
                      {isNotEmptyString(merchantTheme?.privacy_url) && (
                        <div
                          className='learn-more-link'
                          onClick={() => {
                            window.open(
                              `${merchantTheme?.privacy_url}`,
                              '_blank'
                            );
                          }}
                        >
                          {t('LearnMore')}
                        </div>
                      )}
                    </>
                  }
                </p>
              </div>
              <div className='messageBanner__message--button'>
                <MainSquareButton
                  value={
                    !verifyingWebAuthn
                      ? `${t('Register')} ${webAuthnType}`
                      : `${t('Registering')}...`
                  }
                  disabled={verifyingWebAuthn}
                  onClick={triggerWebAuthn}
                  buttonType={'success'}
                />
              </div>
              <div className='messageBanner__message--body'>
                <p>
                  {
                    <>
                      <div
                        className='cancel-link'
                        onClick={
                          !verifyingWebAuthn
                            ? () => {
                                skipRegistration();
                              }
                            : () => {}
                        }
                      >
                        {t('RegisterLater')}
                      </div>
                    </>
                  }
                </p>
              </div>
            </>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

export default WebAuthnRegisterPrompt;
