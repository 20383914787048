import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { GET_ACCESS_TOKEN_BY_INTEGRATION_KEY_API } from '../../constants/central-api';
import {
  setAiTrackerAccessToken,
  setAiTrackerAccessTokenExpireDate,
} from '../../../store/reducers/userSlice';
import moment from 'moment';

export default async function get_access_token_by_integration_key_api_call(
  integrationKey: string
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${GET_ACCESS_TOKEN_BY_INTEGRATION_KEY_API(integrationKey)}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_ACCESS_TOKEN_BY_INTEGRATION_KEY';
  const customMsg = 'Access token fetched successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false,
    true
  );

  const response = res?.data;
  if (!response?.success) {
    return false;
  }
  if (res.actionType === actionType && response?.success === true) {
    if (response?.data === null) {
      return false;
    } else {
      if (
        response?.data?.access_token &&
        typeof response?.data?.access_token === 'string'
      ) {
        store.dispatch(
          setAiTrackerAccessTokenExpireDate(moment().add(14, 'days'))
        );
        store.dispatch(
          setAiTrackerAccessToken(response?.data?.access_token || '')
        );
        return true;
      } else {
        return false;
      }
    }
  }
}
