import { MessageTypes } from '../../../interfaces/taraInterfaces';
import TaraDummyBarChart from '../../../lib/constants/taraDummyData/TaraDummyBarChart';
import TaraDummyLineChart from '../../../lib/constants/taraDummyData/TaraDummyLineChart';
import MarkdownMessage from './MarkdownMessage';
import '../../../styles/pages/tara/chat/taraChatItemAI.scss';

type Props = {
  message: any;
  messageType: MessageTypes;
  isTyping?: boolean;
};

const TaraChatItemAI = ({ message, messageType, isTyping = false }: Props) => {
  const renderChart = (message: any) => {
    if (message?.chartType === 'line') {
      return (
        <TaraDummyLineChart
          title={message?.chartTitle}
          chartData={message?.chartData}
        />
      );
    } else if (message.chartType === 'bar') {
      return (
        <TaraDummyBarChart
          title={message?.chartTitle}
          chartData={message?.chartData}
        />
      );
    }
    return null;
  };

  const renderMessageContent = () => {
    if (isTyping) {
      return (
        <div className='taraChatItemAI__message--loading'>
          <div className='taraChatItemAI__message--loading-spinner'>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    }

    if (typeof message === 'string') {
      return <MarkdownMessage content={message} />;
    }

    return message;
  };

  return (
    <div className='taraChatItemAI'>
      {messageType === 'chart' ? (
        <div className='taraChatItemAI__chart'>{renderChart(message)}</div>
      ) : (
        <div
          className='taraChatItemAI__message'
          data-is-typing={isTyping ? 'true' : 'false'}
        >
          {renderMessageContent()}
        </div>
      )}
    </div>
  );
};

export default TaraChatItemAI;
