import store from '../../../store/store';
import { asyncHandler } from '../async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { MCP_CREDENTIALS_API } from '../../constants/central-api';
import { submitLogToBugsnag } from '../log';

export default async function update_mcp_api_call(
  connectionId: number,
  credentials: any
) {
  try {
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelSellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    const requestInfo: RequestInfo = {
      url: `${MCP_CREDENTIALS_API}/${connectionId}`,
      method: 'PATCH',
      body: JSON.stringify({
        credentials: credentials,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'UPDATE_MCP_CONNECTION';
    const customMsg = 'MCP Connection Successful.';

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response?.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (exception) {
    console.log('Error during authentication request');
    submitLogToBugsnag('error', `${exception}`);
    return false;
  }
}
