function convertSnakeCaseToTitleCase(snakeCaseString: string) {
  if (typeof snakeCaseString !== 'string') {
    return '';
  }

  const words = snakeCaseString.toLowerCase().split('_');
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return titleCaseWords.join(' ');
}

export default convertSnakeCaseToTitleCase;
