import React from 'react';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { plusSignSVG } from '../../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCurrentChatID,
  setIsBotTyping,
} from '../../../store/reducers/chatSlice';
import '../../../styles/pages/tara/history/taraCreateNewChat.scss';

interface Props {
  isSidebarMinimized: boolean;
}

const TaraCreateNewChat = ({ isSidebarMinimized }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createNewChat = () => {
    navigate('/tara');
    // Optionally save current chat in history if needed (if currentChatID is set and you want to keep its data)
    dispatch(setCurrentChatID(null));
    dispatch(setIsBotTyping(false));
  };

  return (
    <div
      className='taraCreateNewChat'
      data-is-sidebar-minimized-new-chat={isSidebarMinimized.toString()}
    >
      <div onClick={createNewChat} className='taraCreateNewChat__content'>
        <CustomSVGs svg={plusSignSVG} />
        <div>{t('NewChat')}</div>
      </div>
    </div>
  );
};

export default TaraCreateNewChat;
