import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import '../../styles/components/subPageContentHolder/dashboardFilter.scss';
import Popup from '../../ui/popup/Popup';
import CustomTimeRangePicker from './CustomTimeRangePicker';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { calenderSVG } from '../../lib/allCustomSVGs';

interface Props {
  dashboardPeriodFilterHandler: (value: string) => void;
  timeFilter: string;
  setCustomPeriodFilterDateRange: (value: {
    start_date: string | undefined;
    end_date: string | undefined;
  }) => void;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
}

const DashboardFilterTime = ({
  dashboardPeriodFilterHandler,
  timeFilter,
  setCustomPeriodFilterDateRange,
  customPeriodFilterDateRange,
}: Props) => {
  const { t } = useTranslation();
  const [isPeriodFilterOpen, setIsPeriodFilterOpen] = useState<boolean>(false);

  // Reference to the dashboardFilter__time container
  const filterRef = useRef<HTMLDivElement | null>(null);

  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
    { OptionValue: 'custom', OptionName: t('Custom') },
  ];

  // Effect to handle click outside and close the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setIsPeriodFilterOpen(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //custom time popup
  const [isCustomTimePopupOpen, setIsCustomTimePopupOpen] = useState(false);
  const toggleCustomTimePopupModal = () => {
    setIsCustomTimePopupOpen(!isCustomTimePopupOpen);
  };

  return (
    <>
      <div
        ref={filterRef}
        onClick={() => setIsPeriodFilterOpen(!isPeriodFilterOpen)}
        className='dashboardFilter__time'
      >
        <div className='dashboardFilter__time--title'>
          <span>{t('SelectPeriod')}:</span>
          <span>
            {
              timeFilterOptionsArr.find(
                (item) => item.OptionValue === timeFilter
              )?.OptionName
            }
          </span>
        </div>

        <div className='dashboardFilter__time-mobile'>
          <CustomSVGs svg={calenderSVG} />
        </div>

        <AnimatePresence>
          {isPeriodFilterOpen && (
            <motion.ul
              className='dashboardFilter__time--filters'
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              {timeFilterOptionsArr.map((item, index) => {
                return (
                  <li
                    data-is-selected-time={
                      item.OptionValue === timeFilter ? 'true' : 'false'
                    }
                    key={index}
                    onClick={() => {
                      if (dashboardPeriodFilterHandler) {
                        // If the selected option is 'custom', open the custom time popup
                        if (item.OptionValue === 'custom') {
                          setIsCustomTimePopupOpen(true);
                        } else {
                          // clear custom period filter date range if the selected option is not 'custom'
                          setCustomPeriodFilterDateRange({
                            start_date: undefined,
                            end_date: undefined,
                          });
                          // then set the time filter to the selected option if it's not 'custom'
                          // 'custom' option is handled in the CustomTimeRangePicker component
                          dashboardPeriodFilterHandler(item.OptionValue);
                        }
                      }
                      setIsPeriodFilterOpen(false);
                    }}
                  >
                    {item.OptionName}
                  </li>
                );
              })}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>

      {/* ------custom time popup start-------- */}
      <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={toggleCustomTimePopupModal}
        trigger={isCustomTimePopupOpen}
      >
        <CustomTimeRangePicker
          toggleCustomTimePopupModal={toggleCustomTimePopupModal}
          setCustomPeriodFilterDateRange={setCustomPeriodFilterDateRange}
          customPeriodFilterDateRange={customPeriodFilterDateRange}
        />
      </Popup>
      {/* ------custom time popup end-------- */}
    </>
  );
};

export default DashboardFilterTime;
