import { setAiTrackerConfiguredTimestamp } from '../../../store/reducers/userSlice';
import store from '../../../store/store';
import get_all_shop_stats_api_call from '../dashboard/get_all_shop_stats_api_call';

//get basic dashboard statistics
export default async function get_statistics_api_call() {
  try {
    const res = await get_all_shop_stats_api_call();
    if (res) {
      store.dispatch(
        setAiTrackerConfiguredTimestamp(
          res?.additional_stats?.tracker_configured_timestamp
            ? res?.additional_stats?.tracker_configured_timestamp
            : ''
        )
      );
      return res;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching tracking data:', error);
    return null;
  }
}
