import { APP_ENV } from './central-api';

let numberOfDaysToAdd = 14;

if (APP_ENV === 'LIVE') {
  numberOfDaysToAdd = 14;
} else if (APP_ENV === 'STAGING') {
  numberOfDaysToAdd = 0;
} else {
  numberOfDaysToAdd = 0;
}

export const DAYS_TO_ADD = numberOfDaysToAdd;

export const defaultGraphTimeFilter = 'last_7_days';
