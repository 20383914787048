import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { MCP_SERVERS_API } from '../../constants/central-api';

export default async function get_all_available_mcp_api_call() {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${MCP_SERVERS_API}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_AVAILABLE_MCP_SERVER_LIST';
  const customMsg = 'Available MCP Server Data Loaded Successfully.';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return [];
    } else {
      return response?.data;
    }
  }
}
