import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { AI_CHECKOUT_CUSTOM_EVENTS_API } from '../../constants/central-api';

export default async function store_custom_events_by_integration_key_api_call(
  integrationKey: string,
  data: any[],
  shouldShowMsg = true
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${AI_CHECKOUT_CUSTOM_EVENTS_API(integrationKey)}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
    body: JSON.stringify(data),
  };

  const actionType = 'STORE_CUSTOM_EVENTS_BY_INTEGRATION_KEY';
  const customMsg = 'Custom event saved successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    shouldShowMsg
  );

  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return null;
    } else {
      return response;
    }
  }
}
