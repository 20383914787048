import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/customEvent.scss';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import '../../../../styles/generic/normalForm.scss';
import { useEffect, useState } from 'react';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { plusSignSVG } from '../../../../lib/allCustomSVGs';
import RadioButtonSVG from '../../../../assets/svg/RadioButtonSVG';
import CustomEventTable from './CustomEventTable';
import store_custom_events_by_integration_key_api_call from '../../../../lib/api/customEvents/store_custom_events_by_integration_key_api_call';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';
import { availableTrackableEvents } from '../../constants/availableTrackableEvents';

type Event = {
  id: string;
  name: string;
  type: string;
  value: string;
  matchTypeOrParam: string;
  active: boolean;
};

interface Props {
  apiKey: string;
}

const CustomEvent = ({ apiKey }: Props) => {
  const { t } = useTranslation();

  const eventTypeArr = [
    { OptionValue: 'url', OptionName: t('URL') },
    { OptionValue: 'parameter', OptionName: t('EventParameter') },
    { OptionValue: 'js', OptionName: t('CallableJSEvent') },
  ];
  const matchTypeArr = [
    { OptionValue: 'contains', OptionName: t('Contains') },
    { OptionValue: 'exact', OptionName: t('ExactMatch') },
  ];

  const [events, setEvents] = useState<Event[]>([]);

  const [eventName, setEventName] = useState('');
  const [eventType, setEventType] = useState(eventTypeArr[0].OptionValue);
  const [url, setURL] = useState('');
  const [matchType, setMatchType] = useState(matchTypeArr[0].OptionValue);
  const [parameterName, setParameterName] = useState('');
  const [parameterValue, setParameterValue] = useState('');

  const [eventsFetchTriggerCount, setEventsFetchTriggerCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isEventNameInputInvalid, setIsEventNameInputInvalid] =
    useState<boolean>(false);

  const validateEventNameInput = (value: string) => {
    setEventName(value);

    const snake_case_pattern = /^[a-z]+(_[a-z]+)*$/;
    if (isNotEmptyString(value)) {
      if (snake_case_pattern.test(value)) {
        setIsEventNameInputInvalid(false);
      } else {
        setIsEventNameInputInvalid(true);
      }
    }
  };

  const filterExistingEventNames = (
    value: string,
    isUpdateMode?: boolean,
    currentEventId?: string
  ) => {
    if (isUpdateMode && currentEventId) {
      // In update mode, exclude the current event from the check
      if (
        events.some(
          (event) => event.name === value && event.id !== currentEventId
        )
      ) {
        return true;
      }
    } else {
      // In create mode, check if any event has the same name
      if (events.some((event) => event.name === value)) {
        return true;
      }
    }
    return false;
  };

  const filterAdUpEventNames = (value: string) => {
    const trackableEventValues = Object.values(availableTrackableEvents);
    if (trackableEventValues.includes(value)) {
      return true;
    }
    return false;
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    const eventNameAlreadyExists = filterExistingEventNames(eventName);

    if (eventNameAlreadyExists) {
      store.dispatch(
        setMessage({
          message: `${t('DuplicateCustomEventName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    const eventNameIsAdUpEvent = filterAdUpEventNames(eventName);

    if (eventNameIsAdUpEvent) {
      store.dispatch(
        setMessage({
          message: `${t('AdUpEventName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    setIsSubmitting(true);

    const res = await store_custom_events_by_integration_key_api_call(apiKey, [
      {
        name: eventName,
        type: eventType,
        configurations:
          eventType === 'url'
            ? {
                url: url,
                match_type: matchType,
              }
            : eventType === 'parameter'
            ? {
                parameter_name: parameterName,
                parameter_value: parameterValue,
              }
            : undefined,
        is_active: true,
      },
    ]);

    if (res && res?.success) {
      setEventsFetchTriggerCount(eventsFetchTriggerCount + 1);

      // Reset the form fields after successful submission
      setEventName('');
      setEventType(eventTypeArr[0].OptionValue);
      setURL('');
      setMatchType(matchTypeArr[0].OptionValue);
      setParameterName('');
      setParameterValue('');
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (
      !isNotEmptyString(eventName) ||
      (eventType === 'url' && !isNotEmptyString(url)) ||
      (eventType === 'parameter' &&
        (!isNotEmptyString(parameterName) || !isNotEmptyString(parameterValue)))
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [eventName, eventType, url, parameterName, parameterValue]);

  const renderRadioButtons = () => {
    return (
      <div className='customEvent__radioInputs'>
        <div className='customEvent__radioInputs--label'>{t('MatchType')}</div>
        <div className='customEvent__radioInputs--radioItems'>
          <div
            className='customEvent__radioInputs--radioItems-radio'
            onClick={() => setMatchType('contains')}
          >
            <div className='customEvent__radioInputs--radioItems-radio-input'>
              <RadioButtonSVG
                isChecked={matchType === 'contains'}
                fillColor={'var(--main-text-color)'}
              />
            </div>
            <label htmlFor='CustomEvent__radio-1'>{t('Contains')}</label>
          </div>
          <div
            className='customEvent__radioInputs--radioItems-radio'
            onClick={() => setMatchType('exact')}
          >
            <div className='customEvent__radioInputs--radioItems-radio-input'>
              <RadioButtonSVG
                isChecked={matchType === 'exact'}
                fillColor={'var(--main-text-color)'}
              />
            </div>
            <label htmlFor='CustomEvent__radio-2'>{t('ExactMatch')}</label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='customEvent'>
        <form
          id='customEvent-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <h1 className='customEvent-title'>{t('AddNewEvent')}</h1>
          <div className='normalForm__top'>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <AINormalInput
                  changeListeners={[
                    (event) =>
                      validateEventNameInput(
                        (event.target as HTMLInputElement).value
                      ),
                  ]}
                  id='CustomEvent__event-name-input'
                  value={eventName}
                  placeholder={t('EventName')}
                  required={true}
                  innerPlaceholder={t('EnterEventName')}
                  pattern='^[a-z]+(_[a-z]+)*$'
                  title={t('CustomEventName-validation')}
                />
              </div>

              <div className='normalForm__container1--right'>
                <AISelectInput
                  defaultValue={eventType}
                  setSelect={setEventType}
                  selectOptionArr={eventTypeArr}
                  placeholder={t('EventType')}
                  id={'CustomEvent__event-type-select'}
                />
              </div>
            </div>

            {/* event type URL inputs */}
            {eventType === 'url' ? (
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <AINormalInput
                    changeListeners={[(e) => setURL(e.target.value)]}
                    id='CustomEvent__event-url-input'
                    value={url}
                    placeholder={t('URL')}
                    required={true}
                    innerPlaceholder={t('EnterURL')}
                  />
                </div>

                <div className='normalForm__container1--right'>
                  {renderRadioButtons()}
                </div>
              </div>
            ) : null}

            {/* event type parameter inputs */}
            {eventType === 'parameter' ? (
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <AINormalInput
                    changeListeners={[(e) => setParameterName(e.target.value)]}
                    id='CustomEvent__event-parameter-name-input'
                    value={parameterName}
                    placeholder={t('ParameterName')}
                    required={true}
                    innerPlaceholder={t('EnterParameterName')}
                  />
                </div>

                <div className='normalForm__container1--right'>
                  <AINormalInput
                    changeListeners={[(e) => setParameterValue(e.target.value)]}
                    id='CustomEvent__event-parameter-value-input'
                    value={parameterValue}
                    placeholder={t('ParameterValue')}
                    required={true}
                    innerPlaceholder={t('EnterParameterValue')}
                  />
                </div>
              </div>
            ) : null}

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={isSubmitting ? `${t('Updating')}...` : t('AddEvent')}
                icon={<CustomSVGs svg={plusSignSVG} />}
                disabled={isDisabled || isSubmitting || isEventNameInputInvalid}
                type='submit'
                form='customEvent-form'
              />
            </div>
          </div>
        </form>
      </div>

      <div className='customEvent__table'>
        <CustomEventTable
          apiKey={apiKey}
          eventsFetchTriggerCount={eventsFetchTriggerCount}
          eventTypeArr={eventTypeArr}
          matchTypeArr={matchTypeArr}
          events={events}
          setEvents={setEvents}
          filterExistingEventNames={filterExistingEventNames}
          filterAdUpEventNames={filterAdUpEventNames}
        />
      </div>
    </>
  );
};

export default CustomEvent;
