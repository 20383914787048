import { useState } from 'react';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../../../lib/allCustomSVGs';
import { AnimatePresence, motion } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';

interface Item {
  id: string;
  property_type: string;
  current_value: string;
  value: string;
  element_id: string;
  title: string;
  description: string;
  type: string;
  is_paused: boolean;
  loadingStatus: 'pending' | 'initial';
  scenario?: any;
  ctr_difference?: number;
}

type Props = {
  data: Item;
  type: 'active' | 'inactive' | 'rejected';
  isDisabled: boolean;
  setRecommendationsHandler: (
    id: string,
    action: 'select' | 'unselect' | 'pause' | 'resume' | 'reject' | 'unarchive',
    type: 'active' | 'inactive' | 'rejected'
  ) => Promise<void>;
};

const RecommendationItem = ({
  data,
  type,
  setRecommendationsHandler,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const [isItemExpanded, setIsItemExpanded] = useState<boolean>(false);
  function toCamelCase(cssProperty: string): string {
    return cssProperty.replace(/-([a-z])/g, (match, letter) =>
      letter.toUpperCase()
    );
  }

  const renderPreview = () => {
    const camelCasedProperty = toCamelCase(data.property_type);
    if (data?.property_type === 'color') {
      return (
        <div className='recommendationItem__bottom--preview-color'>
          <span style={{ backgroundColor: data?.value }}></span>
          <span style={{ color: data?.value }}>{t('Example')}</span>
        </div>
      );
    } else if (
      !data?.property_type?.includes('width') &&
      !data?.property_type?.includes('height') &&
      !data?.property_type?.includes('margin') &&
      !data?.property_type?.includes('position')
    ) {
      return (
        <div
          style={{ [camelCasedProperty]: data?.value }}
          className='recommendationItem__bottom--preview-box'
        >
          {t('Example')}
        </div>
      );
    } else {
      return (
        <div className='recommendationItem__bottom--preview-other'>
          {/* {`${data?.property_type}: ${data?.value}`} */}
          {`${data?.value}`}
        </div>
      );
    }
  };

  const renderCurrentPreview = () => {
    const camelCasedProperty = toCamelCase(data.property_type);
    if (data?.property_type === 'color') {
      return (
        <div className='recommendationItem__bottom--preview-color'>
          <span style={{ backgroundColor: data?.current_value }}></span>
          <span style={{ color: data?.current_value }}>{t('Example')}</span>
        </div>
      );
    } else if (
      !data?.property_type?.includes('width') &&
      !data?.property_type?.includes('height') &&
      !data?.property_type?.includes('margin') &&
      !data?.property_type?.includes('position')
    ) {
      return (
        <div
          style={{ [camelCasedProperty]: data?.current_value }}
          className='recommendationItem__bottom--preview-box'
        >
          {t('Example')}
        </div>
      );
    } else {
      return (
        <div className='recommendationItem__bottom--preview-other'>
          {/* {`${data?.property_type}: ${data?.current_value
}`} */}
          {`${data?.current_value}`}
        </div>
      );
    }
  };

  const renderButton = (
    action: 'resume' | 'pause' | 'select' | 'unselect' | 'reject' | 'unarchive',
    text: string,
    className: string
  ) => {
    if (data?.loadingStatus !== 'pending') {
      return (
        <button
          onClick={() => {
            if (isDisabled) return;
            setRecommendationsHandler(data?.id, action, type);
          }}
          className={`recommendationItem__actions-btn ${className}`}
        >
          {text}
        </button>
      );
    }
  };

  const renderAllButtons = () => {
    if (type === 'inactive') {
      return (
        <>
          {renderButton(
            'reject',
            t('Reject'),
            'recommendationItem__actions-reject-btn'
          )}
          {renderButton(
            'select',
            t('Accept'),
            'recommendationItem__actions-accept-btn'
          )}
        </>
      );
    } else if (type === 'active') {
      return (
        <>
          {data?.is_paused
            ? renderButton(
                'resume',
                t('Resume'),
                'recommendationItem__actions-resume-btn'
              )
            : null}
          {!data?.is_paused
            ? renderButton(
                'pause',
                t('Pause'),
                'recommendationItem__actions-pause-btn'
              )
            : null}
          {renderButton(
            'unselect',
            t('Decline'),
            'recommendationItem__actions-decline-btn'
          )}
          {/*   {renderButton(
            'reject',
            t('Reject'),
            'recommendationItem__actions-reject-btn'
          )} */}
        </>
      );
    } else if (type === 'rejected') {
      return renderButton(
        'unarchive',
        t('Unarchive'),
        'recommendationItem__actions-reject-btn'
      );
    }
  };

  const renderScenarios = () => {
    if (!data || !data?.scenario) {
      return null;
    }
    const scenarios: any = data?.scenario;

    if (!scenarios || Object.keys(scenarios)?.length === 0) {
      return null;
    }

    return (
      <div className='recommendationItem__bottom--scenarios'>
        {Object.keys(scenarios)?.map((scenarioKey) => {
          const scenarioValue = scenarios[scenarioKey];

          if (!scenarioValue || Object.keys(scenarioValue)?.length === 0) {
            return null;
          }

          return (
            <div
              key={scenarioKey}
              className='recommendationItem__bottom--scenario'
            >
              <div className='recommendationItem__bottom--scenario-title'>
                <span>{makeFirstLetterCapital(scenarioKey)}</span>
              </div>
              <div className='recommendationItem__bottom--scenario-items'>
                {/* Check if scenarioValue is an array */}
                {Array.isArray(scenarioValue)
                  ? // If it's an array, render each element as a list item
                    scenarioValue?.map((item, index) => (
                      <div
                        key={index}
                        className='recommendationItem__bottom--scenario-items-item'
                      >
                        <div className='recommendationItem__bottom--scenario-items-item-value'>
                          {makeFirstLetterCapital(item)}
                          {scenarioValue?.length > 1 &&
                          index !== scenarioValue?.length - 1
                            ? ','
                            : ''}
                        </div>
                      </div>
                    ))
                  : // Otherwise, treat it as an object and render its properties
                    Object.keys(scenarioValue)?.map((key) => {
                      const renderScenarioValue = scenarioValue[key];
                      return (
                        <div
                          key={key}
                          className='recommendationItem__bottom--scenario-items-item'
                        >
                          <div className='recommendationItem__bottom--scenario-items-item-key'>
                            {makeFirstLetterCapital(key)}:
                          </div>
                          <div className='recommendationItem__bottom--scenario-items-item-value'>
                            {renderScenarioValue}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getImpact = () => {
    if (data?.ctr_difference && data?.ctr_difference > 0) {
      return `+${data?.ctr_difference}%`;
    } else {
      return `${data?.ctr_difference}%`;
    }
  };

  return (
    <div
      data-is-recommendation-item-expanded={isItemExpanded.toString()}
      className='recommendationItem'
    >
      <div className='recommendationItem__top'>
        <div className='recommendationItem__top--title'>
          <h2>{data?.title}</h2>
          {/* <h3>
            {data?.type === 'near_by'
              ? `(${t('NearBy')})`
              : `(${t('NightTime')})`}
          </h3> */}
          {data?.ctr_difference && type === 'active' ? (
            <div
              data-recommendation-impact={
                data?.ctr_difference > 0 ? 'true' : 'false'
              }
              className='recommendationItem__top--title-impact'
            >
              <span>{getImpact()}</span>
              <div>{t('Impact')}</div>
            </div>
          ) : null}
        </div>
        <div
          onClick={() => setIsItemExpanded(!isItemExpanded)}
          className='recommendationItem__top--icon'
        >
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      </div>
      <AnimatePresence>
        {isItemExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            style={{ overflow: 'hidden' }}
          >
            <div className='recommendationItem__center'>
              <div className='recommendationItem__center--content'>
                <div className='recommendationItem__center--content-description'>
                  <p className='recommendationItem__center--content-description-title'>
                    {`${t('Recommended')}: ${data?.value}`}
                  </p>
                  <span className='recommendationItem__center--content-description-sentence'>
                    {data?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className='recommendationItem__bottom'>
              {data?.current_value ? (
                <div className='recommendationItem__bottom--preview recommendationItem__bottom--preview-current'>
                  <span className='recommendationItem__bottom--preview-text'>{`${t(
                    'Current'
                  )}: `}</span>
                  {renderCurrentPreview()}
                </div>
              ) : null}
              <div className='recommendationItem__bottom--preview'>
                <span className='recommendationItem__bottom--preview-text'>{`${t(
                  'Recommended'
                )}: `}</span>
                {renderPreview()}
              </div>
              {/* scenarios */}
              {renderScenarios()}
            </div>
            <div className='recommendationItem__actions'>
              {data?.loadingStatus === 'pending' ? (
                <CircularProgress
                  size={'2.5rem'}
                  style={{
                    color: 'var(--main-text-color)',
                  }}
                />
              ) : null}
              {renderAllButtons()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default RecommendationItem;
