const currencyData: Record<string, { symbol: string; country: string }> = {
  AED: { symbol: 'د.إ', country: 'United Arab Emirates' },
  AFN: { symbol: '؋', country: 'Afghanistan' },
  ALL: { symbol: 'Lek', country: 'Albania' },
  AMD: { symbol: '֏', country: 'Armenia' },
  ARS: { symbol: '$', country: 'Argentina' },
  AUD: { symbol: '$', country: 'Australia' },
  AWG: { symbol: 'ƒ', country: 'Aruba' },
  AZN: { symbol: '₼', country: 'Azerbaijan' },
  BAM: { symbol: 'KM', country: 'Bosnia and Herzegovina' },
  BBD: { symbol: '$', country: 'Barbados' },
  BDT: { symbol: '৳', country: 'Bangladesh' },
  BGN: { symbol: 'лв', country: 'Bulgaria' },
  BHD: { symbol: '.د.ب', country: 'Bahrain' },
  BIF: { symbol: 'Fr', country: 'Burundi' },
  BMD: { symbol: '$', country: 'Bermuda' },
  BND: { symbol: '$', country: 'Brunei' },
  BOB: { symbol: 'Bs', country: 'Bolivia' },
  BRL: { symbol: 'R$', country: 'Brazil' },
  BSD: { symbol: '$', country: 'Bahamas' },
  BTN: { symbol: 'Nu.', country: 'Bhutan' },
  BWP: { symbol: 'P', country: 'Botswana' },
  BYN: { symbol: 'Br', country: 'Belarus' },
  BZD: { symbol: '$', country: 'Belize' },
  CAD: { symbol: '$', country: 'Canada' },
  CDF: { symbol: 'Fr', country: 'Democratic Republic of the Congo' },
  CHF: { symbol: 'CHF', country: 'Switzerland' },
  CLP: { symbol: '$', country: 'Chile' },
  CNY: { symbol: '¥', country: 'China' },
  COP: { symbol: '$', country: 'Colombia' },
  CRC: { symbol: '₡', country: 'Costa Rica' },
  CUP: { symbol: '₱', country: 'Cuba' },
  CVF: { symbol: 'Escudo', country: 'Cape Verde' },
  CZK: { symbol: 'Kč', country: 'Czech Republic' },
  DJF: { symbol: 'Fdj', country: 'Djibouti' },
  DKK: { symbol: 'kr', country: 'Denmark' },
  DOP: { symbol: '$', country: 'Dominican Republic' },
  DZD: { symbol: 'دج', country: 'Algeria' },
  EGP: { symbol: 'ج.م', country: 'Egypt' },
  ERN: { symbol: 'Nfk', country: 'Eritrea' },
  ETB: { symbol: 'Br', country: 'Ethiopia' },
  EUR: { symbol: '€', country: 'Eurozone' },
  FJD: { symbol: '$', country: 'Fiji' },
  FKP: { symbol: '£', country: 'Falkland Islands' },
  GBP: { symbol: '£', country: 'United Kingdom' },
  GEL: { symbol: '₾', country: 'Georgia' },
  GHS: { symbol: '₵', country: 'Ghana' },
  GIP: { symbol: '£', country: 'Gibraltar' },
  GMD: { symbol: 'D', country: 'Gambia' },
  GNF: { symbol: 'Fr', country: 'Guinea' },
  GTQ: { symbol: 'Q', country: 'Guatemala' },
  GYD: { symbol: '$', country: 'Guyana' },
  HKD: { symbol: '$', country: 'Hong Kong' },
  HNL: { symbol: 'L', country: 'Honduras' },
  HRK: { symbol: 'kn', country: 'Croatia' },
  HTG: { symbol: 'G', country: 'Haiti' },
  HUF: { symbol: 'Ft', country: 'Hungary' },
  IDR: { symbol: 'Rp', country: 'Indonesia' },
  ILS: { symbol: '₪', country: 'Israel' },
  INR: { symbol: '₹', country: 'India' },
  IQD: { symbol: 'د.ع', country: 'Iraq' },
  IRR: { symbol: '﷼', country: 'Iran' },
  ISK: { symbol: 'kr', country: 'Iceland' },
  JMD: { symbol: '$', country: 'Jamaica' },
  JOD: { symbol: 'د.ا', country: 'Jordan' },
  JPY: { symbol: '¥', country: 'Japan' },
  KES: { symbol: 'KSh', country: 'Kenya' },
  KGS: { symbol: 'с', country: 'Kyrgyzstan' },
  KHR: { symbol: '៛', country: 'Cambodia' },
  KMF: { symbol: 'Fr', country: 'Comoros' },
  KRW: { symbol: '₩', country: 'South Korea' },
  KWD: { symbol: 'د.ك', country: 'Kuwait' },
  KYD: { symbol: '$', country: 'Cayman Islands' },
  KZT: { symbol: '₸', country: 'Kazakhstan' },
  LAK: { symbol: '₭', country: 'Laos' },
  LBP: { symbol: 'ل.ل', country: 'Lebanon' },
  LKR: { symbol: 'Rs', country: 'Sri Lanka' },
  LRD: { symbol: '$', country: 'Liberia' },
  LSL: { symbol: 'M', country: 'Lesotho' },
  LTL: { symbol: 'Lt', country: 'Lithuania' },
  LVL: { symbol: 'Ls', country: 'Latvia' },
  LYD: { symbol: 'د.ل', country: 'Libya' },
  MAD: { symbol: 'د.م.', country: 'Morocco' },
  MDL: { symbol: 'L', country: 'Moldova' },
  MGA: { symbol: 'Ar', country: 'Madagascar' },
  MKD: { symbol: 'ден', country: 'North Macedonia' },
  MMK: { symbol: 'Ks', country: 'Myanmar' },
  MNT: { symbol: '₮', country: 'Mongolia' },
  MOP: { symbol: 'P', country: 'Macau' },
  MUR: { symbol: '₨', country: 'Mauritius' },
  MVR: { symbol: 'Rf', country: 'Maldives' },
  MWK: { symbol: 'MK', country: 'Malawi' },
  MXN: { symbol: '$', country: 'Mexico' },
  MYR: { symbol: 'RM', country: 'Malaysia' },
  MZN: { symbol: 'MT', country: 'Mozambique' },
  NAD: { symbol: '$', country: 'Namibia' },
  NGN: { symbol: '₦', country: 'Nigeria' },
  NIO: { symbol: 'C$', country: 'Nicaragua' },
  NOK: { symbol: 'kr', country: 'Norway' },
  NPR: { symbol: '₨', country: 'Nepal' },
  NZD: { symbol: '$', country: 'New Zealand' },
  OMR: { symbol: 'ر.ع.', country: 'Oman' },
  PAB: { symbol: 'B/.', country: 'Panama' },
  PEN: { symbol: 'S/.', country: 'Peru' },
  PGK: { symbol: 'K', country: 'Papua New Guinea' },
  PHP: { symbol: '₱', country: 'Philippines' },
  PKR: { symbol: '₨', country: 'Pakistan' },
  PLN: { symbol: 'zł', country: 'Poland' },
  PYG: { symbol: '₲', country: 'Paraguay' },
  QAR: { symbol: 'ر.ق', country: 'Qatar' },
  RON: { symbol: 'lei', country: 'Romania' },
  RSD: { symbol: 'дин.', country: 'Serbia' },
  RUB: { symbol: '₽', country: 'Russia' },
  RWF: { symbol: 'Fr', country: 'Rwanda' },
  SAR: { symbol: 'ر.س', country: 'Saudi Arabia' },
  SBD: { symbol: '$', country: 'Solomon Islands' },
  SCR: { symbol: '₨', country: 'Seychelles' },
  SDG: { symbol: 'ج.س.', country: 'Sudan' },
  SEK: { symbol: 'kr', country: 'Sweden' },
  SGD: { symbol: '$', country: 'Singapore' },
  SHP: { symbol: '£', country: 'Saint Helena' },
  SLL: { symbol: 'Le', country: 'Sierra Leone' },
  SOS: { symbol: 'Sh', country: 'Somalia' },
  SRD: { symbol: '$', country: 'Suriname' },
  SSP: { symbol: '£', country: 'South Sudan' },
  STN: { symbol: 'Db', country: 'São Tomé and Príncipe' },
  SYP: { symbol: 'ل.س', country: 'Syria' },
  SZL: { symbol: 'L', country: 'Swaziland' },
  THB: { symbol: '฿', country: 'Thailand' },
  TJS: { symbol: 'ЅМ', country: 'Tajikistan' },
  TMT: { symbol: 'm', country: 'Turkmenistan' },
  TND: { symbol: 'د.ت', country: 'Tunisia' },
  TOP: { symbol: 'T$', country: 'Tonga' },
  TRY: { symbol: '₺', country: 'Turkey' },
  TTD: { symbol: '$', country: 'Trinidad and Tobago' },
  TWD: { symbol: '$', country: 'Taiwan' },
  TZS: { symbol: 'Sh', country: 'Tanzania' },
  UAH: { symbol: '₴', country: 'Ukraine' },
  UGX: { symbol: 'USh', country: 'Uganda' },
  UYU: { symbol: '$', country: 'Uruguay' },
  UZS: { symbol: 'сум', country: 'Uzbekistan' },
  VEF: { symbol: 'Bs', country: 'Venezuela' },
  VND: { symbol: '₫', country: 'Vietnam' },
  VUV: { symbol: 'Vt', country: 'Vanuatu' },
  WST: { symbol: 'T', country: 'Samoa' },
  XAF: { symbol: 'Fr', country: 'Central African CFA franc' },
  XCD: { symbol: '$', country: 'Eastern Caribbean dollar' },
  XOF: { symbol: 'Fr', country: 'West African CFA franc' },
  XPF: { symbol: 'Fr', country: 'CFP franc' },
  YER: { symbol: 'ر.ي', country: 'Yemen' },
  ZAR: { symbol: 'R', country: 'South Africa' },
  ZMK: { symbol: 'ZK', country: 'Zambia' },
  ZWL: { symbol: '$', country: 'Zimbabwe' },
};

export default currencyData;
