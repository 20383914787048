import { useState } from 'react';
import Tabs from '../../../../components/Tabs/Tabs';
import CMSEvents from './CMSEvents';
import CustomEvent from './CustomEvent';

interface Props {
  apiKey: string;
  data: any;
  setAPIData: (data: any) => void;
  isAutofillEnabled: boolean;
  isTrackerON: boolean;
  optimizeValue: number;
  setScriptKey: (key: string) => void;
}

const EventTracking = ({
  apiKey,
  data,
  setAPIData,
  isAutofillEnabled,
  isTrackerON,
  optimizeValue,
  setScriptKey,
}: Props) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const tabItems = ['CMSEvents', 'CustomEvents'];
  return (
    <div>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabItems={tabItems}
      />

      {currentTab === 1 ? (
        <CustomEvent apiKey={apiKey} />
      ) : (
        <CMSEvents
          data={data}
          setAPIData={setAPIData}
          isAutofillEnabled={isAutofillEnabled}
          isTrackerON={isTrackerON}
          optimizeValue={optimizeValue}
          setScriptKey={setScriptKey}
        />
      )}
    </div>
  );
};

export default EventTracking;
