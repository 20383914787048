import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { GET_ALL_INTEGRATIONS_API } from '../../constants/central-api';
import { availableTrackableButtons } from '../../../pages/checkoutTracking/constants/availableTrackableButtons';
import { availableTrackableInputs } from '../../../pages/checkoutTracking/constants/availableTrackableInputs';
import { availableTrackableEvents } from '../../../pages/checkoutTracking/constants/availableTrackableEvents';
import isNotEmptyString from '../../helper-functions/isNotEmptyString';

function createFormData(
  sendingData: any,
  autofillConfig: { is_autofill_enabled: string },
  trackerSwitchConfig: { is_tracker_active: string },
  optimizationAdjustmentRatioConfig: { optimization_adjustment_ratio: number }
) {
  const formData: any = new FormData();
  formData.append('name', sendingData.name);
  formData.append('type', sendingData.type);

  // If the data is being updated, then add the _method key to the form data
  if (sendingData?._method === 'put') {
    formData.append('_method', sendingData?._method);
  }

  Object.entries(sendingData.configuration).forEach(([key, value]) => {
    if (typeof value === 'object' && Array.isArray(value)) {
      formData.append(`configs[${key}]`, JSON.stringify(value));
    } else {
      formData.append(`configs[${key}]`, value);
    }
  });

  //* Add autofill to the form data
  formData.append(
    `additional_configs[${Object.keys(autofillConfig)[0]}]`,
    Object.values(autofillConfig)[0]
  );

  //* Add trackerSwitch to the form data
  formData.append(
    `additional_configs[${Object.keys(trackerSwitchConfig)[0]}]`,
    Object.values(trackerSwitchConfig)[0]
  );

  //* Add optimizationAdjustmentRatio to the form data
  formData.append(
    `additional_configs[${Object.keys(optimizationAdjustmentRatioConfig)[0]}]`,
    Object.values(optimizationAdjustmentRatioConfig)[0]
  );

  //* Add availableTrackableButtons to the form data
  Object.entries(availableTrackableButtons).forEach(([key, value]) => {
    formData.append(
      `additional_configs[cssElementMapper][availableTrackableButtons][${key}]`,
      value
    );
  });

  //* Add availableTrackableInputs to the form data
  Object.entries(availableTrackableInputs).forEach(([key, value]) => {
    formData.append(
      `additional_configs[cssElementMapper][availableTrackableInputs][${key}]`,
      value
    );
  });

  //* Add availableTrackableEvents to the form data
  Object.entries(availableTrackableEvents).forEach(([key, value]) => {
    formData.append(
      `additional_configs[cssElementMapper][availableTrackableEvents][${key}]`,
      value
    );
  });

  return formData;
}

export default async function post_integration_api_call(
  sendingData: any,
  editingSlug?: string | undefined,
  autofillConfig: { is_autofill_enabled: 'true' | 'false' } = {
    is_autofill_enabled: 'false',
  },
  trackerSwitchConfig: { is_tracker_active: 'true' | 'false' } = {
    is_tracker_active: 'true',
  },
  optimizationAdjustmentRatioConfig: {
    optimization_adjustment_ratio: number;
  } = {
    optimization_adjustment_ratio: 0,
  },
  customMsg?: string | undefined
) {
  const formData = createFormData(
    sendingData,
    autofillConfig,
    trackerSwitchConfig,
    optimizationAdjustmentRatioConfig
  );
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shopSlug = store.getState().user?.company?.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!shopSlug) {
    return false;
  }

  const usingURL =
    sendingData?._method === 'put' && editingSlug
      ? GET_ALL_INTEGRATIONS_API(shopSlug) + `/${editingSlug}`
      : GET_ALL_INTEGRATIONS_API(shopSlug);

  const requestInfo: RequestInfo = {
    url: usingURL,
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'UPDATE_CHECKOUT_TRACKING_DATA';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg ?? '',
    isNotEmptyString(customMsg)
  );

  const response = res.data;
  if (response.success === true) {
    return response.data;
  } else {
    return false;
  }
}
