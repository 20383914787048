import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/customEvent.scss';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import '../../../../styles/generic/normalForm.scss';
import { useEffect, useState } from 'react';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { plusSignSVG } from '../../../../lib/allCustomSVGs';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import CustomCssTrackingTable from './CustomCssTrackingTable';
import AITagInput from '../../../../ui/inputs/AITagInput';
import { cssElementNames } from '../../../../lib/constants/trackingClassesData';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';
import store_custom_css_by_integration_key_api_call from '../../../../lib/api/customCSS/store_custom_css_by_integration_key_api_call';

type CustomCSS = {
  id: string;
  name: string;
  type: string;
  css_selectors: string[];
  active: boolean;
};

interface Props {
  apiKey: string;
}

const CustomCssTracking = ({ apiKey }: Props) => {
  const { t } = useTranslation();

  const trackingEventTypeArr = [
    { OptionValue: 'click', OptionName: t('Click') },
    { OptionValue: 'hover', OptionName: t('Hover') },
  ];

  const [customCSS, setCustomCSS] = useState<CustomCSS[]>([]);

  const [elementName, setElementName] = useState('');
  const [trackingEventType, setTrackingEventType] = useState(
    trackingEventTypeArr[0].OptionValue
  );
  const [cssSelectors, setCssSelectors] = useState<string[]>([]);

  const [customCSSFetchTriggerCount, setCustomCSSFetchTriggerCount] =
    useState(0);
  const [resetTagIncrement, setResetTagIncrement] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isElementNameInputInvalid, setIsElementNameInputInvalid] =
    useState<boolean>(false);

  const validateElementNameInput = (value: string) => {
    setElementName(value);

    const camelCasePattern = /^[a-z]+([A-Z][a-z]*)*$/;
    if (isNotEmptyString(value)) {
      if (camelCasePattern.test(value)) {
        setIsElementNameInputInvalid(false);
      } else {
        setIsElementNameInputInvalid(true);
      }
    }
  };

  const filterExistingElementNames = (
    value: string,
    isUpdateMode?: boolean,
    currentCustomCSSId?: string
  ) => {
    if (isUpdateMode && currentCustomCSSId) {
      // In update mode, exclude the current customCSS from the check
      if (
        customCSS.some(
          (customCSS) =>
            customCSS.name === value && customCSS.id !== currentCustomCSSId
        )
      ) {
        return true;
      }
    } else {
      // In create mode, check if any customCSS has the same name
      if (customCSS.some((customCSS) => customCSS.name === value)) {
        return true;
      }
    }
    return false;
  };

  const filterAdUpElementNames = (value: string) => {
    if (cssElementNames.includes(value)) {
      return true;
    }
    return false;
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    const elementNameAlreadyExists = filterExistingElementNames(elementName);

    if (elementNameAlreadyExists) {
      store.dispatch(
        setMessage({
          message: `${t('DuplicateCustomCSSElementName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    const elementNameIsAdUpElement = filterAdUpElementNames(elementName);

    if (elementNameIsAdUpElement) {
      store.dispatch(
        setMessage({
          message: `${t('AdUpCSSElementName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    setIsSubmitting(true);

    const res = await store_custom_css_by_integration_key_api_call(apiKey, [
      {
        name: elementName,
        type: trackingEventType,
        css_selectors: cssSelectors,
        is_active: true,
      },
    ]);

    if (res && res?.success) {
      setCustomCSSFetchTriggerCount(customCSSFetchTriggerCount + 1);

      // Reset the form fields after successful submission
      setElementName('');
      setTrackingEventType(trackingEventTypeArr[0].OptionValue);
      setCssSelectors([]);
      setResetTagIncrement((prev) => prev + 1);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!isNotEmptyString(elementName) || cssSelectors.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [elementName, cssSelectors]);

  const selectedTags = (tags: string[], id: string) => {
    setCssSelectors(tags);
  };

  return (
    <>
      <div className='customEvent'>
        <form
          id='customCssTracking-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <h1 className='customEvent-title'>{t('AddNewCSSTrackingRule')}</h1>
          <div className='normalForm__top'>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <AINormalInput
                  changeListeners={[
                    (event) =>
                      validateElementNameInput(
                        (event.target as HTMLInputElement).value
                      ),
                  ]}
                  id='ElementName-input'
                  value={elementName}
                  placeholder={t('ElementName')}
                  required={true}
                  innerPlaceholder={t('EnterElementName')}
                  pattern='^[a-z]+([A-Z][a-z]*)*$'
                  title={t('CustomCSSElementName-validation')}
                />
              </div>

              <div className='normalForm__container1--right'>
                <AITagInput
                  selectedTags={selectedTags}
                  id='CSSClassOrID-input'
                  initialTags={cssSelectors}
                  label={t('CSSClassOrID')}
                  innerPlaceholder={t('EnterCssSelector')}
                  required={cssSelectors.length === 0}
                  validationText={t('CssClassInput-validation')}
                  pattern={/^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/}
                  resetTagIncrement={resetTagIncrement}
                />
              </div>
            </div>

            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <AISelectInput
                  defaultValue={trackingEventType}
                  setSelect={setTrackingEventType}
                  selectOptionArr={trackingEventTypeArr}
                  placeholder={t('TrackingEvent')}
                  id={'TrackingEvent-select'}
                />
              </div>
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={isSubmitting ? `${t('Updating')}...` : t('AddRule')}
                icon={<CustomSVGs svg={plusSignSVG} />}
                disabled={
                  isDisabled || isSubmitting || isElementNameInputInvalid
                }
                type='submit'
                form='customCssTracking-form'
              />
            </div>
          </div>
        </form>
      </div>

      <div className='customEvent__table'>
        <CustomCssTrackingTable
          apiKey={apiKey}
          customCSSFetchTriggerCount={customCSSFetchTriggerCount}
          trackingEventTypeArr={trackingEventTypeArr}
          customCSS={customCSS}
          setCustomCSS={setCustomCSS}
          filterExistingElementNames={filterExistingElementNames}
          filterAdUpElementNames={filterAdUpElementNames}
        />
      </div>
    </>
  );
};

export default CustomCssTracking;
