const removeHttpPrefix = (value: string): string => {
  if (value.startsWith('https://www.')) {
    return value.split('https://www.')[1];
  } else if (value.startsWith('http://www.')) {
    return value.split('http://www.')[1];
  } else if (value.startsWith('https://')) {
    return value.split('https://')[1];
  } else if (value.startsWith('http://')) {
    return value.split('http://')[1];
  } else if (value.startsWith('www.')) {
    return value.split('www.')[1];
  }
  return value;
};

export default removeHttpPrefix;
