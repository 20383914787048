import { useState } from 'react';
import {
  aiGenerateSVG,
  arrowRightThinSVG,
  pauseSVG,
} from '../../../lib/allCustomSVGs';
import '../../../styles/pages/tara/taraUI/taraInput.scss';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { useTranslation } from 'react-i18next';
import { CurrentChatUpdate } from '../../../interfaces/taraInterfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import generateRandomString from '../../../lib/helper-functions/generateRandomString';

type Props = {
  updateChat: ({
    newChat,
    role,
    messageType,
    newChatId,
  }: CurrentChatUpdate) => void;
  cancelAPI: () => void;
  loading: 'pending' | 'success' | 'error' | 'null';
};

const TaraInput = ({ updateChat, cancelAPI, loading }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const currentChatID = useSelector(
    (state: RootState) => state.chat.currentChatID
  );
  const allChatsHistory = useSelector(
    (state: RootState) => state.chat.allChatsHistory
  );
  const currentChat =
    allChatsHistory.find((chat) => chat.chatId === currentChatID) || null;

  const isDisabled =
    currentChat &&
    currentChat.chatMessages &&
    currentChat.chatMessages[currentChat.chatMessages.length - 1]?.role ===
      'user'
      ? true
      : false;

  const handleChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const onSubmit = () => {
    if (inputValue !== '' && !isDisabled) {
      updateChat({
        newChatId: generateRandomString(10),
        newChat: inputValue,
        role: 'user',
        messageType: 'text',
      });
      setInputValue('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className='taraInput__container'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        data-is-input-empty={inputValue === '' ? 'true' : 'false'}
        className='taraInput'
      >
        <textarea
          autoFocus={true}
          value={inputValue}
          onChange={handleChangeValue}
          placeholder={t('Ask Tara a question...')}
          onKeyDown={handleKeyDown}
        />
        <div className='taraInput__icon'>
          <CustomSVGs svg={aiGenerateSVG} />
        </div>
        {loading === 'pending' ? (
          <button
            style={{
              borderRadius: '50%',
            }}
            title='Stop response!'
            type='button'
            className='taraInput__button'
            onClick={cancelAPI}
          >
            <CustomSVGs svg={pauseSVG} />
          </button>
        ) : (
          <button
            type='submit'
            className='taraInput__button'
            disabled={isDisabled}
          >
            <CustomSVGs svg={arrowRightThinSVG} />
          </button>
        )}
      </form>
      <span>{t('Tara can make mistakes.')}</span>
    </div>
  );
};

export default TaraInput;
