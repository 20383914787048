import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { GET_ALL_INTEGRATIONS_API } from '../../constants/central-api';
import { setAiOnboardingSelectedIntegrate } from '../../../store/reducers/userSlice';

export default async function get_checkout_tracking_api_call() {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shopSlug = store.getState().user?.company?.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!shopSlug) {
    return false;
  }

  const requestInfo: RequestInfo = {
    url: `${GET_ALL_INTEGRATIONS_API(shopSlug)}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_CHECKOUT_TRACKING_DATA';
  const customMsg = 'Available Checkout Tracking Data Loaded Successfully.';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (!response.success) {
    return false;
  }
  if (res.actionType === actionType && response.success === true) {
    if (response.data === null) {
      return [];
    } else {
      if (
        response?.data[response?.data.length - 1]?.additional_configs
          ?.CMS_type === 'shopify'
      ) {
        store.dispatch(setAiOnboardingSelectedIntegrate('shopify'));
      }
      return response?.data;
    }
  }
}
