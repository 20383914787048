import { useTranslation } from 'react-i18next';
import '../../styles/components/Tabs/tabs.scss';

type Props = {
  currentTab: number;
  setCurrentTab: (index: number) => void;
  tabItems: string[];
};

const Tabs = ({ currentTab, setCurrentTab, tabItems }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='tabs' data-recommendations-tab={currentTab}>
      {tabItems.map((tab, index) => (
        <div
          key={index}
          onClick={() => {
            setCurrentTab(index);
          }}
          className={`tabs__tab ${
            currentTab === index ? 'tabs__tab--active' : ''
          }`}
        >
          <h2>{t(tab)}</h2>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
