import '../../styles/pages/shop/shopPage.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import Fastcheckout from './Fastcheckout/Fastcheckout';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import { fastcheckoutSVG, cmsSVG } from '../../lib/allCustomSVGs';
import CategoryCard from '../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../store/store';
import CMS from '../shop/cms/CMS';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import { allRoutes, HOME_ROUTE } from '../../lib/constants/route';
import Autofill from './Autofill/Autofill';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
const IntegrationsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );

  const integrationsPageContent = () => {
    return (
      <SubPageInnerContentHolder
        title={t('Integrations')}
        // subTitle={t('ManageIntegrations')}
        backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
        // mainBtnIcon={plusSignSVG}
        // mainBtnTitle='Add CMS'
        // mainBtnLink='/integrations/cms/connection/add'
      >
        <div className='shopPage'>
          <div className='shopPage__container1'>
            {/* //? disabled until pay is integrated */}
            <CategoryCard
              icon={cmsSVG}
              title={'CMS'}
              subTitle={t('ManageCMS')}
              link='/integrations/cms/connection'
            />
            {merchantName !== 'Pay.' && (
              <CategoryCard
                icon={fastcheckoutSVG}
                title={t('FastCheckout')}
                subTitle={t('ManageFastCheckout')}
                link='/integrations/fastcheckout'
              />
            )}
            {/* <CategoryCard
            icon={socialCommerceSVG}
            title={t('SocialCommerce')}
            subTitle={t('ManageSocialCommerce')}
            link='#'
            onSelect={() => {}}
             link='/shop?product_tour_id=396720'
            onSelect={() => {
              window.open(
                `${window.location.origin}/shop?product_tour_id=396720`,
                '_self'
              );
            }}
          /> */}
            {/* {merchantName !== 'Pay.' && (
            <CategoryCard
              icon={autofillSVG}
              title={t('Autofill')}
              subTitle={t('ManageAutofill')}
              link='/integrations/autofill'
            />
          )} */}
          </div>
        </div>
      </SubPageInnerContentHolder>
    );
  };

  return (
    <PageWrapperMain>
      <div className='subPage'>
        <SubPageContentHolder>
          {activeLink === 'integrations' && integrationsPageContent()}
          <Routes>
            {allRoutes['/integrations/cms'] && (
              <Route path='cms/*' element={<CMS />} />
            )}
            {allRoutes['/integrations/fastcheckout'] && (
              <Route path='fastcheckout/*' element={<Fastcheckout />} />
            )}
            {allRoutes['/integrations/autofill'] && (
              <Route path='autofill/*' element={<Autofill />} />
            )}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapperMain>
  );
};

export default IntegrationsPage;
