import CircularProgress from '@mui/material/CircularProgress';
import '../../styles/buttons/mainSquareButton.scss';

type props = {
  onClick?: (event: React.MouseEvent) => void;
  icon?: any;
  image?: any;
  value: string;
  classes?: string;
  loading?: boolean;
  disabled?: boolean;
  normal?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonType?: 'danger' | 'warning' | 'success' | 'light' | 'filter' | 'delete';
  form?: string;
  message?: string;
};

const MainSquareButton: React.FC<props> = ({
  onClick,
  icon,
  image,
  value,
  type,
  disabled,
  classes = '',
  normal,
  buttonType,
  form,
  message,
  loading,
}) => {
  let isNormalButton;
  if (normal) isNormalButton = 'true';
  else isNormalButton = 'false';

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={'mainSquareButton' + (' ' + classes)}
      data-main-square-button-normal={isNormalButton}
      data-main-square-button-type={buttonType}
      data-main-square-button-loading={loading ? 'true' : 'false'}
      form={form}
      title={disabled ? message : ''}
    >
      <div className='mainSquareButton__loading'>
        <div className='mainSquareButton__loading--spinner'>
          <CircularProgress
            size={'1.5rem'}
            style={{
              color: 'var(--main-btn-text-color)',
            }}
          />
        </div>
      </div>

      <div className='mainSquareButton__content'>
        {icon}
        {!icon && image && <img src={image} alt={value} />}
        <p>{value}</p>
      </div>
    </button>
  );
};

export default MainSquareButton;
