import '../../styles/inputs/valueSelector.scss';

type ValueSelectorItem = {
  label: string;
  value: number;
};

type Props = {
  value: number;
  loading: boolean;
  onChange: (value: number) => void;
  symbol?: string;
  content: ValueSelectorItem[];
  isEqualSignVisible?: boolean;
};

const ValueSelector = ({
  value,
  loading,
  onChange,
  symbol,
  content,
  isEqualSignVisible,
}: Props) => {
  return (
    <div className='valueSelector'>
      <div className='valueSelector__content'>
        {content?.map((item, index) => (
          <div
            onClick={() => {
              if (!loading && item?.value !== value) {
                onChange(item?.value);
              }
            }}
            key={index}
            style={
              loading || item?.value === value ? { cursor: 'not-allowed' } : {}
            }
            className={`valueSelector__content__item ${
              item?.value > value ? 'valueSelector__content__item--light' : ''
            } ${loading ? 'valueSelector__content__item--loading' : ''}`}
          >
            {item?.label}
          </div>
        ))}
      </div>
      {!loading && (
        <div className='valueSelector__value'>
          {`${isEqualSignVisible ? ' = ' : ''} ${value}${symbol ? symbol : ''}`}
        </div>
      )}
    </div>
  );
};

export default ValueSelector;
