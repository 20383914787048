// TaraSearchInput.tsx
import { useTranslation } from 'react-i18next';
import { searchSVG } from '../../../lib/allCustomSVGs';
import '../../../styles/pages/tara/search/taraSearchInput.scss';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';

type Props = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

const TaraSearchInput = ({ searchQuery, setSearchQuery }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='taraSearchInput'>
      <div className='taraSearchInput__left'>
        <CustomSVGs svg={searchSVG} />
      </div>

      <div className='taraSearchInput__right'>
        <input
          type='text'
          placeholder={t('SearchForChat')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TaraSearchInput;
