import SimpleCard from '../../../../ui/cards/SimpleCard';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/trends.scss';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import statsLottie from '../../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../../assets/json/lottie_animation/setup_tracking.json';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import CountdownTimer from '../../../../ui/timers/CountdownTimer';
import addDaysToDate from '../../../../lib/helper-functions/addDaysToDate';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import RecommendationItem from './RecommendationItem';
import { DAYS_TO_ADD } from '../../../../lib/constants/abstracts';
import get_recommendations_api_call from '../../../../lib/api/ai-tracker/get_recommendations_api_call';
import SelectableButton from '../../../../ui/buttons/SelectableButton';
import { creditCardSVG, giftCardSVG } from '../../../../lib/allCustomSVGs';
import Popup from '../../../../ui/popup/Popup';
import set_recommendation_api_call from '../../../../lib/api/ai-tracker/set_recommendation_api_call';
import RecommendationsTrial from './RecommendationsTrial';
import get_subscriptions_api_call from '../../../../lib/api/ai-tracker/subscriptions/get_subscriptions_api_call';
import RecommendationPackages from './RecommendationPackages';
interface Props {
  apiKey: string;
  isTrackable: boolean;
}

const Trends = ({ isTrackable, apiKey }: Props) => {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [activeRecommendations, setActiveRecommendations] = useState<any[]>([]);
  const [rejectedRecommendations, setRejectedRecommendations] = useState<any[]>(
    []
  );
  const [currentTab, setCurrentTab] = useState<string>('new');
  const ai_tracker_configured_timestamp = useSelector(
    (state: RootState) => state.user.stats.ai_tracker_configured_timestamp
  );
  const is_tracker_access_token = useSelector(
    (state: RootState) => state.user?.stats?.ai_tracker_access_token
  );
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [isTrialActive, setIsTrialActive] = useState<boolean>(false);
  const [trialRemainingDays, setTrialRemainingDays] = useState<number>(0);
  const [isPaymentActive, setIsPaymentActive] = useState<boolean>(false);
  const [isTrialEnded, setIsTrialEnded] = useState<boolean>(false);

  //Payment popup
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const togglePaymentPopupModal = () => {
    setIsPaymentPopupOpen(!isPaymentPopupOpen);
  };
  const [isTrialPopupOpen, setIsTrialPopupOpen] = useState(false);
  const toggleTrialPopupModal = () => {
    setIsTrialPopupOpen(!isTrialPopupOpen);
  };

  const futureDate = addDaysToDate(
    ai_tracker_configured_timestamp ? ai_tracker_configured_timestamp : '',
    DAYS_TO_ADD
  );

  const [isTimesUp, setIsTimesUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');
  const [loadingRecommendationsGet, setLoadingRecommendationsGet] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');

  useEffect(() => {
    // if (is_tracker_access_token !== '') {
    //   setLoading('pending');
    // }
    if (isTimesUp && is_tracker_access_token !== '') {
      getSubscriptions();
    }
  }, [isTimesUp, is_tracker_access_token]);

  const calculateRemainingDays = (endDate: string): number => {
    const now: Date = new Date();
    const end: Date = new Date(endDate);

    const startOfToday: Date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );

    const differenceInTime: number = end.getTime() - startOfToday.getTime();

    const differenceInDays: number = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );

    return differenceInDays >= 0 ? differenceInDays : 0;
  };

  // GET Graph data
  /* const getGraphData = async () => {
    try {
      const params = `?graph_type=recommendation_impact&time_range=all`;
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        return res;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  }; */

  const getSubscriptions = async () => {
    try {
      setLoading('pending');
      const res = await get_subscriptions_api_call();

      if (res) {
        if (res?.length > 0) {
          const currentSubscription = res[res?.length - 1];
          const is_trial_active = currentSubscription?.is_trial_active;
          const is_active = currentSubscription?.is_active;

          if (is_trial_active === 0 && is_active === 0) {
            // trial expired and paid package inactive
            setIsTrialActive(false);
            setIsPaymentActive(false);
            setIsTrialEnded(true);
          }
          if (is_trial_active === 1 && is_active === 0) {
            // trial expired
            setIsPaymentActive(false);
            setIsTrialActive(false);
            setIsTrialEnded(true);
          }
          if (is_trial_active === 1 && is_active === 1) {
            // trial active and package not yet bought
            setIsTrialActive(true);
            setIsPaymentActive(false);
            const trialEndDate = currentSubscription?.end_date;
            const remainingDays = calculateRemainingDays(trialEndDate);
            setTrialRemainingDays(remainingDays);
          }
          if (is_trial_active === 0 && is_active === 1) {
            // trial expired and paid package active
            setIsPaymentActive(true);
            setIsTrialActive(false);
          }
        } else {
          //trial not started and package not activated
          setIsTrialPopupOpen(true);
          setIsTrialActive(false);
          setIsPaymentActive(false);
        }
        setLoading('success');
        getRecommendations();
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      setLoading('error');
    }
  };

  const getRecommendations = async () => {
    setLoadingRecommendationsGet('pending');
    try {
      //This is temporarily disabled until the API is ready
      const graphDataRes = { recommendations: [] };
      // const graphDataRes = await getGraphData();
      let impact: any[] = [];

      if (graphDataRes) {
        if (
          graphDataRes?.recommendations &&
          graphDataRes?.recommendations.length > 0
        ) {
          impact = graphDataRes?.recommendations;
        } else {
          impact = [];
        }
      }

      const res = await get_recommendations_api_call();

      if (res) {
        setLoadingRecommendationsGet('success');
        const {
          recommendations,
          active_recommendations,
          rejected_recommendations,
        } = res;

        if (recommendations?.length > 0) {
          const newRecommendations = recommendations?.map(
            (recommendation: any) => {
              if (impact && impact?.length > 0) {
                const recommendationData = impact?.find(
                  (data: any) => data?.recommendation_id === recommendation?.id
                );

                if (recommendationData) {
                  return {
                    ...recommendation,
                    is_paused: false,
                    loadingStatus: 'success',
                    ctr_difference: recommendationData.ctr_difference,
                  };
                } else {
                  return {
                    ...recommendation,
                    is_paused: false,
                    loadingStatus: 'success',
                  };
                }
              } else {
                return {
                  ...recommendation,
                  is_paused: false,
                  loadingStatus: 'success',
                };
              }
            }
          );

          setRecommendations(newRecommendations);
        }

        if (active_recommendations?.length > 0) {
          const newActiveRecommendations = active_recommendations?.map(
            (recommendation: any) => {
              if (impact && impact?.length > 0) {
                const recommendationData = impact?.find(
                  (data: any) => data?.recommendation_id === recommendation?.id
                );

                if (recommendationData) {
                  return {
                    ...recommendation,
                    loadingStatus: 'success',
                    ctr_difference: recommendationData.ctr_difference,
                  };
                } else {
                  return {
                    ...recommendation,
                    loadingStatus: 'success',
                  };
                }
              } else {
                return {
                  ...recommendation,
                  loadingStatus: 'success',
                };
              }
            }
          );

          setActiveRecommendations(newActiveRecommendations);
        }

        if (rejected_recommendations?.length > 0) {
          const newRejectedRecommendations = rejected_recommendations?.map(
            (recommendation: any) => {
              if (impact && impact?.length > 0) {
                const recommendationData = impact?.find(
                  (data: any) => data?.recommendation_id === recommendation?.id
                );

                if (recommendationData) {
                  return {
                    ...recommendation,
                    loadingStatus: 'success',
                    ctr_difference: recommendationData.ctr_difference,
                  };
                } else {
                  return {
                    ...recommendation,
                    loadingStatus: 'success',
                  };
                }
              } else {
                return {
                  ...recommendation,
                  loadingStatus: 'success',
                };
              }
            }
          );

          setRejectedRecommendations(newRejectedRecommendations);
        }
      } else {
        setLoadingRecommendationsGet('null');
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoadingRecommendationsGet('error');
    }
  };

  const setLoadingStatus = (
    id: string,
    status: string,
    type: 'active' | 'inactive' | 'rejected'
  ) => {
    let targetRecommendations;

    if (type === 'active') {
      targetRecommendations = activeRecommendations;
    } else if (type === 'inactive') {
      targetRecommendations = recommendations;
    } else {
      targetRecommendations = rejectedRecommendations;
    }

    const newRecommendations = targetRecommendations?.map((item) =>
      item.id === id ? { ...item, loadingStatus: status } : item
    );

    if (type === 'active') {
      setActiveRecommendations(newRecommendations);
    } else if (type === 'inactive') {
      setRecommendations(newRecommendations);
    } else {
      setRejectedRecommendations(newRecommendations);
    }
  };

  const onRecommendationSelect = (id: string) => {
    const filteredRecommendations = recommendations?.filter(
      (recommendation) => recommendation.id !== id
    );
    const selectedRecommendation = recommendations?.find(
      (recommendation) => recommendation.id === id
    );
    const updatedActiveRecommendations = [
      ...activeRecommendations,
      { ...selectedRecommendation, loadingStatus: 'initial' },
    ];
    setRecommendations(filteredRecommendations);
    setActiveRecommendations(updatedActiveRecommendations);
  };

  const onRecommendationReject = (
    id: string,
    type: 'active' | 'inactive' | 'rejected'
  ) => {
    const filteredRecommendations =
      type === 'active'
        ? activeRecommendations?.filter(
            (recommendation) => recommendation.id !== id
          )
        : recommendations?.filter((recommendation) => recommendation.id !== id);

    const selectedRecommendation =
      type === 'active'
        ? activeRecommendations?.find(
            (recommendation) => recommendation.id === id
          )
        : recommendations?.find((recommendation) => recommendation.id === id);

    const updatedRejectedRecommendations = [
      ...rejectedRecommendations,
      { ...selectedRecommendation, loadingStatus: 'initial' },
    ];

    if (type === 'active') {
      setActiveRecommendations(filteredRecommendations);
    } else {
      setRecommendations(filteredRecommendations);
    }

    setRejectedRecommendations(updatedRejectedRecommendations);
  };

  const onRecommendationUnarchive = (id: string) => {
    const filteredRejectedRecommendations = rejectedRecommendations?.filter(
      (recommendation) => recommendation.id !== id
    );
    const unrejectedRecommendation = rejectedRecommendations?.find(
      (recommendation) => recommendation.id === id
    );
    const updatedRecommendations = [
      ...recommendations,
      { ...unrejectedRecommendation, loadingStatus: 'initial' },
    ];
    setRejectedRecommendations(filteredRejectedRecommendations);
    setRecommendations(updatedRecommendations);
  };

  const onRecommendationUnselect = (id: string) => {
    const filteredActiveRecommendations = activeRecommendations?.filter(
      (recommendation) => recommendation.id !== id
    );
    const unselectedRecommendation = activeRecommendations?.find(
      (recommendation) => recommendation.id === id
    );
    const updatedRecommendations = [
      ...recommendations,
      { ...unselectedRecommendation, loadingStatus: 'initial' },
    ];
    setActiveRecommendations(filteredActiveRecommendations);
    setRecommendations(updatedRecommendations);
  };

  const onRecommendationPause = (id: string) => {
    const updatedRecommendations = activeRecommendations?.map((item) =>
      item.id === id ? { ...item, is_paused: 1 } : item
    );
    setActiveRecommendations(updatedRecommendations);
  };

  const onRecommendationResume = (id: string) => {
    const updatedRecommendations = activeRecommendations?.map((item) =>
      item.id === id ? { ...item, is_paused: 0 } : item
    );
    setActiveRecommendations(updatedRecommendations);
  };

  const setRecommendationsHandler = async (
    id: string,
    action: 'select' | 'unselect' | 'pause' | 'resume' | 'reject' | 'unarchive',
    type: 'active' | 'inactive' | 'rejected'
  ) => {
    try {
      setLoadingStatus(id, 'pending', type);

      const res = await set_recommendation_api_call(id, action);

      if (res && res.success) {
        if (action === 'select') {
          onRecommendationSelect(id);
        } else if (action === 'unselect') {
          onRecommendationUnselect(id);
        } else if (action === 'pause') {
          onRecommendationPause(id);
        } else if (action === 'resume') {
          onRecommendationResume(id);
        } else if (action === 'reject') {
          onRecommendationReject(id, type);
        } else if (action === 'unarchive') {
          onRecommendationUnarchive(id);
        }
      } else {
        setLoadingStatus(id, 'initial', type);
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoadingStatus(id, 'initial', type);
    }
  };

  const renderTrialButton = () => {
    if (isTrialEnded) return null;

    if (isTrialActive) {
      return (
        <SelectableButton
          icon={giftCardSVG}
          title={''}
          subTitle={t('TrialActive')}
          subTitle2={
            trialRemainingDays > 0
              ? `${trialRemainingDays} ${t('DaysLeft')}`
              : t('TrialExpired')
          }
          link=''
          onSelect={() => {
            if (isTrialActive) return;
            setIsTrialPopupOpen(true);
          }}
          isSelected={isTrialActive}
        />
      );
    } else {
      return (
        <SelectableButton
          icon={giftCardSVG}
          title={''}
          subTitle={t('FreeTrial')}
          subTitle2={t('GetFreeRecommendationsFor14Days')}
          link=''
          onSelect={() => {
            if (isTrialActive) return;
            setIsTrialPopupOpen(true);
          }}
          isSelected={isTrialActive}
        />
      );
    }
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}

      <div className='flex-row-full-width-flex-wrap'>
        {isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText1')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText2')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {isTimesUp && loading === 'success' && (
          <div className='normalForm'>
            <div className='normalForm__top'>
              {/* {isPaymentActive && !isTrialActive ? null : (
                <div className='shopPage__container1-alignLeft'>
                  <SelectableButton
                    icon={creditCardSVG}
                    title={''}
                    subTitle={t('PayWhatYouWant')}
                    subTitle2={t('UnlockAllRecommendations')}
                    link=''
                    onSelect={() => {
                      if (isPaymentActive && !isTrialActive) return;
                      setIsPaymentPopupOpen(true);
                    }}
                    isSelected={isPaymentActive && !isTrialActive}
                  />
                  {renderTrialButton()}
                </div>
              )} */}

              <div className='recommendations--all'>
                <div
                  style={{
                    marginTop:
                      isPaymentActive && !isTrialActive ? '0rem' : '3rem',
                  }}
                  className='recommendationsTabs'
                  data-recommendations-tab={currentTab}
                >
                  <div
                    onClick={() => {
                      setCurrentTab('new');
                    }}
                    className='recommendationsTabs__tab recommendationsTabs__tab--new'
                  >
                    <h2>{t('New')}</h2>
                    <span>{`(${recommendations?.length})`}</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentTab('active');
                    }}
                    className='recommendationsTabs__tab recommendationsTabs__tab--active'
                  >
                    <h2>{t('ActiveUpper')}</h2>
                    <span>{`(${activeRecommendations?.length})`}</span>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentTab('archived');
                    }}
                    className='recommendationsTabs__tab recommendationsTabs__tab--archived'
                  >
                    <h2>{t('Archived')}</h2>
                    <span>{`(${rejectedRecommendations?.length})`}</span>
                  </div>
                </div>
                {loadingRecommendationsGet === 'pending' && <LoadingSpinner />}
                {/* suggested recommendations */}
                {currentTab === 'new' &&
                  loadingRecommendationsGet === 'success' && (
                    <div className='recommendations'>
                      <h2>{t('Recommendations')}</h2>
                      <h3>{t('SuggestedRecommendationsText')}</h3>

                      {recommendations?.length === 0 && (
                        <div className='recommendationItem__container--none'>
                          <NoData title={t('NoRecommendations')} />
                        </div>
                      )}
                      {recommendations?.length > 0 && (
                        <div className='recommendationItem__container'>
                          {!isPaymentActive && !isTrialActive && (
                            <div className='recommendationItem__container--overlay'></div>
                          )}
                          {recommendations?.map((data: any, index: number) => (
                            <RecommendationItem
                              key={index}
                              data={data}
                              type='inactive'
                              setRecommendationsHandler={
                                setRecommendationsHandler
                              }
                              isDisabled={!isPaymentActive && !isTrialActive}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                {/* active recommendations */}
                {currentTab === 'active' &&
                  loadingRecommendationsGet === 'success' && (
                    <div className='recommendations'>
                      <h2> {t('ActiveRecommendations')}</h2>
                      <h3>{t('ActiveRecommendationsText')}</h3>
                      {activeRecommendations?.length === 0 && (
                        <div className='recommendationItem__container--none'>
                          <NoData title={t('NoActiveRecommendations')} />
                        </div>
                      )}
                      {activeRecommendations?.length > 0 && (
                        <div className='recommendationItem__container'>
                          {!isPaymentActive && !isTrialActive && (
                            <div className='recommendationItem__container--overlay'></div>
                          )}
                          {activeRecommendations?.map(
                            (data: any, index: number) => (
                              <RecommendationItem
                                key={index}
                                data={data}
                                type='active'
                                setRecommendationsHandler={
                                  setRecommendationsHandler
                                }
                                isDisabled={!isPaymentActive && !isTrialActive}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  )}

                {/* rejected recommendations */}
                {currentTab === 'archived' &&
                  loadingRecommendationsGet === 'success' && (
                    <div className='recommendations'>
                      <h2> {t('ArchivedRecommendations')}</h2>
                      <h3>{t('ArchivedRecommendationsText')}</h3>
                      {rejectedRecommendations?.length === 0 && (
                        <div className='recommendationItem__container--none'>
                          <NoData title={t('NoArchivedRecommendations')} />
                        </div>
                      )}
                      {rejectedRecommendations?.length > 0 && (
                        <div className='recommendationItem__container'>
                          {!isPaymentActive && !isTrialActive && (
                            <div className='recommendationItem__container--overlay'></div>
                          )}
                          {rejectedRecommendations?.map(
                            (data: any, index: number) => (
                              <RecommendationItem
                                key={index}
                                data={data}
                                type='rejected'
                                setRecommendationsHandler={
                                  setRecommendationsHandler
                                }
                                isDisabled={!isPaymentActive && !isTrialActive}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}

        <CountdownTimer
          prefix={t('StillCollectingData')}
          suffix={`${t('remaining')}.`}
          className='dashboardTab__item--left-subtitle'
          futureDate={futureDate}
          setIsTimesUp={setIsTimesUp}
          display={false}
        />
      </div>

      {/* ------payment popup start-------- */}
      {/* <div className='recommendations-payment-popup-custom'>
        <Popup
          styles={{
            backgroundColor: 'var(--sidebar-background-color)',
          }}
          setTrigger={togglePaymentPopupModal}
          trigger={isPaymentPopupOpen}
        >
          <RecommendationPackages
            setIsPaymentPopupOpen={setIsPaymentPopupOpen}
          />
        </Popup>
      </div> */}
      {/* ------payment popup end-------- */}

      {/* ------Trial popup start-------- */}
      {/* <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={toggleTrialPopupModal}
        trigger={isTrialPopupOpen}
      >
        <RecommendationsTrial
          setIsTrialPopupOpen={setIsTrialPopupOpen}
          getSubscriptions={getSubscriptions}
        />
      </Popup> */}
      {/* ------payment popup end-------- */}
    </>
  );
};

export default Trends;
