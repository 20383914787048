import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/appSlice';
import '../../styles/generic/message.scss';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import {
  crossSmallSVG,
  infoSVG,
  tickSVG,
  warningSVG,
} from '../../lib/allCustomSVGs';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { useTranslation } from 'react-i18next';

interface Props {
  msgType: string;
  msg: string;
}

const Message = ({ msgType, msg }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setMessage({ messageType: '', message: '' }));
    }, 5000);
  }, []);

  const renderMessageIcon = () => {
    if (msgType === 'success') {
      return <CustomSVGs svg={tickSVG} />;
    } else if (msgType === 'info') {
      return <CustomSVGs svg={infoSVG} />;
    } else if (msgType === 'warning') {
      return <CustomSVGs svg={warningSVG} />;
    } else {
      return <CustomSVGs svg={crossSmallSVG} />;
    }
  };
  const renderMessageHeading = () => {
    if (msgType === 'success') {
      return t('Success');
    } else if (msgType === 'info') {
      return t('Info');
    } else if (msgType === 'warning') {
      return t('Warning');
    } else {
      return t('Error');
    }
  };

  if (msgType && msg) {
    return (
      <motion.div
        data-msgtype={msgType}
        className='message'
        animate={{ y: 0 }}
        // animate={{ y: 10 }}
        initial={{ y: -500 }}
        transition={{ duration: 0.7 }}
        exit={{ y: -500 }}
      >
        <div className='message__left'>
          <div className='message__left--icon'>{renderMessageIcon()}</div>
        </div>
        <div className='message__right'>
          <h1>{renderMessageHeading()}</h1>
          <p>{makeFirstLetterCapital(msg)}</p>
        </div>

        <div
          onClick={() => {
            dispatch(setMessage({ messageType: '', message: '' }));
          }}
          className='message__close'
        >
          <CustomSVGs svg={crossSmallSVG} />
        </div>
      </motion.div>
    );
  } else {
    return <div></div>;
  }
};

export default Message;
