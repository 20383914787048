import { useSelector } from 'react-redux';
import MainAverageCartValueChart from '../../../components/charts/AverageCartValue/MainAverageCartValueChart';
import MainCTRPerTimeOfDayChart from '../../../components/charts/ClickThroughRate/CTRPerTimeOfDay/MainCTRPerTimeOfDayChart';
import MainCTRChart from '../../../components/charts/ClickThroughRate/MainCTRChart';
import MainDeviceBasedCRChart from '../../../components/charts/ConversionRate/DeviceBased/MainDeviceBasedCRChart';
import MainOverallAndLocationBasedCRChart from '../../../components/charts/ConversionRate/OverallAndLocationBased/MainOverallAndLocationBasedCRChart';
import OverallConversionRatePieChart from '../../../components/charts/ConversionRate/OverallAndLocationBased/OverallConversionRatePieChart';
import MainScreenSizeBasedCRChart from '../../../components/charts/ConversionRate/ScreenSizeBased/MainScreenSizeBasedCRChart';
import MainTimeAndDayCRChart from '../../../components/charts/ConversionRate/TimeAndDay/MainTimeAndDayCRChart';
import WeeklyConversionRateLineChart from '../../../components/charts/ConversionRate/Weekly/WeeklyConversionRateLineChart';
import FunnelGraph from '../../../components/charts/FunnelGraph/FunnelGraph';
import LazyLoad from '../../../components/charts/LazyLoad';
import MainUserDemographicsChart from '../../../components/charts/UserDemographics/MainUserDemographicsChart';
import { RootState } from '../../../store/store';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const AllGraphs = ({
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter,
}: Props) => {
  const CMS_Type = useSelector(
    (state: RootState) => state.user.company?.cmsSelected ?? ''
  );

  return (
    <div className='chartLayout'>
      <div className='chartLayout--content'>
        <LazyLoad className='chartLayout--content-small'>
          <OverallConversionRatePieChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </LazyLoad>
        <LazyLoad className='chartLayout--content-large'>
          <WeeklyConversionRateLineChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </LazyLoad>
      </div>
      {/* --------------------------------------------- */}

      <div className='chartLayout--content'>
        <LazyLoad className='chartLayout--content-large'>
          <MainDeviceBasedCRChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </LazyLoad>
        <LazyLoad className='chartLayout--content-small'>
          <MainOverallAndLocationBasedCRChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </LazyLoad>
      </div>

      {/* --------------------------------------------- */}

      {eventFilter === 'purchase' && CMS_Type !== 'shopify' ? (
        <div className='chartLayout--content'>
          <LazyLoad className='chartLayout--content-small'>
            <MainCTRChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
          <LazyLoad className='chartLayout--content-large'>
            <MainAverageCartValueChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
        </div>
      ) : null}

      {eventFilter !== 'purchase' && CMS_Type !== 'shopify' ? (
        <LazyLoad className='chartLayout--content'>
          <MainCTRChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </LazyLoad>
      ) : null}

      {/* --------------------------------------------- */}

      {eventFilter === 'purchase' && CMS_Type === 'shopify' ? (
        <div className='chartLayout--content'>
          <LazyLoad className='chartLayout--content-large'>
            <MainScreenSizeBasedCRChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
          <LazyLoad className='chartLayout--content-small'>
            <MainAverageCartValueChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
        </div>
      ) : null}

      {/* --------------------------------------------- */}

      {CMS_Type !== 'shopify' ? (
        <div className='chartLayout--content'>
          <LazyLoad className='chartLayout--content-large'>
            <MainScreenSizeBasedCRChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
          <LazyLoad className='chartLayout--content-small'>
            <MainCTRPerTimeOfDayChart
              timeFilter={timeFilter}
              customPeriodFilterDateRange={customPeriodFilterDateRange}
              eventFilter={eventFilter}
            />
          </LazyLoad>
        </div>
      ) : null}

      {/* --------------------------------------------- */}
      {eventFilter === 'purchase' ? (
        <div className='chartLayout--content'>
          <LazyLoad className='chartLayout--content-small'>
            <MainTimeAndDayCRChart
              timeFilter={'time_of_day'}
              eventFilter={eventFilter}
            />
          </LazyLoad>
          <LazyLoad className='chartLayout--content-large'>
            <MainTimeAndDayCRChart
              timeFilter={'day_of_week'}
              eventFilter={eventFilter}
            />
          </LazyLoad>
        </div>
      ) : (
        <LazyLoad className='chartLayout--content'>
          <MainTimeAndDayCRChart
            timeFilter={'day_of_week'}
            eventFilter={eventFilter}
          />
        </LazyLoad>
      )}

      {/* --------------------------------------------- */}

      {eventFilter === 'purchase' ? (
        <LazyLoad className='chartLayout--content'>
          <FunnelGraph
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
          />
        </LazyLoad>
      ) : null}

      {/* --------------------------------------------- */}

      <div className='chartLayout--content'>
        {/* <div className='chartLayout--content-large'>
          <MainReferrersChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        </div>
        <div className='chartLayout--content-small'>
          <PaymentMethodOrderChart
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
          />
        </div> */}
      </div>

      {/* --------------------------------------------- */}

      <LazyLoad className='chartLayout--content'>
        <MainUserDemographicsChart
          timeFilter={timeFilter}
          customPeriodFilterDateRange={customPeriodFilterDateRange}
          eventFilter={eventFilter}
        />
      </LazyLoad>
    </div>
  );
};

export default AllGraphs;
