import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ParticleAnimation from './ParticleAnimation';
import '../../styles/components/splashScreen/splashScreenNew.scss';
import { useTranslation } from 'react-i18next';

const SplashScreenNew = () => {
  const { t } = useTranslation();
  const [welcomeText, setWelcomeText] = useState<string>(
    t('NewSplashScreenText0')
  );

  useEffect(() => {
    const texts = [
      t('NewSplashScreenText1'),
      t('NewSplashScreenText2'),
      t('NewSplashScreenText3'),
      t('NewSplashScreenText4'),
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % texts.length;
      setWelcomeText(texts[currentIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='splashScreenNew'>
      <div className='splashScreenNew__content'>
        <div className='splashScreenNew__content--text'>
          <motion.h1
            key={welcomeText}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
          >
            {welcomeText}
          </motion.h1>
        </div>
        <div className='splashScreenNew__content--animation'>
          <div className='splashScreenNew__content--animation-container'>
            <ParticleAnimation />
            {/* <p>Initializing AI</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreenNew;
