import { t } from 'i18next';
import shopify_auth_api_call from '../../../../lib/api/shopify/shopify_auth_api_call';
import shopify_inject_script_api_call from '../../../../lib/api/shopify/shopify_inject_script_api_call';
import { AI_CHECKOUT_TRACKING_LINK } from '../../../../lib/constants/central-api';
import { setMessage } from '../../../../store/reducers/appSlice';
import store from '../../../../store/store';

export async function injectShopifyHeadScript(
  code: string,
  shopDomain: string,
  shopId: string,
  apiKey: string,
  setIsInstalling: (isInstalling: boolean) => void
) {
  try {
    setIsInstalling(true);
    const res = await shopify_auth_api_call({
      code,
      shop_domain: shopDomain,
      shop_id: shopId,
    });
    if (res?.access_token) {
      const isScriptInjected: boolean = await shopify_inject_script_api_call({
        token: res.access_token,
        shop_domain: shopDomain,
        shop_id: shopId,
        script_url: `${
          window.location.origin
        }/adup-shopify-head-script.js?aiTrackingSrc=${AI_CHECKOUT_TRACKING_LINK(
          apiKey
        )}&t=${new Date().getTime()}`,
      });
      if (isScriptInjected) {
        setIsInstalling(false);
        store.dispatch(
          setMessage({
            message: t('ShopifyAppInstallationSuccess'),
            messageType: 'success',
          })
        );
      } else {
        setIsInstalling(false);
        store.dispatch(
          setMessage({
            message: t('ShopifyAuthenticationFailed'),
            messageType: 'error',
          })
        );
      }
    } else {
      setIsInstalling(false);
      store.dispatch(
        setMessage({
          message: t('ShopifyAuthenticationFailed'),
          messageType: 'error',
        })
      );
    }
  } catch (error) {
    setIsInstalling(false);
    console.log('Error during authentication request', error);
    store.dispatch(
      setMessage({
        message: t('ShopifyAuthenticationFailed'),
        messageType: 'error',
      })
    );
  }
}
