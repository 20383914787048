import { createSlice } from '@reduxjs/toolkit';

export enum DashboardViews {
  home,
  products,
  orders,
  statistics,
  settings,
}

export interface UIState {
  dashboard: {
    currentView: DashboardViews;
  };
  theme: {
    type: string;
    dark: boolean;
  };
  isSidebarMinimized: boolean;
  isSidebarDrawerOpen: boolean;
  sidebarMainLinks: string;
  sidebarSubLinks: string;
  isOnboardingCompleteBannerOpen: boolean;
  isWhiteLabel: boolean;
  isPaymentPopupOpen: boolean;
}

const initialState: UIState = {
  dashboard: {
    currentView: DashboardViews.home,
  },
  theme: {
    type: '1',
    dark: false,
  },
  isSidebarMinimized: false,
  isSidebarDrawerOpen: false,
  sidebarMainLinks: '',
  sidebarSubLinks: '',
  isOnboardingCompleteBannerOpen: false,
  isWhiteLabel: false,
  isPaymentPopupOpen: false,
};

export const uiSlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setDashboardView: (state, action) => {
      state.dashboard.currentView = action.payload.view;
    },
    setSidebarMainLink: (state, action) => {
      state.sidebarMainLinks = action.payload;
    },
    setSidebarSubLink: (state, action) => {
      state.sidebarSubLinks = action.payload;
    },
    setAppThemeMode: (state) => {
      state.theme.dark = !state.theme.dark;
    },
    setAppThemeType: (state, action) => {
      state.theme.type = action.payload;
    },

    setIsOnboardingCompleteBannerOpen: (state, action) => {
      state.isOnboardingCompleteBannerOpen = action.payload;
    },

    toggleSidebarDrawer: (state) => {
      state.isSidebarDrawerOpen = !state.isSidebarDrawerOpen;
    },
    toggleSidebarSize: (state) => {
      state.isSidebarMinimized = !state.isSidebarMinimized;
    },
    setIsPaymentPopupOpen: (state, action) => {
      state.isPaymentPopupOpen = action.payload;
    },
    togglePaymentModal: (state) => {
      state.isPaymentPopupOpen = !state.isPaymentPopupOpen;
    },
    setIsWhiteLabel: (state, action) => {
      state.isWhiteLabel = action.payload;
    },
  },
});

export const uiReducer = uiSlice.reducer;

export const {
  setDashboardView,
  setSidebarMainLink,
  setSidebarSubLink,
  setAppThemeMode,
  setIsOnboardingCompleteBannerOpen,
  toggleSidebarDrawer,
  setIsWhiteLabel,
  setAppThemeType,
  toggleSidebarSize,
  setIsPaymentPopupOpen,
  togglePaymentModal,
} = uiSlice.actions;
