import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addedCartSVG } from '../../../../../../lib/allCustomSVGs';
import ChartAdditionalStats from '../../../../../../components/charts/ChartAdditionalStats';
import LoadingSpinner from '../../../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../../../ui/noData/NoData';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import colorsForCharts from '../../../../../../lib/constants/colors';

type Props = {
  apiResponse: any;
};

const SingleDeviceConversionRateBarChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [additionalStats, setAdditionalStats] = useState<any>({
    total_device_carts: 0,
  });
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res?.type === 'singleDevice' && res?.data) {
      let newChartData = [];
      let count = 0;
      let extraStats = { ...additionalStats };

      if (Object.keys(res?.data)?.length === 0) {
        setLoading('null');
        return;
      }
      for (const key in res?.data) {
        if (key === 'total_device_carts') {
          extraStats.total_device_carts = res?.data[key] || 0;
        } else {
          newChartData.push({
            key: key, // Change 'label' to 'key'
            value: res?.data[key]?.conversion_rate || 0,
          });
        }

        count += 1;
      }

      // Set chart data once
      setAdditionalStats(extraStats);
      setChartData(newChartData);
      setLoading('success');
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const AdditionalStatsData = [
    {
      label: t('TotalDeviceCarts'),
      value: additionalStats?.total_device_carts,
      fillColor: '#84b6d8',
      svg: addedCartSVG,
    },
  ];

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__bottom--content-chart'>
            <div
              style={{ width: '100%' }}
              className='chart__bottom--content-chart-pie'
            >
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 50,
                    left: -10,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='key'
                    label={{
                      // value: 'Conversion Rate (%)',
                      position: 'insideBottom',
                      offset: -5,
                    }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    label={{
                      // value: 'Conversion Rate (%)',
                      angle: -90,
                      position: 'insideLeft',
                      offset: 20,
                      margin: 10,
                    }}
                  />
                  <Bar dataKey='value' fill='#8884d8'>
                    <LabelList
                      dataKey='value'
                      position='top'
                      formatter={(value: number) => `${value.toFixed(0)}%`}
                      style={{ fill: '#6d6d6d' }}
                    />
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorsForCharts[index + 10]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <ChartAdditionalStats stats={AdditionalStatsData} />
        </>
      )}
    </>
  );
};

export default SingleDeviceConversionRateBarChart;
