import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { editSVG, trashSVG } from '../../../../lib/allCustomSVGs';
import Popup from '../../../../ui/popup/Popup';
import { useTranslation } from 'react-i18next';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import store from '../../../../store/store';
import { setMessage } from '../../../../store/reducers/appSlice';
import AITagInput from '../../../../ui/inputs/AITagInput';
import get_custom_css_by_integration_key_api_call from '../../../../lib/api/customCSS/get_custom_css_by_integration_key_api_call';
import delete_custom_css_by_integration_key_api_call from '../../../../lib/api/customCSS/delete_custom_css_by_integration_key_api_call';
import store_custom_css_by_integration_key_api_call from '../../../../lib/api/customCSS/store_custom_css_by_integration_key_api_call';

type CustomCSS = {
  id: string;
  name: string;
  type: string;
  css_selectors: string[];
  active: boolean;
};

interface Props {
  apiKey: string;
  customCSSFetchTriggerCount: number;
  trackingEventTypeArr: { OptionValue: string; OptionName: string }[];
  customCSS: CustomCSS[];
  setCustomCSS: React.Dispatch<React.SetStateAction<CustomCSS[]>>;
  filterExistingElementNames: (
    value: string,
    isUpdateMode?: boolean,
    currentCustomCSSId?: string
  ) => boolean;
  filterAdUpElementNames: (value: string) => boolean;
}

const CustomCssTrackingTable: React.FC<Props> = ({
  apiKey,
  customCSSFetchTriggerCount,
  trackingEventTypeArr,
  customCSS,
  setCustomCSS,
  filterExistingElementNames,
  filterAdUpElementNames,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<'pending' | 'success' | 'error'>(
    'pending'
  );

  const [editingCustomCSS, setEditingCustomCSS] = useState<CustomCSS>();

  const [elementName, setElementName] = useState('');
  const [trackingEventType, setTrackingEventType] = useState(
    trackingEventTypeArr[0].OptionValue
  );
  const [cssSelectors, setCssSelectors] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isElementNameInputInvalid, setIsElementNameInputInvalid] =
    useState<boolean>(false);

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(false);

  const toggleEditPopupModal = () => {
    if (!isEditPopupOpen === false) {
      setEditingCustomCSS(undefined);
    }
    setIsEditPopupOpen(!isEditPopupOpen);
  };

  const fetchCustomCSS = async () => {
    try {
      const response = await get_custom_css_by_integration_key_api_call(apiKey);
      if (Array.isArray(response) && response.length > 0) {
        const fetchedCustomCSS: CustomCSS[] = response?.map((element: any) => ({
          id: element.id,
          name: element.name,
          type: element.type,
          css_selectors: element.css_selectors,
          active: element.is_active,
        })) as any;

        // Sort the custom CSS by type
        const sortedCustomCSS = fetchedCustomCSS.sort((a, b) =>
          a.type.localeCompare(b.type)
        );
        setCustomCSS(sortedCustomCSS);
      } else {
        setCustomCSS([]);
      }
      setLoading('success');
      setIsEditPopupOpen(false);
      setEditingCustomCSS(undefined);
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error fetching custom CSS elements:', error);
      setCustomCSS([]);
      setLoading('error');
      setIsEditPopupOpen(false);
      setEditingCustomCSS(undefined);
      setIsSubmitting(false);
    }
  };

  const validateElementNameInput = (value: string) => {
    setElementName(value);

    const camelCasePattern = /^[a-z]+([A-Z][a-z]*)*$/;
    if (isNotEmptyString(value)) {
      if (camelCasePattern.test(value)) {
        setIsElementNameInputInvalid(false);
      } else {
        setIsElementNameInputInvalid(true);
      }
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    const elementNameAlreadyExists = filterExistingElementNames(
      elementName,
      true,
      editingCustomCSS?.id
    );

    if (elementNameAlreadyExists) {
      store.dispatch(
        setMessage({
          message: `${t('DuplicateCustomCSSElementName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    const elementNameIsAdUpElement = filterAdUpElementNames(elementName);

    if (elementNameIsAdUpElement) {
      store.dispatch(
        setMessage({
          message: `${t('AdUpCSSElementName-validation')}`,
          messageType: 'warning',
        })
      );
      return;
    }

    setIsSubmitting(true);

    const res = await store_custom_css_by_integration_key_api_call(apiKey, [
      {
        id: editingCustomCSS?.id,
        name: elementName,
        type: trackingEventType,
        css_selectors: cssSelectors,
        is_active: editingCustomCSS?.active,
      },
    ]);

    if (res && res?.success) {
      fetchCustomCSS();
    } else {
      setIsSubmitting(false);
    }
  };

  const handleActiveToggle = async (element: CustomCSS) => {
    setEditingCustomCSS(element);
    const res = await store_custom_css_by_integration_key_api_call(
      apiKey,
      [
        {
          id: element.id,
          name: element.name,
          type: element.type,
          css_selectors: element.css_selectors,
          is_active: !element.active,
        },
      ],
      false //shouldShowMsg
    );

    if (res && res?.success) {
      setCustomCSS((prev) =>
        prev.map((e) => (e.id === element.id ? { ...e, active: !e.active } : e))
      );
    }

    setEditingCustomCSS(undefined);
  };

  const handleEdit = (element: CustomCSS) => {
    setEditingCustomCSS(element);
    toggleEditPopupModal();
  };

  const handleDelete = async (element: CustomCSS) => {
    setIsDeleteDisabled(true);
    const deleteSuccess = await delete_custom_css_by_integration_key_api_call(
      apiKey,
      element.id
    );
    if (deleteSuccess) {
      setCustomCSS((prevElements) =>
        prevElements.filter((e) => e.id !== element.id)
      );
      store.dispatch(
        setMessage({
          message: `${t('CustomCSSElementDeletedMsg')}`,
          messageType: 'success',
        })
      );
    }
    setIsDeleteDisabled(false);
  };

  useEffect(() => {
    fetchCustomCSS();
  }, [apiKey, customCSSFetchTriggerCount]);

  useEffect(() => {
    // Reset the form fields when the editing custom CSS changes
    setElementName('');
    setTrackingEventType(trackingEventTypeArr[0].OptionValue);
    setCssSelectors([]);

    // Set the form fields to the editing custom CSS's values
    if (editingCustomCSS) {
      setElementName(editingCustomCSS.name);
      setTrackingEventType(editingCustomCSS.type);
      setCssSelectors(editingCustomCSS.css_selectors);
    }
  }, [editingCustomCSS]);

  useEffect(() => {
    if (!isNotEmptyString(elementName) || cssSelectors.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [elementName, cssSelectors]);

  const selectedTags = (tags: string[], id: string) => {
    setCssSelectors(tags);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <h1 className='customEvent-title'>{t('ExistingCssTrackingRules')}</h1>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('ElementName')}</TableCell>
                <TableCell>{t('CSSClassOrID')}</TableCell>
                <TableCell>{t('TrackingEvent')}</TableCell>
                <TableCell>{t('Active')}</TableCell>
                <TableCell>{t('Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customCSS.map((customCSS, index) => (
                <TableRow key={index}>
                  <TableCell>{customCSS.name}</TableCell>
                  <TableCell>
                    {customCSS.css_selectors?.map((selector, index) => (
                      <div key={index}>{selector}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {
                      trackingEventTypeArr.find(
                        (trackingEventType) =>
                          trackingEventType.OptionValue === customCSS.type
                      )?.OptionName
                    }
                  </TableCell>
                  <TableCell>
                    <ToggleSwitch
                      disabled={customCSS.id === editingCustomCSS?.id}
                      isOn={customCSS.active}
                      onClick={() => handleActiveToggle(customCSS)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className='customEvent__actions'>
                      <div
                        className='customEvent__editButton'
                        onClick={() => handleEdit(customCSS)}
                      >
                        <CustomSVGs svg={editSVG} />
                      </div>
                      <div
                        className='customEvent__deleteButton'
                        onClick={() => {
                          if (!isDeleteDisabled) {
                            handleDelete(customCSS);
                          }
                        }}
                      >
                        <CustomSVGs svg={trashSVG} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Popup
        styles={{ backgroundColor: '#fff', width: '80%' }}
        setTrigger={toggleEditPopupModal}
        trigger={isEditPopupOpen}
      >
        <div className='customEvent__editPopup'>
          <form
            id='customCssTracking-form-update'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <h1 className='customEvent-title'>{t('EditEvent')}</h1>
            <div className='normalForm__top'>
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <AINormalInput
                    changeListeners={[
                      (event) =>
                        validateElementNameInput(
                          (event.target as HTMLInputElement).value
                        ),
                    ]}
                    id='ElementName-input'
                    value={elementName}
                    placeholder={t('ElementName')}
                    required={true}
                    innerPlaceholder={t('EnterElementName')}
                    pattern='^[a-z]+([A-Z][a-z]*)*$'
                    title={t('CustomCSSElementName-validation')}
                  />
                </div>

                <div className='normalForm__container1--right'>
                  <AITagInput
                    selectedTags={selectedTags}
                    id='CSSClassOrID-input'
                    initialTags={cssSelectors}
                    label={t('CSSClassOrID')}
                    innerPlaceholder={t('EnterCssSelector')}
                    required={cssSelectors.length === 0}
                    validationText={t('CssClassInput-validation')}
                    pattern={
                      /^(?!\s*$)[.#]?[^\[\]\{\}\(\)!@$%^&*+=,:<>;:"'`]+$/
                    }
                  />
                </div>
              </div>

              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <AISelectInput
                    defaultValue={trackingEventType}
                    setSelect={setTrackingEventType}
                    selectOptionArr={trackingEventTypeArr}
                    placeholder={t('TrackingEvent')}
                    id={'TrackingEvent-select'}
                  />
                </div>
              </div>

              <div className='normalForm__bottom flex-start'>
                <MainSquareButton
                  value={isSubmitting ? `${t('Updating')}...` : t('UpdateRule')}
                  disabled={
                    isDisabled || isSubmitting || isElementNameInputInvalid
                  }
                  type='submit'
                  form='customCssTracking-form-update'
                />
              </div>
            </div>
          </form>
        </div>
      </Popup>
    </>
  );
};

export default CustomCssTrackingTable;
