import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useEffect, useState } from 'react';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import store from '../../../store/store';
import { asyncHandler } from '../../../lib/api/async-handler';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import CopyToClipboard from '../../../ui/inputs/CopyToClipboard';
import { useTranslation } from 'react-i18next';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import {
  CENTRAL_API_BASE_URL,
  SHOP_API,
} from '../../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsMarketing = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const slug = store.getState().user?.company?.shop_slug;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [facebookPixelId, setFacebookPixelId] = useState<string>('');
  const [facebookAccessToken, setFacebookAccessToken] = useState<string>('');
  const [tiktokAccessToken, setTiktokAccessToken] = useState<string>('');
  const [tiktokPixelId, setTiktokPixelId] = useState<string>('');
  const [tiktokTestEventCode, setTiktokTestEventCode] = useState<string>('');
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState<string>('');
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isDisabled =
    (facebookPixelId === '' || facebookPixelId === null) &&
    (facebookAccessToken === '' || facebookAccessToken === null) &&
    (tiktokPixelId === '' || tiktokPixelId === null) &&
    (tiktokAccessToken === '' || tiktokAccessToken === null) &&
    (tiktokTestEventCode === '' || tiktokTestEventCode === null) &&
    (googleAnalyticsId === '' || googleAnalyticsId === null)
      ? true
      : false;

  useEffect(() => {
    if (slug && slug !== '') {
      const response = getMarketingSettings();
      response
        .then((result) => {
          setFacebookPixelId(result?.facebook_pixel_id);
          setTiktokPixelId(result?.tiktok_pixel_id);
          setGoogleAnalyticsId(result?.google_analytics_id);
          setFacebookAccessToken(result?.facebook_access_token);
          setTiktokTestEventCode(result?.tiktok_test_event_code);
          setTiktokAccessToken(result?.tiktok_access_token);
          setLoading('success');
        })
        .catch((err) => {
          console.error(err);
          setLoading('error');
          submitLogToBugsnag('error', `${err}`);
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */
  async function getMarketingSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings?filter[group]=marketing`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_MARKETING_SETTINGS';
      const customMsg = 'Marketing Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        let data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.key] = item.value === null ? '' : item.value;
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'marketing',
        facebook_pixel_id: facebookPixelId,
        facebook_access_token: facebookAccessToken,
        tiktok_pixel_id: tiktokPixelId,
        tiktok_access_token: tiktokAccessToken,
        tiktok_test_event_code: tiktokTestEventCode,
        google_analytics_id: googleAnalyticsId,
      };

      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_MARKETING_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */
  const base_product_feed_URL = CENTRAL_API_BASE_URL?.substring(
    0,
    CENTRAL_API_BASE_URL?.length - 7
  );
  return (
    <SubPageInnerContentHolder
      title={t('MarketingSettings')}
      // subTitle={t('ManageMarketingSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form
            id='marketing-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <div className='normalForm__top'>
              {/* <h1>Add the Following Information.</h1> */}
              <h2>Facebook</h2>
              <hr />
              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    id='marketing-details-facebook'
                    value={facebookPixelId}
                    changeListeners={[
                      (e) => setFacebookPixelId(e.target.value),
                    ]}
                    focusListeners={[]}
                    placeholder='Facebook Pixel ID'
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='Facebook pixel ID cannot exceed 100 characters'
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalInput
                    id='marketing-details-facebook-access-token'
                    value={facebookAccessToken}
                    changeListeners={[
                      (e) => setFacebookAccessToken(e.target.value),
                    ]}
                    focusListeners={[]}
                    placeholder={t('FacebookAccessToken')}
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='Facebook pixel ID cannot exceed 100 characters'
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
              <h2 className='margin-top-2rem'>TikTok</h2>
              <hr />

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    id='marketing-details-tiktok'
                    value={tiktokPixelId}
                    changeListeners={[(e) => setTiktokPixelId(e.target.value)]}
                    focusListeners={[]}
                    placeholder='TikTok Pixel ID'
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='TikTok pixel ID cannot exceed 100 characters'
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalInput
                    id='marketing-details-tiktok-access-token'
                    value={tiktokAccessToken}
                    changeListeners={[
                      (e) => setTiktokAccessToken(e.target.value),
                    ]}
                    focusListeners={[]}
                    placeholder={t('TikTokAccessToken')}
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='TikTok pixel ID cannot exceed 100 characters'
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    id='marketing-details-tiktok-test-event-code'
                    value={tiktokTestEventCode}
                    changeListeners={[
                      (e) => setTiktokTestEventCode(e.target.value),
                    ]}
                    focusListeners={[]}
                    placeholder={t('TikTokTestEventCode')}
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='Facebook pixel ID cannot exceed 100 characters'
                  />
                </div>
                <div className='normalForm__container1--right'>
                  {/* add a new input later if needed to this container */}
                </div>
              </div>
              {/* ------------------------------------------------------- */}

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <h2 className='margin-top-2rem'>Google</h2>
                  <hr />
                  <NormalInput
                    id='marketing-details-google'
                    value={googleAnalyticsId}
                    changeListeners={[
                      (e) => setGoogleAnalyticsId(e.target.value),
                    ]}
                    focusListeners={[]}
                    placeholder='Google Analytics ID'
                    classes='lg:w-[13rem]'
                    // required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    // title='Google Analytics ID cannot exceed 100 characters'
                  />
                </div>
                {/* <div className='normalForm__container1--right'>
                  <h2 className='margin-top-2rem'>{t('Feeds')}</h2>
                  <hr />
                  <CopyToClipboard
                    inputTitle={t('ProductsFeed')}
                    inputValue={`${base_product_feed_URL}feed/${slug}/xml`}
                  />
                </div> */}
              </div>
              {/* ------------------------------------------------------- */}
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                type='submit'
                form='marketing-settings-form'
                value={t('UpdateSettings')}
                loading={isSubmitting}
                disabled={isDisabled || isSubmitting}
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsMarketing;
