import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { errorSVG, subscribeSVG, successSVG } from '../../../lib/allCustomSVGs';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import { useEffect, useState } from 'react';
import store, { RootState } from '../../../store/store';
import {
  STRIPE_SUBSCRIPTION_ENDPOINT,
  STRIPE_SUBSCRIPTION_PRICE_ID,
} from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import NoData from '../../../ui/noData/NoData';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { setSubscriptionLock } from '../../../store/reducers/authSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Popup from '../../../ui/popup/Popup';
import SelectableButton from '../../../ui/buttons/SelectableButton';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsSubscription = () => {
  const { t } = useTranslation();
  const [subscription14, setSubscription14] = useState<any>(undefined);
  const [subscription49, setSubscription49] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(undefined);
  const [isRetry, setIsRetry] = useState(false);
  const [statusLoading, setStatusLoading] = useState('pending');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [stripeSubscriptionUrl, setStripeSubscriptionUrl] = useState();
  const isSafariBrowser = store.getState().app.browser.includes('Safari');
  const navigate = useNavigate();
  const subscriptionLock = useSelector(
    (state: RootState) => state.auth.authorization.subscriptionLock
  );

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const response = getSubscriptionStatus();
    response
      .then((result) => {
        setSubscriptionStatus(result?.status);
        setStatusLoading('success');
        if (result?.status === 'completed') {
          store.dispatch(setSubscriptionLock('false'));
        }
      })
      .catch((err) => {
        console.error(err);
        setStatusLoading('error');
        submitLogToBugsnag('error', `${err}`);
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getSubscriptionStatus() {
    try {
      const requestInfo: RequestInfo = {
        url: STRIPE_SUBSCRIPTION_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_STRIPE_SUBSCRIPTION_STATUS';
      const customMsg = 'Subscription status fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler() {
    try {
      setIsLoading(true);
      const requestInfo: RequestInfo = {
        url: STRIPE_SUBSCRIPTION_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          price_id: subscription14 ?? subscription49,
          qty: 1,
          mode: 'subscription',
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'STRIPE_SUBSCRIPTION';
      const customMsg = t('SubscriptionRequestedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (
        res.actionType === actionType &&
        response.success &&
        response.result?.url
      ) {
        if (isSafariBrowser) {
          setStripeSubscriptionUrl(response.result?.url);
          togglePopupModal();
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          window.open(
            response.result?.url,
            '_blank',
            'toolbar=no,location=no,top=80, left=420, status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=700'
          );
          setTimeout(() => {
            setIsLoading(false);
            navigate(HOME_ROUTE);
          }, 5000);
        }
      } else {
        setIsLoading(false);
      }
    } catch (exception) {
      setIsLoading(false);
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <>
      <SubPageInnerContentHolder
        title={t('SubscriptionSettings')}
        // subTitle={t('ManageSubscriptionSettings')}
        backBtnLink={
          (subscriptionLock && subscriptionLock === 'true') || isWhiteLabel
            ? undefined
            : HOME_ROUTE
        }
        mainBtnIcon={undefined}
        mainBtnTitle={undefined}
        mainBtnLink={undefined}
      >
        <>
          {statusLoading === 'pending' && <LoadingSpinner />}
          {statusLoading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {statusLoading === 'success' && (
            <>
              {(subscriptionStatus === undefined || isRetry) && (
                <>
                  {isLoading && (
                    <>
                      <LoadingSpinner
                        customLoadingMsg={t('ProcessingStripeSubscription')}
                      />
                      <br />
                    </>
                  )}
                  {!isLoading && (
                    <>
                      <div className='shopPage__title'>
                        <h2>{t('YourPlan')}</h2>
                      </div>
                      {/* <div className='shopPage__divider' /> */}
                      <div className='shopPage__container1-alignLeft'>
                        <SelectableButton
                          icon={''}
                          title={''}
                          subTitle={`€14 ${t('Per-Month')}`}
                          subTitle2={t('Per-Transaction')}
                          link=''
                          onSelect={() => {
                            setSubscription49(undefined);
                            setSubscription14(
                              STRIPE_SUBSCRIPTION_PRICE_ID?.subscription14
                            );
                          }}
                          isSelected={subscription14}
                        />
                        <SelectableButton
                          icon={''}
                          title={''}
                          subTitle={`€49 ${t('Per-3-Month')}`}
                          subTitle2={t('TransactionIncludedDiscount')}
                          link=''
                          onSelect={() => {
                            setSubscription14(undefined);
                            setSubscription49(
                              STRIPE_SUBSCRIPTION_PRICE_ID?.subscription49
                            );
                          }}
                          isSelected={subscription49}
                        />
                        {/*  <CategoryCard
                          icon={euro14SVG}
                          title={''}
                          subTitle={t('Per-Month')}
                          subTitle2={t('Per-Transaction')}
                          link=''
                          onSelect={() => {
                            setSubscription49(undefined);
                            setSubscription14(
                              STRIPE_SUBSCRIPTION_PRICE_ID?.subscription14
                            );
                          }}
                          isSelected={subscription14}
                        /> */}
                        {/*  <CategoryCard
                          icon={euro49SVG}
                          title={''}
                          subTitle={t('Per-3-Month')}
                          subTitle2={t('TransactionIncludedDiscount')}
                          link=''
                          onSelect={() => {
                            setSubscription14(undefined);
                            setSubscription49(
                              STRIPE_SUBSCRIPTION_PRICE_ID?.subscription49
                            );
                          }}
                          isSelected={subscription49}
                        /> */}
                      </div>
                      <div className='normalForm__bottom margin-left-right-1rem flex-start'>
                        <MainSquareButton
                          onClick={(e) => {
                            onSubmitHandler();
                          }}
                          type={'button'}
                          value={t('Subscribe')}
                          icon={subscribeSVG}
                          disabled={!(subscription14 || subscription49)}
                          normal={true}
                        />
                      </div>
                      <div className='shopPage__container1'>
                        {/* <span style={{ fontSize: 8 }}>
                          {' '}
                          Powered by {stripeSVG}
                        </span> */}
                      </div>
                    </>
                  )}
                </>
              )}
              {subscriptionStatus === 'completed' && !isRetry && (
                <div className='noData'>
                  <div className='noData__success'>
                    <CustomSVGs svg={successSVG} />
                  </div>
                  <h1>{t('StripeSubscriptionSuccessMainText')}</h1>
                  <p>{t('StripeSubscriptionSuccessSubText2')}</p>{' '}
                  <a
                    onClick={() => {
                      setIsRetry(true);
                    }}
                  >
                    {t('StripeSubscriptionEdit')}
                  </a>
                </div>
              )}
              {subscriptionStatus === 'unpaid' && !isRetry && (
                <div className='noData'>
                  <div className='noData__error'>
                    <CustomSVGs svg={errorSVG} />
                  </div>

                  <h1>{t('StripeSubscriptionFailMainText')}</h1>
                  <p>{t('StripeSubscriptionFailSubText')}</p>
                  <a
                    onClick={() => {
                      setIsRetry(true);
                    }}
                  >
                    {t('Retry')}
                  </a>
                </div>
              )}
            </>
          )}
        </>
      </SubPageInnerContentHolder>

      {/* //* ------popup only for Safari Browser -------- */}
      <Popup setTrigger={togglePopupModal} trigger={isPopupOpen}>
        <div className='nopsp__container1' style={{ paddingTop: '30px' }}>
          <button
            className='nopsp__btn'
            onClick={() => {
              window.open(
                stripeSubscriptionUrl,
                '_blank',
                'toolbar=no,location=no,top=80, left=420, status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=700'
              );
              setTimeout(() => {
                setIsLoading(false);
                navigate(HOME_ROUTE);
              }, 1000);
            }}
          >
            <span>{t('ContinueStripeSubscription')}</span>
          </button>
        </div>
      </Popup>
    </>
  );
};

export default SettingsSubscription;
