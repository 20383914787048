import { useTranslation } from 'react-i18next';
import SimpleCard from '../../../ui/cards/SimpleCard';
import Lottie from 'lottie-react';
import '../../../styles/components/charts/pieChartComponent.scss';
import setupTrackingLottie from '../../../assets/json/lottie_animation/setup_tracking.json';
import '../../../styles/pages/dashboard/stats/aiCheckoutTrackingStats.scss';
import DummyStats from './dummyStats/DummyStats';
import AllGraphs from './AllGraphs';

type Props = {
  isTrackable: boolean;
  isTimesUp: boolean;
  stats: any;
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const AiCheckoutTrackingStats = ({
  isTrackable,
  isTimesUp,
  stats,
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='aiCheckoutTrackingStats--container'>
      <div className='aiCheckoutTrackingStats'>
        {isTrackable && !isTimesUp && (
          /*  <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText1')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard> */
          <DummyStats />
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard grow={false}>
            <div className='aiCheckoutTrackingStats__card'>
              <h3>{t('TrendsText2')}</h3>
              <div className='aiCheckoutTrackingStats__card--lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}

        {!stats?.additional_stats?.is_tracker_classes_configured &&
          stats?.additional_stats?.tracker_configured_timestamp &&
          isTimesUp && (
            <SimpleCard grow={false}>
              <div className='aiCheckoutTrackingStats__card'>
                <h3>{t('Please configure your tracker classes')}</h3>
                <div className='aiCheckoutTrackingStats__card--lottie'>
                  <Lottie animationData={setupTrackingLottie} loop={true} />
                </div>
              </div>
            </SimpleCard>
          )}

        {stats?.additional_stats?.is_tracker_classes_configured &&
        stats?.additional_stats?.tracker_configured_timestamp &&
        isTimesUp ? (
          <AllGraphs
            timeFilter={timeFilter}
            customPeriodFilterDateRange={customPeriodFilterDateRange}
            eventFilter={eventFilter}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AiCheckoutTrackingStats;
