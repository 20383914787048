import { useNavigate } from 'react-router-dom';
import { warningWithCircleSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/components/subPageList/subPageListDelete.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delete_psp_api_call from '../../../../lib/api/psp/delete-psp';
import { submitLogToBugsnag } from '../../../../lib/api/log';

const DeletePSP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pspId } = useParams();
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const [deletingPSP, setDeletingPSP] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (pspId) {
      //getting full data about the current psp from the available PSPs array using the pspId
      const currentPSPAllData: any = onboarding_object?.available_psp?.filter(
        (el: any) => el?.id?.toString() === pspId?.toString()
      );

      if (currentPSPAllData?.length > 0) {
        setDeletingPSP(currentPSPAllData);
      }
    }
  }, [pspId]);

  console.log(deletingPSP);

  const deleteHandler = async () => {
    setIsSubmitting(true);
    try {
      const res: boolean = await delete_psp_api_call(deletingPSP[0]?.slug);

      if (res) {
        setIsSubmitting(false);
        navigate('/settings/payments/connection');
      } else {
        setIsSubmitting(false);
      }
    } catch (exception) {
      setIsSubmitting(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  return (
    <div className='subPageListDelete'>
      <CustomSVGs svg={warningWithCircleSVG} />
      <h1>{t('AskToDelete-msg')}</h1>
      <h1>{`${deletingPSP[0]?.label} PSP ?`}</h1>
      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            deleteHandler();
          }}
          type={'button'}
          value={t('Yes')}
          normal={true}
          buttonType={'danger'}
          loading={isSubmitting}
        />
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            navigate('/settings/payments/connection');
          }}
          type={'button'}
          value={t('No')}
          normal={true}
          buttonType={'light'}
        />
      </div>
    </div>
  );
};

export default DeletePSP;
