import '../../styles/pages/whiteLabel.scss';
import store from '../../store/store';
import { setMessage } from '../../store/reducers/appSlice';
import {
  setAccessToken,
  setIsAdminSession,
} from '../../store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { fetchAppInit } from '../../lib/api/app-init';
import { submitLogToBugsnag } from '../../lib/api/log';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import moment from 'moment';
import { useEffect } from 'react';
import { HOME_ROUTE } from '../../lib/constants/route';
import localStorageHardReset from '../../lib/helper-functions/localStorageHardReset';

const PortalLogin = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('token');
  const token_type = 'Bearer';

  useEffect(() => {
    localStorageHardReset();
    setTimeout(async () => {
      try {
        if (token && isNotEmptyString(token)) {
          store.dispatch(
            setAccessToken({
              sellerToken: token,
              type: token_type,
              expiresOn: moment().add(1, 'days'),
            })
          );
          const isInitFetched = await fetchAppInit();
          if (isInitFetched) {
            store.dispatch(setIsAdminSession(true));
            navigate(HOME_ROUTE);
          }
        } else {
          store.dispatch(
            setMessage({
              message: 'Authorization failed',
              messageType: 'error',
            })
          );
          console.log('PortalLogin : Token is empty or invalid.');
          submitLogToBugsnag(
            'error',
            `PortalLogin : Token is empty or invalid.`
          );
          navigate('/login');
        }
      } catch (error) {
        store.dispatch(
          setMessage({
            message: 'Authorization failed',
            messageType: 'error',
          })
        );
        console.log('error', `PortalLogin : ${error}`);
        submitLogToBugsnag('error', `PortalLogin : ${error}`);
        navigate('/login');
      }
    }, 1000);
  }, []);

  return (
    <div className='container'>
      <div className='loadingSpinner__roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default PortalLogin;
