import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { CodeSection } from 'react-code-section-lib';
import { clockSVG } from '../../../../lib/allCustomSVGs';
import { useEffect, useState } from 'react';
import {
  regularScriptCode,
  shopifyPixelCode,
  shopifyPixelName,
} from '../../../../lib/constants/integration_script_code';
import TrackerSwitch from './TrackerSwitch';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import '../../../../styles/pages/checkoutTracking/trackingIntegration.scss';
import StatusCard from '../../../../ui/cards/StatusCard';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';
import { shopifyAdminURL } from '../../../../lib/constants/central-api';
import ValueSelector from '../../../../ui/inputs/ValueSelector';
import getInitialValuesViaTrackingConfigurations from '../../../../lib/helper-functions/getInitialValuesViaTrackingConfigurations';
import {
  initialInputData,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';
// import shopifyButtonImg from '../../../../assets/shopify.png';
import { injectShopifyHeadScript } from './injectShopifyHeadScript';

interface Props {
  apiKey: any;
  isTrackerON: boolean;
  setIsTrackerON: (value: boolean) => void;
  optimizeValue: number;
  setOptimizeValue: (value: number) => void;
  isAutofillEnabled: boolean;
  data: any;
  setAPIData: (data: any) => void;
  setScriptKey: (key: string) => void;
}

function Integration({
  apiKey,
  isTrackerON,
  setIsTrackerON,
  optimizeValue,
  setOptimizeValue,
  isAutofillEnabled,
  data,
  setAPIData,
  setScriptKey,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);

  const companyDetails = useSelector((state: RootState) => state.user.company);
  const shopURL = companyDetails?.webshop_url ?? '';

  const [detectedCMS, setDetectedCMS] = useState(
    isNotEmptyString(data?.additional_configs?.CMS_type)
      ? data?.additional_configs?.CMS_type
      : 'custom'
  );

  const percentagesArr = Array.from({ length: 10 }, (_, index) => {
    return {
      label: (index + 1)?.toString(),
      value: (index + 1) * 10,
    };
  });

  const detectCMS_Arr = [];

  const preDefinedCMS_Arr_without_shopify = [
    { OptionValue: 'custom', OptionName: 'Custom' },
  ];

  const preDefinedCMS_Arr = [
    { OptionValue: 'shopify', OptionName: 'Shopify' },
    ...preDefinedCMS_Arr_without_shopify,
  ];

  const cmsType = data?.additional_configs?.CMS_type || '';

  if (isNotEmptyString(cmsType) && cmsType !== 'custom') {
    detectCMS_Arr.push({
      OptionValue: cmsType,
      OptionName: `${makeFirstLetterCapital(cmsType)} (Auto-detected)`,
    });

    const cmsArray =
      cmsType === 'shopify'
        ? preDefinedCMS_Arr_without_shopify
        : preDefinedCMS_Arr;
    detectCMS_Arr.push(...cmsArray);
  } else {
    detectCMS_Arr.push(...preDefinedCMS_Arr);
  }

  const trackingInputValues = data
    ? getInitialValuesViaTrackingConfigurations(
        data?.configuration,
        transformInputData(initialInputData)
      )
    : transformInputData(initialInputData);

  async function updateAdjustmentRatio(value: number) {
    setIsLoading(true);
    const isEverythingNull = !Object.values(trackingInputValues).some(
      (value) => value !== ''
    );

    const optimizationAdjustmentRatioObj: {
      optimization_adjustment_ratio: number;
    } = {
      optimization_adjustment_ratio: value ?? 0,
    };

    const trackerSwitchObj: { is_tracker_active: 'true' | 'false' } = {
      is_tracker_active: isTrackerON ? 'true' : 'false',
    };

    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isAutofillEnabled ? 'true' : 'false',
    };

    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEverythingNull
          ? { key: 'value' }
          : trackingInputValues,
      };

      if (data) {
        sendingData['_method'] = 'put';
      }

      const customMsg = t('OptimizationRatio-success-msg', {
        ratio: value,
      });

      const res = await post_integration_api_call(
        sendingData,
        data ? data?.slug : undefined,
        autofillEnabledObj,
        trackerSwitchObj,
        optimizationAdjustmentRatioObj,
        customMsg
      );
      if (res?.api_key) {
        setAPIData(res);
        setScriptKey(res?.api_key);
        setOptimizeValue(value);
      }
      setIsLoading(false);
    } catch (exception) {
      console.log('Error during authentication request');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    const code: any = params.get('code');
    const shop: any = params.get('shop');

    if (isNotEmptyString(code) && isNotEmptyString(shop)) {
      //* Removing params from browser URL and continue with the authentication process
      window.history.replaceState({}, '', `${window?.location?.pathname}`);
      injectShopifyHeadScript(
        code,
        shop,
        companyDetails?.shop_id,
        apiKey,
        setIsInstalling
      );
    }
  }, []);

  const renderIntegrationCode = () => {
    return (
      <div
        style={{ paddingTop: 0 }}
        className={`normalForm ${!isTrackerON ? 'disabled' : ''}`}
      >
        <h1 className='trackingIntegration-title'>{t('IntegrationSetup')}</h1>
        <span className='trackingIntegration-subtitle'>
          {t('FollowTheseStepsToIntegrateTrackingOnYourWebsite')}
        </span>
        <div style={{ marginTop: '2rem' }} className='normalForm__top'>
          {/* //* ----------- RegularIntegration -- Start ----------- */}
          {detectedCMS !== 'shopify' && (
            <>
              <span className='trackingIntegration-subtitle-2'>
                {t('CheckoutTrackingIntegrationInstructions1')}
              </span>
              <div className='trackingIntegration-subtitle'>
                {t('CheckoutTrackingIntegrationInstructions2')} {`<head>`}
              </div>

              <div className='code__snippet'>
                <CodeSection
                  showLineNumbers={true}
                  theme={'dark'}
                  lang={'html'}
                >
                  {regularScriptCode(apiKey)}
                </CodeSection>
              </div>
            </>
          )}
          {/* //* ----------- RegularIntegration -- End ----------- */}

          {/* //* ----------- ShopifyIntegration -- Start ----------- */}
          {detectedCMS === 'shopify' && (
            <>
              <span className='trackingIntegration-subtitle-2'>
                {t('CheckoutTrackingIntegrationInstructions0-Shopify')}
              </span>
              {/* //* Commented below for the MCP Launch */}
              {/* <div className='trackingIntegration-subtitle'>
                {t('CheckoutTrackingIntegrationInstructions8-Shopify')}
              </div>
              <div
                className='normalForm__container1--left'
                style={{ marginBottom: '1.8rem', maxWidth: '32.3rem' }}
              >
                <div className='login__right--other'>
                  <div className='login__right--bottom-button'>
                    <MainSquareButton
                      value={
                        isInstalling
                          ? `${t('Installing')}...`
                          : `${t(
                              'CheckoutTrackingIntegrationInstructions9-Shopify'
                            )}`
                      }
                      image={shopifyButtonImg}
                      type='button'
                      disabled={isInstalling}
                      onClick={() => {
                        setIsInstalling(true);
                        window.location.href = SHOPIFY_AUTH_URL(
                          shopURL,
                          `${window.location.origin}/tracking/integration`
                        );
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div
                style={{ marginBottom: '1rem' }}
                className='trackingIntegration-subtitle'
              >
                {t('CheckoutTrackingIntegrationInstructions1-Shopify')}
              </div>
              <ul className='trackingIntegration-list'>
                <li>
                  {t('CheckoutTrackingIntegrationInstructions2-Shopify')} ({' '}
                  <a
                    onClick={() => {
                      window.open(shopifyAdminURL, '_blank');
                    }}
                  >
                    {shopifyAdminURL}
                  </a>
                  )
                </li>
                <li>{t('CheckoutTrackingIntegrationInstructions3-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions4-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions5-Shopify')}</li>
                <div
                  className='code__snippet__single-line'
                  style={{
                    width: '25rem',
                    marginTop: '-4rem',
                    marginBottom: '-1rem',
                    marginLeft: '1.5rem',
                  }}
                >
                  <CodeSection
                    showLineNumbers={false}
                    theme={'dark'}
                    lang={'html'}
                  >
                    {shopifyPixelName(apiKey)}
                  </CodeSection>
                </div>
                <li>{t('CheckoutTrackingIntegrationInstructions6-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions7-Shopify')}</li>
              </ul>
              <div className='code__snippet'>
                <CodeSection
                  showLineNumbers={true}
                  theme={'dark'}
                  lang={'html'}
                >
                  {shopifyPixelCode(apiKey)}
                </CodeSection>
              </div>
            </>
          )}
          {/* //* ----------- ShopifyIntegration -- End ----------- */}
        </div>
      </div>
    );
  };

  return (
    <div className='trackingIntegration'>
      {/* ======================================================================= */}
      <div className='trackingIntegration-wrapper'>
        <h1 className='trackingIntegration-title'>{t('IntegrationStatus')}</h1>
        <span className='trackingIntegration-subtitle'>
          {t('OverviewOfYourIntegrationPerformance')}.
        </span>
        <div className='trackingIntegration-status'>
          <StatusCard
            title={t('LastRequest')}
            info={data?.integration_stats?.recent_request_age ?? 'N/A'}
            description={t('LastTrackingRequestReceived')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('IntegrationAge')}
            info={
              data?.integration_stats?.age
                ? data?.integration_stats?.age?.replace(' ago', '')
                : 'N/A'
            }
            description={t('SinceFirstIntegration')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('RequestsToday')}
            info={data?.integration_stats?.today_request_counts ?? '0'}
            description={t('TrackingRequestsReceivedToday')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('Status')}
            info={isTrackerON ? t('Active') : t('Inactive')}
            description={
              isTrackerON
                ? t('IntegrationIsWorkingProperly')
                : t('IntegrationIsPaused')
            }
            color={isTrackerON ? 'green' : 'red'}
            icon={clockSVG}
          />
        </div>
      </div>
      {/* ======================================================================= */}
      <div
        style={{ marginTop: '2rem' }}
        className='trackingIntegration-wrapper'
      >
        <div style={{ paddingTop: 0 }}>
          <div className='trackingIntegration--titleWithSwitch'>
            <div className='trackingIntegration--titleWithSwitch-title'>
              <h1 className='trackingIntegration-title'>{t('BasicSetup')}</h1>
              <span className='trackingIntegration-subtitle'>
                {t('ConfigureYourWebshopIntegration')}
              </span>
            </div>
            <div className='trackingIntegration--titleWithSwitch-switch'>
              <TrackerSwitch
                setIsTrackerON={setIsTrackerON}
                isTrackerON={isTrackerON}
                isAutofillEnabled={isAutofillEnabled}
                optimizeValue={optimizeValue}
                data={data}
                setAPIData={setAPIData}
                setScriptKey={setScriptKey}
                noMargin={true}
                title={t('EnableTracking')}
              />
            </div>
          </div>

          <div
            className='normalForm__top'
            style={{ cursor: !isTrackerON ? 'not-allowed' : 'auto' }}
          >
            <div
              className='normalForm__container1'
              style={{ cursor: 'auto', marginBottom: '1rem' }}
            >
              <div className='normalForm__container1--left'>
                <AINormalInput
                  id='integration__webshop-url'
                  value={shopURL}
                  placeholder={t('WebshopURL')}
                  innerPlaceholder={'https://your-webshop.com'}
                  readonly={true}
                />
                <br />
              </div>
              <div className='normalForm__container1--right'>
                <AISelectInput
                  defaultValue={detectedCMS}
                  setSelect={setDetectedCMS}
                  selectOptionArr={detectCMS_Arr}
                  placeholder={t('CMSType')}
                  id={'integration__detect-cms'}
                />
                <br />
              </div>
            </div>
            {
              <div className={!isTrackerON ? 'disabled' : ''}>
                <span className='trackingIntegration-subtitle-2'>
                  {t('OptimizationRatio')}
                </span>
                <div className='trackingIntegration-subtitle'>
                  {t('SelectHowManyUsersShouldBeOptimized')}
                </div>
                <ValueSelector
                  onChange={updateAdjustmentRatio}
                  value={optimizeValue}
                  loading={isLoading}
                  isEqualSignVisible={true}
                  symbol={'%'}
                  content={percentagesArr}
                />
              </div>
            }
          </div>
        </div>
      </div>
      {/* ======================================================================= */}
      {
        <div
          style={{
            marginTop: '2rem',
            cursor: !isTrackerON ? 'not-allowed' : 'auto',
          }}
          className='trackingIntegration-wrapper'
        >
          {renderIntegrationCode()}
        </div>
      }
      {/* ======================================================================= */}
    </div>
  );
}

export default Integration;
